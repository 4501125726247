import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import InfoBox from '@common/InfoBox/InfoBox';
import FieldWrapper from '@common/form/FieldWrapper';
import {
  accordionStyle,
  contentSize,
  labelSize,
} from '@components/Settings/VerificationSettings/components/constants';
import { Field } from 'react-final-form';
import Input from '@components/Auth/Common/Input';

import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import iconPlus from '@assets/icons/plusGrey.svg';
import iconRefresh from '@assets/icons/refreshItem.svg';
import ActionButton from '@common/buttons/ActionButton/ActionButton';

import ActionButtonLight from '@common/buttons/ActionButton/ActionButtonLight';
import FileUploader from '@components/LiveChatConfigurations/components/FileUploader';

import {
  ThunkAddVerificationScreenLogo,
  ThunkRemoveVerificationScreenLogo,
} from '@store/slices/verificationSettings/thunks';
import useStyles from '../styles';

const templateVariables = [{ value: '${error}', label: 'Server error' }];

const ValidationScreenSettings = ({ processing, form, values, onReset }) => {
  const classes = useStyles();

  const [emailAnchorEl, setEmailAnchorEl] = useState(null);

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Verification screen settings
          <InfoBox text="Allows to configure verification screen" />
        </AccordionSummary>
        <AccordionDetails style={accordionStyle}>
          <ActionButtonLight
            handler={onReset}
            toolTip="Reset value"
            icon={<img src={iconRefresh} alt="refresh" />}
          />
          <div className={classes.logoField}>
            {values?.id ? (
              <FieldWrapper
                label="Logo"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <FileUploader
                    name="logo"
                    resourceId={values.id}
                    handleUpload={ThunkAddVerificationScreenLogo}
                    handleRemove={ThunkRemoveVerificationScreenLogo}
                    imageWidth="300px"
                  />
                }
              />
            ) : (
              <span>Press submit to unlock the logo settings</span>
            )}
          </div>
          <div className={classes.gradientRow}>
            <FieldWrapper
              label="Left color of gradient"
              labelSize={labelSize + 1}
              contentSize={contentSize - 1}
              fullWidth
              content={
                <Field
                  name="gradientColor1"
                  id="gradientColor1"
                  render={Input}
                  type="color"
                  disabled={processing}
                />
              }
            />
            <FieldWrapper
              label="Right color of gradient"
              labelSize={labelSize + 1}
              contentSize={contentSize - 1}
              fullWidth
              content={
                <Field
                  name="gradientColor2"
                  id="gradientColor2"
                  render={Input}
                  type="color"
                  disabled={processing}
                />
              }
            />
          </div>
          <FieldWrapper
            label="Header text"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <Field
                name="headerText"
                id="headerText"
                render={Input}
                disabled={processing}
              />
            }
          />
          <FieldWrapper
            label="Header text color"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <Field
                name="headerColor"
                id="headerColor"
                render={Input}
                type="color"
                disabled={processing}
              />
            }
          />
          <FieldWrapper
            label="Success message text"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <Field
                name="successMessageText"
                id="successMessageText"
                render={Input}
                disabled={processing}
              />
            }
          />
          <FieldWrapper
            label="Success message text color"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <Field
                name="successMessageColor"
                id="successMessageColor"
                render={Input}
                type="color"
                disabled={processing}
              />
            }
          />
          <FieldWrapper
            label="Failed message text"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <div className={classes.dialogTemplate}>
                <ActionButton
                  handler={event => setEmailAnchorEl(event.currentTarget)}
                  icon={<img src={iconPlus} alt="iconPlus" />}
                  toolTip="Add template variable"
                  disabled={processing}
                />
                <Field
                  name="failedMessageText"
                  id="failedMessageText"
                  render={Input}
                  disabled={processing}
                />
              </div>
            }
          />
          <FieldWrapper
            label="Failed message text color"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <Field
                name="failedMessageColor"
                id="failedMessageColor"
                render={Input}
                type="color"
                disabled={processing}
              />
            }
          />
        </AccordionDetails>
      </Accordion>
      <TemplateMenu
        anchorEl={emailAnchorEl}
        setAnchorEl={setEmailAnchorEl}
        form={form}
        name="failedMessageText"
        templateVariables={templateVariables}
      />
    </>
  );
};

ValidationScreenSettings.propTypes = {
  processing: PropTypes.bool,
  form: PropTypes.objectOf(PropTypes.any),
  values: PropTypes.objectOf(PropTypes.any),
  onReset: PropTypes.func,
};

export default ValidationScreenSettings;
