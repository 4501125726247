import incident from './incident';
import errors from './errors';
import crmTicket from './crmTicket';
import testEmail from './testEmail';
import chats from './chats';
import phoneNumbers from './phoneNumbers';
import countries from './countries';
import bufferPhoneNumbers from './bufferPhoneNumbers';
import messaging from './messaging';
import tenantModules from './admin';
import messagingDashboard from './messagingDashboard';
import triage from './triage';
import integration from './integration';
import connectWiseTicket from './connectWiseTicket';
import common from './common';
import notificationGroups from './notificationGroups';
import teamsConnections from './teamsConnections';
import crm from './crm';
import pod from './psaPods';
import treeView from './treeView';
import optIn from './optIn';
import extraResources from './billing';
import fileUpload from './fileUpload';
import ticketCreationTemplates from './ticketCreationTemplates';
import kaseyaTicket from './kaseyaTicket';
import autotaskTicket from './autotaskTicket';
import liveChats from './liveChats';
import chatBots from './chatBots';
import clientPortals from './clientPortalAdmin';
import clientPortalDefaults from './clientPortalDefaults';
import clientPortalDefaultsMapping from './clientPortalDefaultMapping';
import issueTypes from './issueTypes';
import secureData from './secureData';
import smsCampaignGroups from './smsCampaigns';
import auth from './auth';
import secureDataSettings from './secureDataSettings';
import createTicket from './createTicket';
import verificationSettings from './verificationSettings';
import optInSettings from './optInSettings';

export default {
  incident,
  errors,
  crmTicket,
  testEmail,
  chats,
  phoneNumbers,
  countries,
  bufferPhoneNumbers,
  messaging,
  tenantModules,
  messagingDashboard,
  triage,
  integration,
  connectWiseTicket,
  common,
  notificationGroups,
  teamsConnections,
  crmData: crm,
  pod,
  treeView,
  optIn,
  extraResources,
  fileUpload,
  ticketCreationTemplates,
  kaseyaTicket,
  autotaskTicket,
  liveChats,
  chatBots,
  clientPortals,
  clientPortalDefaults,
  clientPortalDefaultsMapping,
  issueTypes,
  secureData,
  smsCampaignGroups,
  auth,
  secureDataSettings,
  createTicket,
  verificationSettings,
  optInSettings,
};
