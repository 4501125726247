import React, { useState } from 'react';
import ReusableButton from '@common/Button/Button';
import InviteUsersDialog from '@components/InvitedUsers/dialogs/InviteUsersDialog';
import { getRoleIdFromStorage } from '@services/api';
import roles from '@constants/roles';

const InviteUsersTableActions = () => {
  const [openInviteUsersDialog, setOpenInviteUsersDialog] = useState(false);
  const allowEdit = getRoleIdFromStorage() !== roles.READ_ONLY;

  return (
    <div style={{ display: 'flex', margin: '5px 0px', gap: '5px' }}>
      {allowEdit && (
        <ReusableButton
          label="Invite"
          onClick={() => {
            setOpenInviteUsersDialog(true);
          }}
        />
      )}

      {openInviteUsersDialog && (
        <InviteUsersDialog
          open={openInviteUsersDialog}
          onClose={(_, reason) => {
            if (reason && reason === 'backdropClick') {
              return;
            }
            setOpenInviteUsersDialog(false);
          }}
        />
      )}
    </div>
  );
};

export default InviteUsersTableActions;
