import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px',
    background: 'white',
    borderRadius: '15px',
    padding: '20px',
    display: 'grid',
    '& > form': {
      width: '60%',
      margin: '30px auto',
    },
    '@media (max-width: 767px)': {
      '& > form': {
        width: '100%',
        margin: '10px auto',
      },
    },
  },
  deleteDialogActions: {
    margin: '10px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > button': {
      marginLeft: '5px',
    },
    '@media (max-width: 767px)': {
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: '10px',
      padding: '10px',
      '& > button': {
        width: '72vw',
      },
    },
  },
  deleteDialogInput: {
    minWidth: '550px',
  },
  deleteDialogFieldLabelInner: {
    maxWidth: '100%',
  },
  deleteDialogFieldLabelOuter: {
    padding: '1px 4px',
    textAlign: 'start',
    height: '35px',
  },
  twoColumns: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '1rem',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
    },
  },
  checkBoxAlignment: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 767px)': {
      justifyContent: 'flex-start',
    },
  },
  withNotesContainer: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    padding: '20px',
    gap: '20px',
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      overflow: 'auto',
    },
  },
}));

export default useStyles;
