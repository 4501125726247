import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { ReactComponent as ChartIcon } from '@assets/icons/chart.svg';
import { ReactComponent as DiscountIcon } from '@assets/icons/discount.svg';
import { ReactComponent as EventsIcon } from '@assets/icons/events.svg';
import { Typography } from '@material-ui/core';

import events from './events';
import format from './helpers';
import useStyles from './styles';

const StatisticItem = ({ children, classNameWrapper }) => {
  const classes = useStyles();

  return (
    <div
      className={cx(
        classes.statisticContainer_header,
        classes.statisticContainer_innerSpacing,
        classNameWrapper,
      )}
    >
      {children}
    </div>
  );
};

const renderTimeInfo = value => `${value?.toFixed(3)}%`;

const StatisticContainer = ({ statistic }) => {
  const event = events[statistic.currentStatus];
  const iconColor = event?.color;

  const classes = useStyles({ color: iconColor });

  const {
    timeLastStatusChanged,
    dateFormat,
    timeLatestDownTime,
  } = useMemo(() => {
    const formatType = 'YYYY-MM-DD HH:mm:ss';
    const { lastStatusChange, lastDownTime } = statistic;
    const value =
      moment.utc().valueOf() - moment.utc(lastStatusChange).valueOf();

    return {
      timeLastStatusChanged: format(Math.floor(value / 1000 / 60) || 0),
      dateFormat:
        lastStatusChange &&
        moment
          .parseZone(lastStatusChange)
          .local()
          .format(formatType),
      timeLatestDownTime:
        lastDownTime &&
        moment
          .parseZone(lastDownTime)
          .local()
          .format(formatType),
    };
  }, [statistic]);

  const text = `Since ${timeLastStatusChanged} ( ${dateFormat} )`;

  const textLatestDownTime =
    timeLatestDownTime && `It was recorded on ${timeLatestDownTime}`;
  return (
    <>
      <StatisticItem classNameWrapper={classes.classNameStatisticItem}>
        <EventsIcon width={20} height={20} fill={iconColor} />
        <span className={cx(classes.bold, classes.info, classes.leftSpacing)}>
          Current Status
        </span>
      </StatisticItem>
      <StatisticItem>
        <div className={classes.classNameStatisticItem}>
          <EventsIcon width={30} height={30} fill={iconColor} />
          <Typography
            className={classes.leftSpacing}
            variant="h5"
            component="div"
          >
            {event && event.name}
          </Typography>
        </div>
        <p>{text}</p>
      </StatisticItem>
      <StatisticItem
        classNameWrapper={cx(
          classes.statisticContainer_outerSpacingTop,
          classes.classNameStatisticItem,
        )}
      >
        <>
          <ChartIcon width={20} height={20} fill={iconColor} />
          <span className={cx(classes.bold, classes.info, classes.leftSpacing)}>
            Uptime
          </span>
        </>
      </StatisticItem>
      <StatisticItem classNameWrapper={classes.classNameStatisticItem}>
        <>
          <DiscountIcon width={20} height={20} stroke={iconColor} />
          <span className={cx(classes.timeInfo, classes.leftSpacing)}>
            {renderTimeInfo(statistic.last24H)}
          </span>
          <span className={classes.leftSpacing}>( last 24 hours)</span>
        </>
      </StatisticItem>
      <StatisticItem classNameWrapper={classes.classNameStatisticItem}>
        <>
          <DiscountIcon width={20} height={20} stroke={iconColor} />
          <span className={cx(classes.timeInfo, classes.leftSpacing)}>
            {renderTimeInfo(statistic.last7D)}
          </span>
          <span className={classes.leftSpacing}>( last 7 days)</span>
        </>
      </StatisticItem>
      <StatisticItem classNameWrapper={classes.classNameStatisticItem}>
        <>
          <DiscountIcon width={20} height={20} stroke={iconColor} />
          <span className={cx(classes.timeInfo, classes.leftSpacing)}>
            {renderTimeInfo(statistic.last30D)}
          </span>
          <span className={classes.leftSpacing}>( last 30 days)</span>
        </>
      </StatisticItem>
      <StatisticItem
        classNameWrapper={classes.statisticContainer_outerSpacingTop}
      >
        <span className={cx(classes.bold, classes.warning)}>
          Latest downtime
        </span>
      </StatisticItem>
      <StatisticItem>
        <span>{textLatestDownTime}</span>
      </StatisticItem>
    </>
  );
};

StatisticContainer.propTypes = {
  statistic: PropTypes.objectOf(PropTypes.any),
};
StatisticItem.propTypes = {
  children: PropTypes.node,
  classNameWrapper: PropTypes.string,
};

export default StatisticContainer;
