import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import ReusableButton from '@common/Button/Button';
import MultiSelectDialog from '@common/MultiSelectDialog/MultiSelectDialog';
import useStyles from './styles';

const SyncCompaniesDialog = ({
  open,
  onClose,
  statuses,
  types,
  onSelect,
  syncSettings,
}) => {
  const classes = useStyles();
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  useEffect(() => {
    setSelectedStatuses(syncSettings?.statuses ?? []);
    setSelectedTypes(syncSettings?.types ?? []);
  }, [syncSettings]);

  const handleSubmit = () => {
    const statusList = selectedStatuses.map(i => i.value);
    const typesList = selectedTypes.map(i => i.value);
    onSelect({
      statuses: statusList,
      types: typesList,
    });
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="xl">
      <div className={classes.dialogWidth}>
        <DialogTitle>Set synchronization parameters</DialogTitle>
        <DialogContent>
          <div className={classes.dialogInfo}>
            <span className={classes.label}>
              Left filters blank to synchronize companies with all statuses
              or/and types
            </span>
          </div>

          <MultiSelectDialog
            title="Company statuses"
            values={selectedStatuses}
            setValues={setSelectedStatuses}
            fullArray={statuses}
          />

          <MultiSelectDialog
            title="Company types"
            values={selectedTypes}
            setValues={setSelectedTypes}
            fullArray={types}
          />
        </DialogContent>
        <DialogActions>
          <ReusableButton
            label="resources.buttons.cancel"
            classNameWrapper={classes.button}
            onClick={onClose}
          />
          <ReusableButton
            viewType="black"
            label="resources.buttons.confirm"
            classNameWrapper={classes.button}
            onClick={handleSubmit}
          />
        </DialogActions>
      </div>
    </Dialog>
  );
};

SyncCompaniesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  syncSettings: PropTypes.objectOf(PropTypes.any).isRequired,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
  types: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
};

export default SyncCompaniesDialog;
