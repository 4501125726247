import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { ThunkBuyPhoneNumber } from '@store/slices/phoneNumbers/thunks';

import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import ReusableButton from '@ui/components/common/Button/Button';
import PurchaseFilters from '../components/PurchaseFilters';

import { tableDefaultFilters } from '../constants';

import sharedStyles from '../../CrmTicket/sharedStyles';

const PurchasePhoneNumber = ({ open, setOpen, setUpdateTableDataTrigger }) => {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  const handleBuy = (phoneNumber, country, type) => {
    dispatch(ThunkBuyPhoneNumber({ phoneNumber, country, type }));
    setOpen(false);
    setUpdateTableDataTrigger(prevState => !prevState);
  };

  const columns = [
    {
      name: 'Number',
      key: 'dedicatedNumber',
      isPhoneNumber: true,
    },
    {
      name: 'Country',
      key: 'country',
      width: 100,
    },
    {
      name: 'Monthly Fee',
      key: 'priceMonthly',
      width: 200,
    },
    {
      name: 'Setup Fee',
      width: 100,
      key: 'priceSetup',
      accessor: rowData => (
        <>{rowData.priceSetup === '0.0000' ? 'Free' : rowData.priceSetup}</>
      ),
    },
    {
      name: 'Type',
      key: 'type',
      isUpperCase: true,
      width: 100,
    },
    {
      name: 'Actions',
      key: 'actions',
      width: 100,
      accessor: rowData => (
        <ReusableButton
          label="Buy"
          onClick={() => {
            handleBuy(rowData.dedicatedNumber, rowData.country, rowData.type);
          }}
        />
      ),
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Purchase Phone Number</DialogTitle>
      <DialogContent>
        <MuiVirtualizedTable
          actions={<></>}
          columns={columns}
          resource="MessagingPhoneNumbers/numbers/search"
          defaultFilters={tableDefaultFilters}
          selectorKey="messagingPhoneNumbers"
        >
          <PurchaseFilters />
        </MuiVirtualizedTable>

        <div className={classes.dialogActionsContainer}>
          <ReusableButton
            label="Close"
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

PurchasePhoneNumber.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setUpdateTableDataTrigger: PropTypes.func.isRequired,
};
export default PurchasePhoneNumber;
