import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ReusableButton from '@common/Button/Button';
import PlanBenefits from '@ui/pages/auth/SignUp/components/PlanBenefits';
import AppLogo from '@ui/common/AppLogo';
import Input from '@components/Auth/Common/Input';

import { signUpPages } from '../constants';
import { SignInContext, SingInDispatchContext } from '../context';

import useLoginStyles from '../../Login/styles';
import useStyles from '../styles';

const BillingPlans = ({ pricePlans, handleBack, handleForward }) => {
  const sharedClasses = useLoginStyles();
  const classes = useStyles();
  const contextDispatch = useContext(SingInDispatchContext);
  const { selectedPlanId, technicianCount, initTechnicianCount } = useContext(
    SignInContext,
  );

  const handlePlanSelection = useCallback(
    planId => {
      contextDispatch({
        type: 'setSelectedPlan',
        planId,
      });
    },
    [contextDispatch],
  );

  const handleTechnicianCountChange = useCallback(
    count => {
      contextDispatch({
        type: 'setTechnicianCount',
        technicianCount: count,
      });
    },
    [contextDispatch],
  );

  const selectedPricePlan = useMemo(() => {
    return pricePlans.find(i => i.id === selectedPlanId) ?? [];
  }, [pricePlans, selectedPlanId]);

  const planBenefits = useMemo(() => {
    return selectedPricePlan.scopes ?? { Header: '', Items: [] };
  }, [selectedPricePlan]);

  useLayoutEffect(() => {
    if (selectedPlanId) {
      return;
    }
    if (pricePlans?.length) {
      handlePlanSelection(pricePlans[0].id);
    }
  }, [handlePlanSelection, pricePlans]);

  const totalPrice = useCallback(() => {
    const techOver = technicianCount - initTechnicianCount;
    return (
      (selectedPricePlan.basePriceCents +
        selectedPricePlan.pricePerTechCents * techOver) /
      100
    );
  }, [
    initTechnicianCount,
    selectedPricePlan.basePriceCents,
    selectedPricePlan.pricePerTechCents,
    technicianCount,
  ]);

  return (
    <>
      <PlanBenefits planBenefits={planBenefits} />
      <div className={sharedClasses.activeContainer}>
        <AppLogo />

        <div className={sharedClasses.typographyContainer}>
          <h5>Select Your Plan</h5>
        </div>
        <div className={classes.plansContainer}>
          {pricePlans.map(i => (
            <div
              key={i.id}
              className={cx(
                {
                  [classes.selectedPlan]: selectedPlanId === i.id,
                },
                classes.pricePlan,
              )}
              onClick={() => {
                handlePlanSelection(i.id);
              }}
              role="presentation"
            >
              <div>
                <b>{i.name}</b>
              </div>

              <div>
                {`$${Number(i.basePriceCents / 100).toFixed(
                  2,
                )} for the first ${initTechnicianCount} technicians`}
              </div>

              <div>
                {`$${Number(i.pricePerTechCents / 100).toFixed(
                  2,
                )} for each next technician`}
              </div>
            </div>
          ))}

          <div className={classes.technicianCount}>
            <div>Technician Licenses Count</div>
            <Input
              input={{
                value: technicianCount,
                onChange: e => {
                  if (
                    !Number.isNaN(Number(e.target.value)) &&
                    String(e.target.value).length < 5
                  ) {
                    handleTechnicianCountChange(e.target.value);
                  }
                },
              }}
              meta={{
                touched: false,
              }}
            />
          </div>

          <div className={classes.totalValue}>
            <div>Total: </div>
            <div>{`$${Number(totalPrice()).toFixed(2)}`}</div>
          </div>

          <div className={classes.error}>
            {+technicianCount < +initTechnicianCount &&
              `Minimal technician licenses is ${initTechnicianCount}`}
          </div>

          <div className={classes.billingButtons}>
            <ReusableButton
              label="Back"
              onClick={() => {
                handleBack(signUpPages.billingPlan);
              }}
            />
            <ReusableButton
              label="Next"
              onClick={() => {
                handleForward(signUpPages.billingPlan);
              }}
              viewType="blue"
              disabled={+technicianCount < +initTechnicianCount}
            />
          </div>
        </div>
      </div>
    </>
  );
};

BillingPlans.propTypes = {
  pricePlans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  handleBack: PropTypes.func.isRequired,
  handleForward: PropTypes.func.isRequired,
};
export default BillingPlans;
