import { createSlice } from '@reduxjs/toolkit';
import { COUNTRIES } from '../resources';
import { ThunkGetCountries } from './thunks/getCountries';

const initialState = {
  list: [],
};

const phoneNumbersSlice = createSlice({
  name: COUNTRIES,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ThunkGetCountries.fulfilled, (state, { payload }) => {
      state.list = payload;
    });
  },
});

export default phoneNumbersSlice.reducer;

export const countryCodesListSelector = state => state[COUNTRIES].list;
