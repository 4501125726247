import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThunkGetPhoneNumbers } from '@store/slices/phoneNumbers/thunks';
import {
  PhoneNumbersListSelector,
  PhoneNumbersLoadingSelector,
} from '@store/slices/phoneNumbers';

import ActionField from '@common/ActionFields/ActionField';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { ThunkDeletePhoneNumber } from '@store/slices/phoneNumbers/thunks/deletePhoneNumber';
import { useNotify } from 'react-admin';
import nationalFormatPhoneNumber from '@utils/nationalFormatPhoneNumber';
import SimpleTable from '../Triggers/pages/Incident/components/SimpleTable';
import Loading from '../common/Loading/Loading';

import useStyles from './styles';

const PhoneNumbers = () => {
  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const isLoading = useSelector(PhoneNumbersLoadingSelector);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);

  const onDelete = record => {
    setIdForDelete(record.id);
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setIdForDelete(null);
    setDeleteDialogOpen(false);
    setLoading(false);
  };

  const handleDelete = () => {
    setLoading(true);
    dispatch(ThunkDeletePhoneNumber({ id: idForDelete }))
      .catch(e => notify(e.message, 'error'))
      .finally(handleCancelDelete);
  };

  const columns = useMemo(
    () => [
      {
        label: 'Phone Number',
        value: 'phoneNumber',
        accessor: phoneNumber => <>{nationalFormatPhoneNumber(phoneNumber)}</>,
      },
      {
        label: 'Region',
        value: 'region',
      },
      {
        label: 'Role',
        value: 'role',
      },
      {
        label: 'Actions',
        handler: rowData => (
          <div className={classes.actionField}>
            <ActionField record={rowData} onDelete={onDelete} />
          </div>
        ),
      },
    ],
    [classes.actionField],
  );

  const onMount = useCallback(() => {
    dispatch(ThunkGetPhoneNumbers(true));
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (isLoading) return <Loading />;
  return (
    <div className={classes.container}>
      <SimpleTable columns={columns} selector={PhoneNumbersListSelector} />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDelete}
        onCancel={handleCancelDelete}
        title="Are you sure to delete this phone number?"
        content="This operation can not be undone."
        loading={loading}
      />
    </div>
  );
};

export default PhoneNumbers;
