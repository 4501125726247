import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'end',
    paddingRight: '15px',
    '& > span': {
      display: 'flex',
    },
  },
  icon: {
    marginRight: '0.8rem',
    overflow: 'visible !important',
    '&:last-child': {
      marginRight: 0,
    },
  },
  iconImageSize: {
    width: '20px',
    height: '20px',
  },
  iconImage: {
    transition: '0.3s',

    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.2)',
    },
  },
  iconSpacing: {
    margin: '5px 10px 0 0',
  },
  disabled: {
    opacity: 0.4,
  },
}));

export default useStyles;
