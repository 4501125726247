import React from 'react';
import { useVersion, useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import MuiVirtualizedTable from '@common/MuiVirtualizedTable';
import TableLabel from '@common/TableLabel/TableLabel';
import { toggleGlobal } from '@components/MailBox/helpers';
import { actions } from '@store/actions';
import ActionField from './ActionField';
import useStyles from './styles';

const List = props => {
  const { resource } = props;
  const notify = useNotify();
  const version = useVersion();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleToggleGlobal = record => {
    toggleGlobal(record.id, !record.isGlobal)
      .then(data => {
        const nerRecord = {
          ...record,
          isGlobal: !record.isGlobal,
          tenantName: data.tenantName,
        };
        dispatch(actions.updateItemInList(nerRecord));
      })
      .catch(error => notify(error.message, 'error'));
  };

  const columns = [
    {
      name: <TableLabel item={{ name: 'Name', field: 'name' }} />,
      key: 'name',
    },
    {
      name: <TableLabel item={{ name: 'Tenant', field: 'tenantName' }} />,
      key: 'tenantName',
    },
    {
      name: 'Actions',
      key: 'actions',
      accessor: rowData => (
        <ActionField record={rowData} handleMakeGlobal={handleToggleGlobal} />
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <MuiVirtualizedTable
        key={version}
        defaultSort={{ field: 'id', order: 'ASC' }}
        bulkActions={null}
        actions={null}
        filters={null}
        columns={columns}
        resource={resource}
      />
    </div>
  );
};

List.propTypes = {
  resource: PropTypes.string,
};

export default List;
