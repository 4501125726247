import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';

import ReusableButton from '@ui/components/common/Button/Button';
import useStyles from './styles';
import TypesListItem from './TypesListItem';
import { cloneType, deleteType } from '../helpers';
import CreateTypeDialog from './dialogs/CreateTypeDialog';

const TypesList = React.memo(({ types, setSelectedType, setTypes }) => {
  const [addTypeDialog, setAddTypeDialog] = useState(false);
  const classes = useStyles();
  const notify = useNotify();

  const handleViewClick = useCallback(
    (id, name, readOnly) => {
      setSelectedType({ id, name, readOnly });
    },
    [setSelectedType],
  );

  const handleCloneClick = useCallback(
    id => {
      cloneType(id)
        .then(res => {
          notify('Successfully cloned', 'success');
          setTypes([...types, res]);
        })
        .catch(err => {
          notify(err.message, 'error');
        });
    },
    [setTypes, notify, types],
  );

  const handleDeleteClick = useCallback(
    id => {
      deleteType(id)
        .then(() => {
          notify('Successfully deleted', 'success');
          setTypes(types.filter(type => type.id !== id));
          setSelectedType(0);
        })
        .catch(err => {
          notify(err.message, 'error');
        });
    },
    [setTypes, notify, types, setSelectedType],
  );

  const handleAddClick = useCallback(
    newType => {
      setTypes([...types, newType]);
    },
    [setTypes, types],
  );

  return (
    <div className={classes.typesListContainer}>
      <div className={classes.typesListHeader}>
        <div>
          <b>Types List</b>
        </div>
        <div>
          <ReusableButton
            onClick={() => {
              setAddTypeDialog(true);
            }}
            label="Add"
          />
        </div>
      </div>

      {types.map(({ name, id, readOnly }) => (
        <TypesListItem
          key={id}
          name={name}
          id={id}
          readOnly={readOnly}
          handleViewClick={() => {
            handleViewClick(id, name, readOnly);
          }}
          handleCloneClick={() => {
            handleCloneClick(id);
          }}
          handleDeleteClick={() => {
            handleDeleteClick(id);
          }}
        />
      ))}

      {addTypeDialog && (
        <CreateTypeDialog
          open={addTypeDialog}
          onClose={() => {
            setAddTypeDialog(false);
          }}
          onSubmit={item => {
            handleAddClick(item);
          }}
        />
      )}
    </div>
  );
});

TypesList.propTypes = {
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      readOnly: PropTypes.bool,
    }),
  ),
  setSelectedType: PropTypes.func,
  setTypes: PropTypes.func,
};
export default TypesList;
