import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import DropDown from '@components/Auth/Common/DropDown';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import ReusableButton from '@ui/components/common/Button/Button';

import { requiredValidator } from '@utils/validators';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import {
  createAutotaskWebhook,
  getAutotaskIntegrationAutocomplete,
} from '../helpers';

import useStyles from '../styles';

const CreateAutotaskWebhookDialog = ({ open, setOpen }) => {
  const [loading, setLoading] = useState(true);
  const [integrations, setIntegrations] = useState([]);

  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const list = useSelector(listSelector);

  const validation = values => {
    return {
      integrationId: requiredValidator(values.integrationId)
        ? 'Required'
        : undefined,
    };
  };

  const submit = values => {
    setLoading(true);
    createAutotaskWebhook(values)
      .then(res => {
        notify('Successfully created', 'success');
        setOpen(false);
        dispatch(actions.saveList([...list, res]));
      })
      .catch(err => {
        notify(err.message, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onMount = useCallback(() => {
    setLoading(true);
    getAutotaskIntegrationAutocomplete()
      .then(res => {
        setIntegrations(res);
      })
      .catch(err => {
        notify(err.message, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [notify]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="simple-dialog-title"
    >
      <DialogTitle>Create</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validation}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <FieldWrapper
                label="Autotask Integration"
                labelSize={12}
                contentSize={12}
                showLabel
                classNameLabelContainer={classes.dialogLabelContainer}
                classNameLabelInner={classes.dialogLabelText}
                isRequired
                content={
                  <Field
                    name="integrationId"
                    id="integrationId"
                    render={DropDown}
                    disabled={loading}
                    options={integrations}
                  />
                }
              />
              <div className={classes.buttonContainer}>
                <ReusableButton
                  label="Cancel"
                  disabled={loading}
                  onClick={() => {
                    setOpen(false);
                  }}
                />

                <ReusableButton
                  label="Create"
                  type="submit"
                  disabled={loading}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

CreateAutotaskWebhookDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default CreateAutotaskWebhookDialog;
