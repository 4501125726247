import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  notesContainer: {
    background: '#F7F9FB',
    padding: '20px',
    borderRadius: '10px',
  },
  notesContainerHeader: {
    fontWeight: '800',
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '5px',
  },
  notesContainerHeaderLoader: {
    color: 'black',
    width: '20px !important',
    height: '20px !important',
  },
  notesList: {
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '80vh',
    height: '74vh',
    overflow: 'auto',
  },
  note: {
    background: 'white',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '15px',
    gap: '5px',
  },
  noteAuthorDate: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#646464',
    fontSize: '12px',
  },
  noteTitle: {
    fontWeight: '800',
  },
  noteLabels: {
    fontWeight: '800',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  noteLabelRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
