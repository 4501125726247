import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import {
  SelectResourceOptions,
  SelectResourceOptionsLoading,
} from '@store/slices/liveChats/selectors';
import {
  ThunkGetResources,
  ThunkLiveChatScript,
} from '@store/slices/liveChats/thunks';

import copyText from '@utils/copy';
import getFormattedDate from '@utils/getFormattedDate';
import { LIVE_CHAT_CONFIGURATIONS } from '@constants/routes';

import { ReactComponent as CopyIcon } from '@assets/icons/copy_blue.svg';

import Loading from '@common/Loading/Loading';
import DropDown from '@components/Auth/Common/DropDown';
import BooleanAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanAccessor';
import ReusableButton from '@common/Button/Button';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import TableDropDown from '@common/TableDropDown/TableDropDown';
import LiveChatActions from '@components/LiveChat/components/LiveChatActions';
import TableLabel from '@common/TableLabel/TableLabel';
import LiveChatTableItemActions from '@components/LiveChat/components/LiveChatTableItemActions';

import useStyles from './styles';

const isActiveOptions = {
  true: { name: 'true', value: 'true' },
  false: { name: 'false', value: 'false' },
};

const valueHandler = key => isActiveOptions[key]?.value;

const defaultSort = { field: 'lastMessageDate', order: 'DESC' };

const LiveChat = () => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const classes = useStyles();
  const { push } = useHistory();

  const resourcesOptions = useSelector(SelectResourceOptions);
  const resourcesOptionsLoading = useSelector(SelectResourceOptionsLoading);

  const [selectedResource, setSelectedResource] = useState(undefined);

  const copyScript = useCallback(
    id => {
      dispatch(ThunkLiveChatScript(id))
        .unwrap()
        .then(p => {
          copyText(p.data);
          notify('Script copied to the clipboard');
        });
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        name: (
          <TableDropDown
            item={{ name: 'Is Answered', field: 'isAnswered' }}
            options={isActiveOptions}
            valueHandler={valueHandler}
            hideSorting
          />
        ),
        key: 'active',
        accessor: rowData => (
          <BooleanAccessor value={rowData.isAnswered} useMultiColor />
        ),
      },
      {
        name: (
          <TableDropDown
            item={{ name: 'Is Closed', field: 'isClosed' }}
            options={isActiveOptions}
            valueHandler={valueHandler}
            hideSorting
          />
        ),
        key: 'isClosed',
        accessor: rowData => (
          <BooleanAccessor value={rowData.isClosed} useMultiColor />
        ),
      },
      {
        name: 'Last Message Date',
        key: 'lastMessageDate',
        sort: 'lastMessageDate',
        accessor: i => <>{getFormattedDate(i.lastMessageDate)}</>,
      },
      {
        name: (
          <TableLabel
            item={{ name: 'Last Message', field: 'lastMessage' }}
            showSortButton={false}
          />
        ),
        key: 'lastMessage',
      },
      {
        name: (
          <TableLabel
            item={{ name: 'User Identity', field: 'userIdentity' }}
            showSortButton={false}
          />
        ),
        key: 'userIdentity',
      },
      {
        name: (
          <TableLabel
            item={{ name: 'User Name', field: 'userName' }}
            showSortButton={false}
          />
        ),
        key: 'userName',
      },
      {
        name: (
          <TableLabel
            item={{ name: 'Ticket ID', field: 'ticketId' }}
            showSortButton={false}
          />
        ),
        key: 'ticketId',
        accessor: item => (
          <div
            style={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            role="presentation"
            onClick={() => {
              window.open(item.ticketLink, '_blank');
            }}
          >
            {item?.ticketId}
          </div>
        ),
      },
      {
        name: (
          <TableLabel
            item={{ name: 'Assignee', field: 'assigneeName' }}
            showSortButton={false}
          />
        ),
        key: 'assigneeName',
      },
      {
        name: 'Actions',
        key: 'actions',
        accessor: item => (
          <LiveChatTableItemActions
            {...item}
            selectedResource={selectedResource}
          />
        ),
      },
    ],
    [selectedResource],
  );

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetResources())
      .unwrap()
      .then(resources => {
        if (resources.length === 1) {
          setSelectedResource(resources[0].id);
        }
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (resourcesOptionsLoading) return <Loading />;

  return (
    <div className={classes.container}>
      <div className={classes.tableActions}>
        <div className={classes.tableActionContainer}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '10px',
            }}
          >
            <div style={{ width: '100%' }}>
              <DropDown
                options={resourcesOptions}
                input={{
                  onChange: e => setSelectedResource(e.target.value),
                  value: +selectedResource,
                }}
              />
            </div>
            <ReusableButton
              label="Configurations"
              onClick={() => push(LIVE_CHAT_CONFIGURATIONS)}
              size="xl"
            />
            {selectedResource && (
              <Tooltip title="Copy script" style={{ cursor: 'pointer' }}>
                <CopyIcon onClick={() => copyScript(selectedResource)} />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      {selectedResource && (
        <div className={classes.tableContainer}>
          <MuiVirtualizedTable
            actions={<LiveChatActions />}
            columns={columns}
            resource={`LiveChatAdmin/resources/${selectedResource}/chats`}
            defaultFilters={{ isClosed: false }}
            defaultSort={defaultSort}
          />
        </div>
      )}
    </div>
  );
};

export default LiveChat;
