import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { ThunkGetTicketLink } from '@store/slices/crmTicket/thunks';

import MuiVirtualizedTable from '@ui/components/common/MuiVirtualizedTable';
import TableLabel from '@ui/components/common/TableLabel/TableLabel';
import AutotaskTicketTableFilters from './AutotaskTicketTableFilters';
import AutotaskTicketTableActions from './AutotaskTicketTableActions';

import useStyles from '../../styles';

const AutotaskTicketTable = ({ defaultId, crmId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [defaultFilters, setDefaultFilters] = useState({ skipCompleted: true });

  const handleTicketIdClick = async ({ ticketNumber: ticketId }) => {
    dispatch(ThunkGetTicketLink({ ticketId, crmId }))
      .unwrap()
      .then(res => {
        window.open(res.data, '_blank');
      });
  };

  const handleDefaultFiltersChange = useCallback(filters => {
    setDefaultFilters({ ...defaultFilters, ...filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const autotaskColumns = [
    {
      name: (
        <TableLabel
          item={{ field: 'ticketNumber', name: 'ID' }}
          showSortButton={false}
        />
      ),
      key: 'ticketNumber',
      accessor: item => (
        <div
          className={classes.link}
          onClick={() => {
            handleTicketIdClick(item);
          }}
          role="presentation"
        >
          {item.ticketNumber}
        </div>
      ),
    },
    {
      name: 'Company',
      key: 'company',
    },
    {
      name: 'Queue',
      key: 'board',
    },
    {
      name: 'Issue Type',
      key: 'issueType',
    },
    {
      name: 'Priority',
      key: 'priority',
    },
    {
      name: 'Source',
      key: 'source',
    },
    {
      name: 'Status',
      key: 'status',
    },
    {
      name: 'Ticket Category',
      key: 'ticketCategory',
    },
    {
      name: 'Ticket Type',
      key: 'ticketType',
    },
    {
      name: (
        <TableLabel
          item={{ field: 'title', name: 'Title' }}
          showSortButton={false}
        />
      ),
      key: 'title',
    },
    {
      name: 'Resource',
      key: 'assignedResource',
    },
    {
      name: 'Contact',
      key: 'contact',
    },
    {
      name: 'Actions',
      key: 'actions',
      accessor: item => (
        <AutotaskTicketTableActions
          record={item}
          crmDefaultId={defaultId}
          crmId={crmId}
        />
      ),
    },
  ];

  return (
    <div>
      <MuiVirtualizedTable
        actions={<></>}
        columns={autotaskColumns}
        resource={`TicketUpdate/autotask/${defaultId}/tickets`}
        defaultFilters={defaultFilters}
      >
        <AutotaskTicketTableFilters
          defaultId={defaultId}
          handleDefaultFiltersChange={handleDefaultFiltersChange}
        />
      </MuiVirtualizedTable>
    </div>
  );
};

AutotaskTicketTable.propTypes = {
  defaultId: PropTypes.number.isRequired,
};
export default AutotaskTicketTable;
