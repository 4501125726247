import React, { useState } from 'react';
import { useListContext, useNotify } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ReusableButton from '@ui/components/common/Button/Button';

import {
  deleteWebHookResults,
  reParseWebHookResults,
} from '@ui/components/WebHooks/helpers';
import { listSelector } from '@store/selectors';
import { actions } from '@store/actions';

import { WEB_HOOKS_RESULT_UNRECOGNIZED } from '@constants/routes';

import { BuildOutlined as BuildOutlinedIcon } from '@material-ui/icons';
import iconDelete from '@assets/icons/trash-box.svg';
import iconRefresh from '@assets/icons/refresh.svg';
import useStyles from '../styles';

const BulkActions = () => {
  const [loading, setLoading] = useState(false);
  const { selectedIds, resetSelectedIds } = useListContext();
  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const list = useSelector(listSelector);
  const { push } = useHistory();

  const handleDelete = selectedIds => {
    setLoading(true);
    const ids = selectedIds.map(id => `id=${id}`).join('&');
    deleteWebHookResults(ids)
      .then(() => {
        const newList = list.filter(item => !selectedIds.includes(item.id));
        dispatch(actions.saveList(newList));
        notify('Webhook results deleted', 'info');
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => {
        resetSelectedIds();
        setLoading(false);
      });
  };

  const handleReParse = selectedIds => {
    setLoading(true);
    const ids = selectedIds.map(id => `resultId=${id}`).join('&');
    reParseWebHookResults(ids)
      .then(() => {
        const newList = list.filter(item => !selectedIds.includes(item.id));
        dispatch(actions.saveList(newList));
        notify('Web hook results send again', 'info');
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => {
        resetSelectedIds();
        setLoading(false);
      });
  };

  const handleFix = () => {
    const ids = selectedIds.map(id => `id=${id}`).join('&');
    if (
      list.some(
        item => selectedIds.includes(String(item.id)) && item.status !== '2',
      )
    ) {
      push(WEB_HOOKS_RESULT_UNRECOGNIZED.replace(':id', ids));
    }
    notify('Web hook results already fixed', 'info');
  };

  return (
    <div className={classes.bulkActions}>
      <ReusableButton
        viewType="red"
        label="Delete"
        onClick={() => {
          handleDelete(selectedIds);
        }}
        children={
          <>
            <img src={iconDelete} alt="Delete" /> Delete
          </>
        }
        loading={loading}
      />

      <ReusableButton
        label="Proceed again"
        onClick={() => {
          handleReParse(selectedIds);
        }}
        children={
          <>
            <img src={iconRefresh} alt="Send" /> Send again
          </>
        }
        loading={loading}
      />

      <ReusableButton
        label="Proceed again"
        onClick={() => {
          handleFix(selectedIds);
        }}
        size="md"
        children={
          <>
            {' '}
            <BuildOutlinedIcon /> Fix
          </>
        }
        loading={loading}
        classNameWrapper={classes.fixButton}
      />
    </div>
  );
};

export default BulkActions;
