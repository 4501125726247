import putData from '@services/api/common/putData';
import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import { genericPipelines, genericSources } from '@constants/appRoutes';
import deleteData from '@services/api/common/deleteData';

export const toggleEnabled = (id, data) =>
  putData(`${appConfig.baseUrl}${genericSources}${id}/makeEnabled`, data);

export const getDetails = id =>
  getData(`${appConfig.baseUrl}${genericSources}${id}`);

export const getGenericPipelinesOptions = () =>
  getData(`${appConfig.baseUrl}${genericPipelines}?type=2`);

export const deleteRecord = id =>
  deleteData(`${appConfig.baseUrl.concat(genericSources)}${id}`);

export const getFieldTransformers = (srcId, pipelineId) =>
  getData(
    `${appConfig.baseUrl}${genericSources}getFieldTransformer?srcId=${srcId}&pipelineId=${pipelineId}`,
  );

export const createDataView = (id, data) =>
  putData(`${appConfig.baseUrl}${genericSources}${id}/createDataView`, data);
