import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tableWrapper: {
    marginTop: '4rem',
  },
  buttonWrapper: {
    margin: '0 0 5px 25px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  monoSpace: {
    fontFamily: 'monospace',
  },
  textCenter: {
    textAlign: 'center',
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: '0.25rem',
  },
  marginTop: {
    marginTop: '1rem',
    minWidth: '21rem',
    display: 'flex',
    justifyContent: 'space-around',
  },
  booleanFieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginTop: '1vh',
  },
  couponInput: {
    width: '12vh',
  },
  componentsContainer: {
    minHeight: '6rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
