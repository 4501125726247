import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MaterialAvatar from '@material-ui/core/Avatar';
import { userDataSelector } from '@store/selectors';
import { getUserImageFromStorage } from '@services/api';
import { PROFILE_ROUTE } from '@constants/routes';

import { Tooltip } from '@material-ui/core';
import useStyles from './styles';

const Avatar = () => {
  const classes = useStyles();

  const history = useHistory();
  const userData = useSelector(userDataSelector);

  return (
    <Tooltip title="Profile">
      <MaterialAvatar
        className={classes.avatar}
        onClick={() => history.push(PROFILE_ROUTE)}
        src={userData.imageUri || getUserImageFromStorage()}
      />
    </Tooltip>
  );
};

export default Avatar;
