import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import MuiVirtualizedTable from '@ui/components/common/MuiVirtualizedTable';
import TableSortLabel from '@ui/components/common/TableLabel/TableSortLabel';
import TableLabel from '@ui/components/common/TableLabel/TableLabel';

import ConnectWiseTicketTableFilters from './ConnectWiseTicketTableFilters';
import ConnectWiseTicketTableActions from './ConnectWiseTicketTableActions';

import useStyles from '../../styles';

const ConnectWiseTicketTable = ({ defaultId, crmId }) => {
  const classes = useStyles();
  const [defaultFilters, setDefaultFilters] = useState({});

  const handleTicketIdClick = async ticketLink => {
    window.open(ticketLink, '_blank');
  };

  const handleDefaultFiltersChange = useCallback(filters => {
    setDefaultFilters({ ...defaultFilters, ...filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectWiseColumns = [
    {
      name: <TableLabel item={{ field: 'id', name: 'ID' }} numbersOnly />,
      key: 'id',
      accessor: item => (
        <div
          className={classes.link}
          onClick={() => {
            handleTicketIdClick(item.ticketLink);
          }}
          role="presentation"
        >
          {item.id}
        </div>
      ),
    },
    {
      name: <TableSortLabel item={{ field: 'board', name: 'Board' }} />,
      key: 'board',
    },
    {
      name: <TableSortLabel item={{ field: 'status', name: 'Status' }} />,
      key: 'status',
    },
    {
      name: <TableSortLabel item={{ field: 'company', name: 'Company' }} />,
      key: 'company',
    },
    {
      name: <TableLabel item={{ field: 'contact', name: 'Contact' }} />,
      key: 'contact',
    },
    {
      name: <TableSortLabel item={{ field: 'priority', name: 'Priority' }} />,
      key: 'priority',
    },
    {
      name: <TableSortLabel item={{ field: 'location', name: 'Location' }} />,
      key: 'location',
    },
    {
      name: (
        <TableSortLabel item={{ field: 'department', name: 'Department' }} />
      ),
      key: 'department',
    },
    {
      name: <TableSortLabel item={{ field: 'owner', name: 'Owner' }} />,
      key: 'owner',
    },
    {
      name: <TableLabel item={{ field: 'resources', name: 'Resources' }} />,
      key: 'resources',
    },
    {
      name: <TableLabel item={{ field: 'severity', name: 'Severity' }} />,
      key: 'severity',
    },
    {
      name: <TableLabel item={{ field: 'impact', name: 'Impact' }} />,
      key: 'impact',
    },
    {
      name: <TableLabel item={{ field: 'summary', name: 'Summary' }} />,
      key: 'summary',
    },
    {
      name: 'Actions',
      key: 'actions',
      accessor: item => (
        <ConnectWiseTicketTableActions
          record={item}
          crmDefaultId={defaultId}
          crmId={crmId}
        />
      ),
    },
  ];

  return (
    <div>
      <MuiVirtualizedTable
        actions={<></>}
        columns={connectWiseColumns}
        defaultFilters={defaultFilters}
        resource={`TicketUpdate/connectWise/${defaultId}/tickets`}
      >
        <ConnectWiseTicketTableFilters
          defaultId={defaultId}
          handleDefaultFiltersChange={handleDefaultFiltersChange}
        />
      </MuiVirtualizedTable>
    </div>
  );
};

ConnectWiseTicketTable.propTypes = {
  defaultId: PropTypes.number.isRequired,
};

export default ConnectWiseTicketTable;
