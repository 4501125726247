import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  wizardModalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '45vw',
    transform: 'translate(-50%, -50%)',
  },
  stepperBody: {
    borderRadius: '5px',
    backgroundColor: 'white',
    height: '50vh',
    padding: '1rem',
  },
  stepperRoot: {
    backgroundColor: 'transparent',
    flexWrap: 'wrap',
  },
  selectIntegrationContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  font: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    overflowY: 'auto',
    height: 'calc(70vh - 140px)',
  },
  shortHeight: {
    height: 'calc(70vh - 170px)',
  },
  networkColumnItem: {
    cursor: 'pointer',
    '&:hover': {
      color: '#b1b0ad',
    },
  },
  buttonBlock: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2px',
  },
  buttonWrapper: {
    margin: '0 0 5px 25px',
  },
  dataViewSelector: {
    height: 'calc(50vh - 70px);',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formControl: {
    width: '65%',
  },
  fieldTile: {
    border: '1px solid gray',
    padding: '3px',
    borderRadius: '9px',
    margin: '0 5px',
    minWidth: '5.8rem',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '2px 2px 5px -1px rgba(0,0,0,0.5)',
    },
    height: '20px',
    marginTop: '5px',
  },
  fieldTilesRegion: {
    display: 'flex',
    justifyContent: 'Center',

    fontStyle: 'normal',
    minHeight: '30px',
  },
  selectorLayout: {
    display: 'grid',
    height: 'calc(100% - 63px)',
    gridGap: '2px',
  },
  fourRowsDivide: {
    gridTemplateRows: '40px 40px 1fr 40px',
  },
  threeRowsDivide: {
    gridTemplateRows: '40px 1fr 40px',
  },
  checkboxesArea: {
    columns: '2',
  },
  overflow: {
    overflowY: 'auto',
  },
  flex: {
    display: 'flex',
  },
  marginLeft: {
    marginLeft: '2rem',
  },
}));

export default useStyles;
