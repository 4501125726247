import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';

import { currentUserDataSelector } from '@store/selectors';
import { pusherConfigSelector } from '@store/selectors/pusher';
import { getPusherConfig } from '@ui/components/Chats/helpers';
import { actions } from '@store/actions';

import { ThunkGetPusherConfig } from '@store/slices/liveChats/thunks';
import LiveChatNotifications from '@components/Layout/AppBar/UserMenu/LiveChatNotifications/LiveChatNotifications';
import { SelectClientPortalPusherSettings } from '@store/slices/clientPortalAdmin/selectors';
import ClientPortalNotifications from '@components/Layout/AppBar/UserMenu/ClientPortalNotifications/ClientPortalNotifications';
import { ThunkGetClientPortalPusherSettings } from '@store/slices/clientPortalAdmin/thunks';
import Avatar from './Avatar';
import UserName from './UserName/UserName';
import Logout from './Logout';
import Notifications from './Notifications/Notifications';
import Messages from './Messages/Messages';
import Support from './Support/Support';

import useStyles from './styles';

const UserMenu = () => {
  const [liveChatPusher, setLiveChatPusher] = useState(undefined);
  const classes = useStyles();

  const currentUser = useSelector(currentUserDataSelector);
  const pusherConfig = useSelector(pusherConfigSelector);
  const clientPortalPusherConfig = useSelector(
    SelectClientPortalPusherSettings,
  );
  const dispatch = useDispatch();
  const notify = useNotify();

  const onMount = async () => {
    await getPusherConfig()
      .then(res => {
        dispatch(actions.setPusherConfig(res));
      })
      .catch(err => {
        notify(err.messages, 'error');
      });
    await dispatch(ThunkGetPusherConfig())
      .unwrap()
      .then(res => {
        setLiveChatPusher(res);
      });
    await dispatch(ThunkGetClientPortalPusherSettings());
  };

  useEffect(() => {
    onMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Notifications />
      {currentUser && pusherConfig && <Messages />}

      {currentUser && liveChatPusher && (
        <LiveChatNotifications liveChatPusher={liveChatPusher} />
      )}

      {clientPortalPusherConfig && (
        <ClientPortalNotifications pusherConfig={clientPortalPusherConfig} />
      )}
      <Support />
      <div className={classes.userButton}>
        <Avatar />
        <UserName />
        <Logout />
      </div>
    </div>
  );
};

export default UserMenu;
