import { makeRequest } from '../makeRequest';
import {
  CRM_DEFAULTS_TICKETS,
  MESSAGING_BOARD,
  PSA_CONTACT_CLIENT_PORTAL,
  TRIAGE,
} from '../requestResources';

export const getCrmDefaultsAutocomplete = () =>
  makeRequest('GET', `${CRM_DEFAULTS_TICKETS}/list`);

export const getCrmUsersByDefaultsId = (crmDefaultId, companyId) =>
  makeRequest(
    'GET',
    `${TRIAGE}/${crmDefaultId}/crmUsers${
      companyId ? `?companyId=${companyId}` : ''
    }`,
  );

export const getCrmCompaniesByDefaultId = crmDefaultId =>
  makeRequest('GET', `${TRIAGE}/${crmDefaultId}/companies`);

export const updateCrmUserPhoneNumber = (crmDefaultId, payload) =>
  makeRequest('PUT', `${TRIAGE}/${crmDefaultId}/updateUserPhone`, payload);

export const addCrmUser = (crmDefaultId, payload) =>
  makeRequest('POST', `${TRIAGE}/${crmDefaultId}/crmUsers`, payload);

export const deleteTechnicianNumber = channelId =>
  makeRequest(
    'DELETE',
    `${MESSAGING_BOARD}/channels/${channelId}/removeTechNumber`,
  );

export const addTechnicianNumber = (channelId, payload) =>
  makeRequest(
    'POST',
    `${MESSAGING_BOARD}/channels/${channelId}/addTechNumber`,
    payload,
  );

export const linkCrmContactToClientPortal = payload =>
  makeRequest('POST', `${PSA_CONTACT_CLIENT_PORTAL}/assignContact`, payload);

export const getClientPortals = psaId =>
  makeRequest(
    'GET',
    `${PSA_CONTACT_CLIENT_PORTAL}/clientPortalAutocomplete?crmId=${psaId}`,
  );

export const getCrmDefaultExtended = () =>
  makeRequest('GET', '/Integration/getPsaInfo');
export default {};
