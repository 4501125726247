import React from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';

import copyText from '@utils/copy';
import CopyIcon from '@assets/icons/copy_blue.svg';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import nationalFormatPhoneNumber from '@utils/nationalFormatPhoneNumber';
import useStyles from '../../styles';

const ExistingPhone = ({ phone }) => {
  const classes = useStyles();
  const notify = useNotify();

  const onCopy = key => {
    copyText(key);
    notify('resources.apiKeys.dialogs.copied');
  };

  return (
    <div className={classes.phoneNumberExists}>
      <div className="">Your verification phone:</div>
      {nationalFormatPhoneNumber(phone)}
      <ActionFieldItem handler={() => onCopy(phone)} toolTip="copy">
        <img src={CopyIcon} alt="clone" />
      </ActionFieldItem>
    </div>
  );
};

ExistingPhone.propTypes = {
  phone: PropTypes.string.isRequired,
};

export default ExistingPhone;
