import { makeRequest } from '../makeRequest';
import { INTEGRATION } from '../requestResources';

export const getIntegrationResources = (id, type) =>
  makeRequest('GET', `${INTEGRATION}/${type}/${id}/resources`);

export const getIntegrationSources = id =>
  makeRequest('GET', `${INTEGRATION}/${id}/sources`);

export const getIntegrationServiceBoards = id =>
  makeRequest('GET', `${INTEGRATION}/${id}/service-boards`);

export const getIntegrationPriorities = id =>
  makeRequest('GET', `${INTEGRATION}/${id}/priorities`);

export const getIntegrationCompanies = id =>
  makeRequest('GET', `${INTEGRATION}/companies/${id}`);

export const getIntegrationSyncSettings = id =>
  makeRequest('GET', `${INTEGRATION}/sync-settings/${id}`);

export const getIntegrationTicketCategories = id =>
  makeRequest('GET', `${INTEGRATION}/${id}/ticket-categories`);

export const getIntegrationBoardTypes = (id, boardId) =>
  makeRequest('GET', `${INTEGRATION}/${id}/board-types/${boardId}`);

export const getIntegrationBoardStatuses = (id, boardId) =>
  makeRequest('GET', `${INTEGRATION}/${id}/board-statuses/${boardId}`);

export const clearAutotaskCache = crmId =>
  makeRequest('POST', `${INTEGRATION}/autotask/cache/reset/${crmId}`);

export const getAutotaskIntegrationCompanies = crmId =>
  makeRequest('GET', `${INTEGRATION}/autotask/${crmId}/companies`);

export const getSyncroIntegrationCompanies = crmId =>
  makeRequest('GET', `${INTEGRATION}/syncro/${crmId}/companies`);

export const getConnectWiseIntegrationCompanies = crmId =>
  makeRequest('GET', `${INTEGRATION}/connectwise/${crmId}/companies`);

export const getKaseyaIntegrationCompanies = crmId =>
  makeRequest('GET', `${INTEGRATION}/kaseya/${crmId}/companies`);

export const getZendeskIntegrationStatuses = crmId =>
  makeRequest('GET', `${INTEGRATION}/zendesk/${crmId}/statuses`);

export const getZendeskIntegrationTypes = crmId =>
  makeRequest('GET', `${INTEGRATION}/zendesk/${crmId}/types`);

export const getSuperOpsIntegrationTypes = crmId =>
  makeRequest('GET', `${INTEGRATION}/superOps/${crmId}/types`);

export const getSuperOpsIntegrationStatuses = crmId =>
  makeRequest('GET', `${INTEGRATION}/superOps/${crmId}/statuses`);
export const hetHaloClients = crmId =>
  makeRequest('GET', `${INTEGRATION}/halo/${crmId}/clients`);

export const hetHaloTeams = crmId =>
  makeRequest('GET', `${INTEGRATION}/halo/${crmId}/teams`);

export const hetHaloStatuses = crmId =>
  makeRequest('GET', `${INTEGRATION}/halo/${crmId}/statuses`);

export const hetHaloTicketTypes = crmId =>
  makeRequest('GET', `${INTEGRATION}/halo/${crmId}/ticketTypes`);

export const hetHaloSites = (crmId, clientId) =>
  makeRequest('GET', `${INTEGRATION}/halo/${crmId}/sites?clientId=${clientId}`);

export const hetHaloUsers = (crmId, clientId, siteId) =>
  makeRequest(
    'GET',
    `${INTEGRATION}/halo/${crmId}/users?clientId=${clientId}&siteId=${siteId}`,
  );

export default {};
