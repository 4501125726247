import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import 'material-ui-rc-color-picker/assets/index.css';

import { defaultBgColor, defaultFontColor } from '@constants/defaultBageColors';
import ReusableButton from '@common/Button/Button';
import Input from '@common/Input/Input';
import useStyles from '../styles';
import { createTypeValue } from '../../helpers';
import ColorSelector from '../subComponents/ColorSelector';
import DataSelector from '../subComponents/DataSelector';
import CircleSelector from '../subComponents/CircleSelector';
import useValueTypeDialog from '../hook/useValueTypeDialog';

const CreateTypeValueDialog = ({ onSubmit, onClose, open, typeId }) => {
  const notify = useNotify();
  const classes = useStyles();

  const {
    circleBorderColor,
    setCircleBorderColor,
    circleBgColor,
    setCircleBgColor,
    icon,
    setIcon,
    bgColor,
    setBgColor,
    textColor,
    setTextColor,
    loading,
    setLoading,
    name,
    setName,
    chipData,
    setChipData,
    inputTextRef,
    getText,
    handleArrowClick,
    handleChipsChange,
  } = useValueTypeDialog();

  const clearAfterCreating = () => {
    setChipData([]);
    setTextColor(defaultFontColor);
    setBgColor(defaultBgColor);
    setIcon('');
  };

  const submit = async () => {
    if (!name) {
      notify('Name can not be empty!', 'error');
      return;
    }

    const chipTextFieldValue = inputTextRef.current.value
      ? `,${inputTextRef.current.value}`
      : ' ';

    const data = {
      name,
      values:
        chipData.length > 0
          ? chipData
              .join(',')
              .concat(chipTextFieldValue)
              .trimEnd()
          : '',
      fontColor: textColor,
      backgroundColor: bgColor,
      circleBgColor,
      circleBorderColor,
      icon,
    };
    setLoading(true);

    await createTypeValue(typeId, data)
      .then(res => {
        notify(getText('recordCreated'));
        setName('');
        onSubmit(res);
        clearAfterCreating();
      })
      .catch(e => {
        notify(e.message, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDelete = chipToDelete =>
    setChipData(chips => chips.filter(chip => chip !== chipToDelete));

  const handleNameChange = event => setName(event.target.value);

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{getText('title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{getText('content')}</DialogContentText>
        <Form onSubmit={() => {}}>
          {() => (
            <>
              <Field
                name="name"
                placeholder="Value name"
                inputView="text"
                styleType="main"
                fullWidth
                helperText={false}
                input={{
                  value: name,
                  onChange: handleNameChange,
                }}
                component={Input}
              />

              <DataSelector
                chipData={chipData}
                handleArrowClick={handleArrowClick}
                handleChipsChange={handleChipsChange}
                handleDelete={handleDelete}
                inputRef={inputTextRef}
              />
              <ColorSelector
                textColor={textColor}
                setTextColor={setTextColor}
                bgColor={bgColor}
                setBgColor={setBgColor}
              />
              <CircleSelector
                bgColor={circleBgColor}
                setBgColor={setCircleBgColor}
                borderColor={circleBorderColor}
                setBorderColor={setCircleBorderColor}
              />
              <div className={classes.modalButtonContainer}>
                <ReusableButton
                  label="resources.buttons.cancel"
                  classNameWrapper={cx(
                    classes.button,
                    classes.modalCancelButtonContainer,
                  )}
                  disabled={loading}
                  onClick={onClose}
                />

                <ReusableButton
                  viewType="black"
                  disabled={loading}
                  classNameWrapper={classes.button}
                  onClick={submit}
                  loading={loading}
                  label="resources.buttons.ok"
                />
              </div>
            </>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};

CreateTypeValueDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  typeId: PropTypes.number.isRequired,
};

export default CreateTypeValueDialog;
