import React, { useCallback, useState } from 'react';
import { useListContext } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';

import { ThunkDeleteSmsCampaignGroupItems } from '@store/slices/smsCampaigns/thunks';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';

import ReusableButton from '@common/Button/Button';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';

const BulkActions = () => {
  const [
    openDeleteConfirmationDialog,
    setOpenDeleteConfirmationDialog,
  ] = useState(false);
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const list = useSelector(listSelector);
  const { selectedIds, onUnselectItems } = useListContext();

  const handleBulkDelete = useCallback(() => {
    setProcessing(true);
    dispatch(ThunkDeleteSmsCampaignGroupItems(selectedIds))
      .unwrap()
      .then(() => {
        dispatch(
          actions.saveList(list.filter(i => !selectedIds.includes(i.id))),
        );
        setProcessing(false);
        setOpenDeleteConfirmationDialog(false);
        onUnselectItems();
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [dispatch, list, selectedIds]);

  return (
    <div>
      <ReusableButton
        label="Delete"
        onClick={() => {
          setOpenDeleteConfirmationDialog(true);
        }}
      />

      {openDeleteConfirmationDialog && (
        <ConfirmDialog
          onAccept={handleBulkDelete}
          onCancel={() => {
            setProcessing(false);
            setOpenDeleteConfirmationDialog(false);
          }}
          title="Confirm Action"
          content="Are you sure you want to delete this items?"
          open={openDeleteConfirmationDialog}
          loading={processing}
        />
      )}
    </div>
  );
};

export default BulkActions;
