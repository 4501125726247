import React from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from '@common/form/FieldWrapper';
import {
  accordionStyle,
  contentSize,
  labelSize,
} from '@components/Settings/VerificationSettings/components/constants';
import { Field } from 'react-final-form';
import Checkbox from '@common/Checkbox/Checkbox';
import MinutesPeriodPicker from '@common/form/DateTime/MinutesPeriodPicker';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import InfoBox from '@common/InfoBox/InfoBox';

const ValidationProcessSettings = ({ processing, values }) => {
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Verification settings
          <InfoBox text="Allows to configure verification lifetime" />
        </AccordionSummary>
        <AccordionDetails style={accordionStyle}>
          <FieldWrapper
            label="Reset validation result"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <Field
                name="resetVerification"
                id="resetVerification"
                render={Checkbox}
                type="checkbox"
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Verification expires in"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            infoText="Verification of the contact will ends in defined period"
            content={
              <Field
                name="resetVerificationInMin"
                id="resetVerificationInMin"
                render={MinutesPeriodPicker}
                disabled={processing || !values.resetVerification}
              />
            }
          />

          <FieldWrapper
            label="Require Note if Phone Number changed"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <Field
                name="requireNoteIfPhoneChanged"
                id="requireNoteIfPhoneChanged"
                render={Checkbox}
                type="checkbox"
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Send Note via SMS when Phone Number changed"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <Field
                name="sendNoteAsSmsOnContactNumberChange"
                id="sendNoteAsSmsOnContactNumberChange"
                render={Checkbox}
                type="checkbox"
                disabled={processing}
              />
            }
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

ValidationProcessSettings.propTypes = {
  processing: PropTypes.bool,
  values: PropTypes.objectOf(PropTypes.any),
};

export default ValidationProcessSettings;
