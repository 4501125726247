import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { CLIENT_PORTAL_CONFIGS } from '@constants/routes';
import { ThunkGetClientPortalConfigurationOptions } from '@store/slices/clientPortalAdmin/thunks';

import {
  SelectClientPortalConfigurationOptions,
  SelectClientPortalConfigurationOptionsLoading,
} from '@store/slices/clientPortalAdmin/selectors';
import DropDown from '@components/Auth/Common/DropDown';
import Loading from '@common/Loading/Loading';
import TableDropDown from '@common/TableDropDown/TableDropDown';
import BooleanAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanAccessor';
import TableLabel from '@common/TableLabel/TableLabel';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import ReusableButton from '@common/Button/Button';
import ClientPortalChatActions from '@components/ClientPortal/components/ClientPortalChatActions';
import ClientPortalChatTableActions from '@components/ClientPortal/components/ClientPortalChatTableActions';

import getFormattedDate from '@utils/getFormattedDate';
import useStyles from './styles';

const isActiveOptions = {
  true: { name: 'true', value: 'true' },
  false: { name: 'false', value: 'false' },
};

const valueHandler = key => isActiveOptions[key]?.value;

const defaultSort = { field: 'lastMessageDate', order: 'DESC' };

const ClientPortal = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { push } = useHistory();

  const configurationOptions = useSelector(
    SelectClientPortalConfigurationOptions,
  );

  const configurationOptionsLoading = useSelector(
    SelectClientPortalConfigurationOptionsLoading,
  );

  const [selectedConfig, setSelectedConfig] = useState(undefined);

  const columns = useMemo(
    () => [
      {
        name: (
          <TableDropDown
            item={{ name: 'Is Answered', field: 'isAnswered' }}
            options={isActiveOptions}
            valueHandler={valueHandler}
            hideSorting
          />
        ),
        key: 'isAnswered',
        accessor: rowData => (
          <BooleanAccessor value={rowData.isAnswered} useMultiColor />
        ),
      },
      {
        name: (
          <TableDropDown
            item={{ name: 'Is Closed', field: 'isClosed' }}
            options={isActiveOptions}
            valueHandler={valueHandler}
            hideSorting
          />
        ),
        key: 'isClosed',
        accessor: rowData => (
          <BooleanAccessor value={rowData.isClosed} useMultiColor />
        ),
      },
      {
        name: <TableLabel item={{ name: 'Name', field: 'name' }} />,
        key: 'name',
      },
      {
        name: 'Last Message Date',
        key: 'lastMessageDate',
        accessor: i => <>{getFormattedDate(i.lastMessageDate)}</>,
        sort: 'lastMessageDate',
      },
      {
        name: (
          <TableLabel
            item={{ name: 'Last Message', field: 'lastMessage' }}
            showSortButton={false}
          />
        ),
        key: 'lastMessage',
      },
      {
        name: (
          <TableLabel
            item={{ name: 'Ticket ID', field: 'ticketId' }}
            showSortButton={false}
          />
        ),
        key: 'ticketId',
        accessor: item => (
          <div
            style={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            role="presentation"
            onClick={() => {
              window.open(item.ticketLink, '_blank');
            }}
          >
            {item?.ticketId}
          </div>
        ),
      },
      {
        name: (
          <TableLabel item={{ name: 'Contact Name', field: 'contactName' }} />
        ),
        key: 'contactName',
      },
      {
        name: (
          <TableLabel
            item={{ name: 'Assignee Name', field: 'assigneeName' }}
            showSortButton={false}
          />
        ),
        key: 'assigneeName',
      },
      {
        name: 'Actions',
        key: 'actions',
        accessor: item => (
          <ClientPortalChatTableActions
            {...item}
            selectedResource={selectedConfig}
          />
        ),
      },
    ],
    [selectedConfig],
  );

  useEffect(() => {
    if (!!configurationOptions && configurationOptions.length === 1) {
      setSelectedConfig(configurationOptions[0].value);
    }
  }, [configurationOptions]);

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetClientPortalConfigurationOptions());
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (configurationOptionsLoading) return <Loading />;

  return (
    <div className={classes.container}>
      <div className={classes.tableActions}>
        <DropDown
          options={configurationOptions}
          input={{
            onChange: e => setSelectedConfig(e.target.value),
            value: +selectedConfig,
          }}
          classNameWrapper={classes.configSelector}
        />
        <ReusableButton
          label="Configurations"
          onClick={() => push(CLIENT_PORTAL_CONFIGS)}
        />
      </div>
      {!selectedConfig && (
        <div className={classes.selectLanguage}>
          Select Client Portal configuration
        </div>
      )}
      {selectedConfig && (
        <div className={classes.tableContainer}>
          <MuiVirtualizedTable
            actions={<ClientPortalChatActions />}
            columns={columns}
            resource={`ClientPortalChats/configurations/${selectedConfig}/chats`}
            defaultFilters={{ isClosed: false }}
            defaultSort={defaultSort}
          />
        </div>
      )}
    </div>
  );
};

export default ClientPortal;
