import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import crmSources from '@constants/crmSources';
import SMSIcon from '@assets/icons/sms.svg';
import ShieldPersonIcon from '@assets/icons/shield_person.svg';
import SyncIcon from '@assets/icons/secureDataSend.svg';

import ItemMenu from '@common/ItemMenu/ItemMenu';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import CreateMessagingChannelDialog from '@ui/components/Messaging/dialogs/CreateMessagingChannelDialog';
import PodDialog from '@components/PodDialog';

import useStyles from '../../styles';

const ZendeskTicketTableActions = ({ record, crmId, crmDefaultId }) => {
  const [openCreateChannelDialog, setOpenCreateChannelDialog] = useState(false);
  const [openSecureDialog, setOpenSecureDialog] = useState(false);
  const [openPodDialog, setOpenPodDialog] = useState(false);

  const classes = useStyles();

  const menuOptions = useMemo(
    () => [
      {
        icon: <img src={SMSIcon} alt="createChannel" />,
        caption: 'Add SMS Channel',
        handler: () => {
          setOpenCreateChannelDialog(true);
        },
      },
    ],
    [],
  );

  return (
    <div className={classes.tableActions}>
      {record?.requesterId && (
        <>
          <ActionFieldItem
            alt="Open POD"
            handler={() => {
              setOpenPodDialog(true);
            }}
            toolTip="Open POD"
            icon={ShieldPersonIcon}
            ignoreDefaultIconSize
          />
          <ActionFieldItem
            alt="Secure Data"
            handler={() => setOpenSecureDialog(true)}
            toolTip="Secure data"
            icon={SyncIcon}
            ignoreDefaultIconSize
          />
        </>
      )}

      <ItemMenu options={menuOptions} itemsHeightMultiplier={10} openOnHover />

      {openPodDialog && record.requesterId && (
        <PodDialog
          itemId={record.requesterId}
          onClose={() => {
            setOpenPodDialog(false);
          }}
          itemType="contact"
          psaId={crmId}
          psa={crmSources.Zendesk.label}
        />
      )}

      {openCreateChannelDialog && (
        <CreateMessagingChannelDialog
          open={openCreateChannelDialog}
          setOpen={setOpenCreateChannelDialog}
          record={record}
          crmDefaultId={crmDefaultId}
          crmType={crmSources.Zendesk.name}
        />
      )}

      {openSecureDialog && record.requesterId && (
        <PodDialog
          itemId={record.requesterId}
          onClose={() => {
            setOpenSecureDialog(false);
          }}
          itemType="contact"
          psaId={crmId}
          psa={crmSources.Zendesk.label}
          page="secureData"
        />
      )}
    </div>
  );
};

ZendeskTicketTableActions.propTypes = {
  record: PropTypes.shape({
    ticketNumber: PropTypes.string.isRequired,
    crmDefaultId: PropTypes.number.isRequired,
    requesterId: PropTypes.number,
  }),
  crmDefaultId: PropTypes.number,
  crmId: PropTypes.number,
};
export default ZendeskTicketTableActions;
