import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable';
import TableDateLabel from '@common/TableLabel/TableDateLabel';
import DateTimeField from '@common/TableComponents/DateTimeField';
import { sortOrders } from '@constants/filters';
import TableLabel from '@common/TableLabel/TableLabel';
import TableDropDown from '@common/TableDropDown/TableDropDown';
import TableDatePicker from '@common/TableDatePicker/TableDatePicker';
import useTranslatedText from '@common/hooks/useTranslatedText';
import useTableDateLabel from '@common/hooks/useTableDateLabel';
import TenantPanel from '@components/Billing/SmsLogs/TenantPanel';
import StatusesAccessor from '@common/MuiVirtualizedTable/components/accessors/StatusesAccessor';
import { getRoleIdFromStorage } from '@services/api';
import roles from '@constants/roles';
import events from './events';
import useStyles from '../../Admin/styles';

const valueHandlerStatus = key => events[key].name;

const List = ({ resource }) => {
  const classes = useStyles();

  const { date, isOpen, setIsOpen, dateDispatcher } = useTableDateLabel();
  const { getFieldText } = useTranslatedText(resource);

  const columns = useMemo(
    () => [
      {
        name: (
          <TableDateLabel
            item={{ field: 'dateTime', name: getFieldText('dateTime') }}
            date={date}
            setIsOpen={setIsOpen}
          />
        ),
        key: 'dateTime',
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="dateTime"
            source="dateTime"
            label={getFieldText('dateTime')}
            sortable
          />
        ),
        width: 150,
      },
      {
        name: <TableLabel item={{ name: getFieldText('to'), field: 'to' }} />,
        key: 'to',
        width: 200,
        isPhoneNumber: true,
      },
      {
        name: (
          <TableDropDown
            item={{ field: 'status', name: 'Status' }}
            options={events}
            labelHandler={valueHandlerStatus}
          />
        ),
        key: 'status',
        accessor: row => <StatusesAccessor item={row.status} array={events} />,
        width: 150,
      },
      {
        name: (
          <TableLabel
            item={{ name: getFieldText('message'), field: 'message' }}
          />
        ),
        key: 'message',
        align: 'left',
      },
      {
        name: (
          <TableLabel
            item={{ name: getFieldText('errorMessage'), field: 'errorMessage' }}
          />
        ),
        key: 'errorMessage',
        align: 'left',
      },
    ],
    [date, getFieldText, setIsOpen],
  );

  return (
    <>
      <MuiVirtualizedTable
        classNameWrapper={classes.tableWrapper}
        actions={null}
        defaultSort={{
          field: 'dateTime',
          order: sortOrders.desc,
        }}
        columns={columns}
        resource={resource}
      >
        <TableDatePicker
          date={date}
          dateDispatcher={dateDispatcher}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          field="dateTime"
        />
        {getRoleIdFromStorage() === roles.SUPER_ADMIN && <TenantPanel />}
      </MuiVirtualizedTable>
    </>
  );
};

List.propTypes = {
  resource: PropTypes.string,
};

export default List;
