const crmDefaultFields = {
  name: 'name',
  defaultCompany: 'defaultCompany',
  defaultBoard: 'defaultBoard',
  ticketPriority: 'ticketPriority',
  defaultBoardId: 'defaultBoardId',
  sourceId: 'sourceId',
  typeId: 'typeId',
  assignedResourceId: 'assignedResourceId',
  subTypeId: 'subTypeId',
  itemId: 'itemId',
  openBoardStatusId: 'openBoardStatusId',
  closedBoardStatusId: 'closedBoardStatusId',
  acknowledgedBoardStatusId: 'acknowledgedBoardStatusId',
  smsReceivedBoardStatusId: 'smsReceivedBoardStatusId',
  ignoredStatuses: 'ignoredStatuses',
  ticketCategoryId: 'ticketCategoryId',
  category: 'category',
  subcategory: 'subcategory',
  urgency: 'urgency',
  impact: 'impact',
  group: 'group',
  caller: 'caller',
  ticketTimeZone: 'ticketTimeZone',
  agreementId: 'agreementId',
  preferredContactMethodFieldName: 'preferredContactMethodFieldName',
};

export default crmDefaultFields;
