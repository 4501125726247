import { validateRequired } from '@ui/components/common/functions/validators';
import crmDefaultFields from '../updateCrm/CrmDefaults/constants';

export const initialValues = {
  name: '',
  [crmDefaultFields.defaultCompany]: '',
  [crmDefaultFields.defaultBoard]: '',
  [crmDefaultFields.openBoardStatusId]: '',
  [crmDefaultFields.acknowledgedBoardStatusId]: '',
  [crmDefaultFields.closedBoardStatusId]: '',
  [crmDefaultFields.typeId]: '',
  [crmDefaultFields.ticketCategoryId]: '',
  [crmDefaultFields.sourceId]: '',
};

export const validation = values => ({
  name: validateRequired(values.name),
  [crmDefaultFields.defaultCompany]: validateRequired(
    values[crmDefaultFields.defaultCompany],
  ),
  [crmDefaultFields.defaultBoard]: validateRequired(
    values[crmDefaultFields.defaultBoard],
  ),
  [crmDefaultFields.openBoardStatusId]: validateRequired(
    values[crmDefaultFields.openBoardStatusId],
  ),
  [crmDefaultFields.closedBoardStatusId]: validateRequired(
    values[crmDefaultFields.closedBoardStatusId],
  ),
  [crmDefaultFields.typeId]: validateRequired(values[crmDefaultFields.typeId]),
  [crmDefaultFields.ticketCategoryId]: validateRequired(
    values[crmDefaultFields.ticketCategoryId],
  ),
  [crmDefaultFields.sourceId]: validateRequired(
    values[crmDefaultFields.sourceId],
  ),
});

export default {};
