import React, { useCallback, useMemo, useState } from 'react';
import { useTranslate } from 'react-admin';

import MuiVirtualizedTable from '@common/MuiVirtualizedTable';
import TableLabel from '../common/TableLabel/TableLabel';
import TableActions from './components/TableActions';
import TableItemActions from './components/TableItemActions';
import CreateAutotaskWebhookDialog from './components/CreateAutotaskWebhookDialog';

import useStyles from './styles';

const resource = 'autotaskWebHooks';

const AutotaskNoteToSMS = () => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const classes = useStyles();
  const translate = useTranslate();

  const getLabel = useCallback(
    src =>
      translate(
        'resources.'
          .concat(resource)
          .concat('.fields.')
          .concat(src),
      ),
    [translate],
  );

  const columns = useMemo(
    () => [
      {
        name: <TableLabel item={{ field: 'id', name: getLabel('id') }} />,
        key: 'id',
      },
      {
        name: (
          <TableLabel
            item={{ field: 'integrationId', name: getLabel('integrationId') }}
          />
        ),
        key: 'integrationId',
      },
      {
        name: (
          <TableLabel
            item={{
              field: 'integrationName',
              name: getLabel('integrationName'),
            }}
          />
        ),
        key: 'integrationName',
      },
      {
        name: (
          <TableLabel
            item={{ field: 'autotaskHookId', name: getLabel('autotaskHookId') }}
          />
        ),
        key: 'autotaskHookId',
      },
      {
        name: 'Actions',
        key: 'actions',
        accessor: rowData => <TableItemActions record={rowData} />,
      },
    ],
    [getLabel],
  );

  return (
    <div className={classes.container}>
      <MuiVirtualizedTable
        resource={resource}
        columns={columns}
        actions={
          <TableActions
            openDialog={() => {
              setOpenCreateDialog(true);
            }}
          />
        }
      />
      {openCreateDialog && (
        <CreateAutotaskWebhookDialog
          open={openCreateDialog}
          setOpen={setOpenCreateDialog}
        />
      )}
    </div>
  );
};

export default AutotaskNoteToSMS;
