import React from 'react';
import PropTypes from 'prop-types';
import { useRedirect, useTranslate } from 'react-admin';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import TableLabel from '@common/TableLabel/TableLabel';
import { WEB_HOOKS, WEB_HOOKS_CREATE } from '@constants/routes';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import useDeleteRecord from '@components/DeviceMonitors/hook/useDeleteRecord';
import { deleteRecord } from '@components/WebHooks/helpers';
import ActionField from '../components/ActionField';
import ListActions from '../components/ListActions';
import useStyles from '../styles';

const List = ({ resource }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const redirect = useRedirect();
  const getLabel = src =>
    translate(
      'resources.'
        .concat(resource)
        .concat('.fields.')
        .concat(src),
    );

  const {
    onCancel,
    open,
    loading,
    handleDeleteIcon,
    onDelete,
  } = useDeleteRecord(deleteRecord);

  const createPage = () => redirect(WEB_HOOKS_CREATE);
  const editPage = id => redirect(`${WEB_HOOKS}/${id}`);

  return (
    <>
      <MuiVirtualizedTable
        classNameWrapper={classes.tableWrapper}
        actions={<ListActions createPage={createPage} />}
        columns={[
          {
            name: (
              <TableLabel
                item={{
                  name: getLabel('integrationName'),
                  field: 'integrationName',
                }}
              />
            ),
            key: 'integrationName',
          },
          {
            name: getLabel('integrationGuid'),
            key: 'integrationGuid',
            sort: 'integrationGuid',
          },
          {
            name: 'Actions',
            key: 'actions',
            accessor: rowData => (
              <ActionField
                label="Actions"
                record={rowData}
                onEdit={row => editPage(row.id)}
                onDelete={row => handleDeleteIcon(row.id)}
              />
            ),
            width: 200,
          },
        ]}
        resource={resource}
      />
      <ConfirmDialog
        open={open}
        onCancel={onCancel}
        onAccept={onDelete}
        loading={loading}
        title={translate('resources.webHooks.modal.deleteTitle')}
        content={translate('resources.webHooks.modal.deleteContent')}
      />
    </>
  );
};

List.propTypes = {
  resource: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default List;
