import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '15px',
    '& > div > div > div > div': {
      marginTop: '0px !important',
    },
  },
  statusField: {
    color: 'white',
    margin: '0px',
    padding: '5px 15px',
    minWidth: '70%',
    borderRadius: '5px',
  },
  bulkActions: {
    '& > *': {
      marginRight: '10px',
    },
  },
  fixButton: {
    '& > * > *': {
      marginRight: '5px',
    },
  },
}));

export default useStyles;
