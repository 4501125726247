import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { EditButton, useTranslate } from 'react-admin';
import Box from '@material-ui/core/Box';

import iconEdit from '@assets/icons/edit.svg';

import MuiVirtualizedTable from '@common/MuiVirtualizedTable';
import TableLabel from '@common/TableLabel/TableLabel';
import BulkActionButtons from '@common/BulkActionButtons';
import DeleteActionField from './components/DeleteActionField';
import ListActions from './components/ListActions';
import FieldNameField from './TableFields/FieldNameField';

import useStyles from './styles';

const List = props => {
  const { history } = props;

  const classes = useStyles();
  const translate = useTranslate();

  const createPage = () => history.push(`${window.location.pathname}/create`);

  const getLabel = useCallback(
    src =>
      translate(
        'resources.'
          .concat('dataViews')
          .concat('.fields.')
          .concat(src),
      ),
    [translate],
  );

  const columns = useMemo(
    () => [
      {
        name: <TableLabel item={{ field: 'name', name: getLabel('name') }} />,
        key: 'name',
      },
      {
        name: <TableLabel item={{ field: 'data', name: 'Displayed Fields' }} />,
        key: 'data',
        accessor: rowData => (
          <FieldNameField
            record={rowData}
            source="data"
            label="Displayed Fields"
          />
        ),
        width: 640,
        cropCell: true,
      },
      {
        name: 'Actions',
        key: 'actions',
        accessor: rowData => (
          <Box>
            <EditButton
              label=""
              icon={<img src={iconEdit} alt="iconEdit" />}
              record={rowData}
              basePath="dataViews"
              className={classes.editButton}
            />

            <DeleteActionField record={rowData} />
          </Box>
        ),
        width: 200,
      },
    ],
    [classes.editButton, getLabel],
  );

  return (
    <MuiVirtualizedTable
      bulkActions={<BulkActionButtons />}
      actions={<ListActions createPage={createPage} />}
      columns={columns}
      resource="dataViews"
    />
  );
};

List.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default List;
