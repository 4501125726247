import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { useNotify } from 'react-admin';

import { ThunkUpdateCardData } from '@store/slices/billing/thunks';

import { requiredValidator } from '@utils/validators';
import useValidate from '@components/Hooks/useValidate';

import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import TextMask from '@components/Auth/Common/creditCardInput';
import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

const ChangeCardDataDialog = ({ open, onClose }) => {
  const [processing, setProcessing] = useState(false);

  const sharedStyles = useSharedStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  const {
    numberInRange,
    validateCreditCardNumber,
    numberLengthRange,
  } = useValidate();

  const submit = useCallback(
    values => {
      setProcessing(true);
      dispatch(ThunkUpdateCardData(values))
        .unwrap()
        .then(() => {
          notify('Card Data Updated', 'success');
          onClose();
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch, notify, onClose],
  );

  const validate = useCallback(
    values => ({
      cardHolder: requiredValidator(values.cardHolder),
      cardNumber: validateCreditCardNumber(values.cardNumber),
      cardExpMonth: numberInRange(values.cardExpMonth, 1, 12),
      cardExpYear: numberInRange(values.cardExpYear, 22, 35),
      cardCvc: numberLengthRange(values.cardCvc, 3, 4),
    }),
    [numberInRange, numberLengthRange, validateCreditCardNumber],
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Change Card Data</DialogTitle>

      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Card Name"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    placeholder="Card Name"
                    name="cardHolder"
                    id="cardHolder"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Card Number"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    id="cardNumber"
                    name="cardNumber"
                    inputView="text"
                    styleType="main"
                    placeholder="**** **** **** ****"
                    fullWidth
                    component={Input}
                    InputProps={{ inputComponent: TextMask }}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Card Details"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr 1fr',
                      gap: '10px',
                    }}
                  >
                    <Field
                      id="cardExpMonth"
                      name="cardExpMonth"
                      inputView="text"
                      styleType="main"
                      component={Input}
                      placeholder="Month"
                      disabled={processing}
                    />
                    <Field
                      id="cardExpYear"
                      name="cardExpYear"
                      inputView="text"
                      styleType="main"
                      component={Input}
                      placeholder="Year"
                      disabled={processing}
                    />
                    <Field
                      id="cardCvc"
                      name="cardCvc"
                      inputView="text"
                      styleType="main"
                      component={Input}
                      placeholder="CVV"
                      disabled={processing}
                    />
                  </div>
                }
              />

              <div className={sharedStyles.dialogActionsContainer}>
                <ReusableButton label="Close" onClick={onClose} />
                <ReusableButton label="Submit" type="submit" viewType="blue" />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

ChangeCardDataDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChangeCardDataDialog;
