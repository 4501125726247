import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useListContext } from 'react-admin';

import {
  ThunkGetKaseyaIssueSubTypes,
  ThunkGetKaseyaIssueTypes,
  ThunkGetKaseyaQueues,
  ThunkGetKaseyaStatuses,
  ThunkGetKaseyaTypes,
} from '@store/slices/kaseyaTicket/thunks';
import { KaseyaTicketOptionsSelector } from '@store/slices/kaseyaTicket';

import ViewAutoComplete from '@common/ViewAutoComplete/ViewAutoComplete';

import useStyles from '../../styles';

const KaseyaTicketTableFilters = ({ crmDefaultId }) => {
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { setFilters, filterValues } = useListContext();
  const { queues, statuses, types, issueTypes, issueSubTypes } = useSelector(
    KaseyaTicketOptionsSelector,
  );

  const [queueId, setQueueId] = useState(0);
  const [statusId, setStatusId] = useState(0);
  const [typeId, setTypeId] = useState(0);
  const [issueTypeId, setIssueTypeId] = useState(0);
  const [issueSubTypeId, setIssueSubTypeId] = useState(0);

  const handleIssueTypeChange = async value => {
    setLoading(true);
    await dispatch(
      ThunkGetKaseyaIssueSubTypes({ crmDefaultId, issueTypeId: value }),
    );
    setLoading(false);
  };

  const handleChange = (item, setter, filterName) => {
    setter(item);
    setFilters(
      {
        [filterName]: item?.label,
      },
      filterValues,
      true,
    );
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    await Promise.all([
      dispatch(ThunkGetKaseyaQueues({ crmDefaultId })).unwrap(),
      dispatch(ThunkGetKaseyaStatuses({ crmDefaultId })).unwrap(),
      dispatch(ThunkGetKaseyaTypes({ crmDefaultId })).unwrap(),
      dispatch(ThunkGetKaseyaIssueTypes({ crmDefaultId })).unwrap(),
    ]);

    setLoading(false);
  }, [dispatch, crmDefaultId]);

  useEffect(() => {
    onMount();
  }, [onMount]);
  return (
    <div className={classes.filtersContainer}>
      <ViewAutoComplete
        options={statuses}
        onChange={item => {
          handleChange(item, setStatusId, 'status');
        }}
        currentView={statusId}
        textFieldLabel="Status"
        disabled={loading}
      />

      <ViewAutoComplete
        options={queues}
        onChange={item => {
          handleChange(item, setQueueId, 'queue');
        }}
        currentView={queueId}
        textFieldLabel="Queue"
        disabled={loading}
      />

      <ViewAutoComplete
        options={types}
        onChange={item => {
          handleChange(item, setTypeId, 'type');
        }}
        currentView={typeId}
        textFieldLabel="Type"
        disabled={loading}
      />

      <ViewAutoComplete
        options={issueTypes}
        onChange={item => {
          handleChange(item, setIssueTypeId, 'issueType');
          if (item?.value) {
            handleIssueTypeChange(item?.value);
          }
        }}
        currentView={issueTypeId}
        textFieldLabel="Issue Type"
        disabled={loading}
      />

      <ViewAutoComplete
        options={issueSubTypes}
        onChange={item => {
          handleChange(item, setIssueSubTypeId, 'issueSubType');
        }}
        currentView={issueSubTypeId}
        textFieldLabel="Issue Sub Type"
        disabled={loading || !issueTypeId}
      />
    </div>
  );
};

KaseyaTicketTableFilters.propTypes = {
  crmDefaultId: PropTypes.number.isRequired,
};
export default KaseyaTicketTableFilters;
