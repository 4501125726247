import React, { useMemo } from 'react';
import { useNotify, useRedirect } from 'react-admin';

import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import resources from '@constants/resources';
import { CREATE_GENERIC_SOURCE, GENERIC_SOURCES } from '@constants/routes';
import ListActions from '@components/common/buttons/ListActionButtons/ListActions';
import ActionField from '@components/common/ActionFields/ActionField';

import StatusCircle from '@common/StatusCircle/StatusCircle';
import { toggleEnabled } from '@components/GenericSources/helpers';
import { actions } from '@store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { listSelector } from '@store/selectors';
import useDeleteRecord from '@components/DeviceMonitors/hook/useDeleteRecord';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import TableLabel from '../common/TableLabel/TableLabel';
import useTranslatedText from '../common/hooks/useTranslatedText';
import { deleteRecord } from './helpers';

const GenericSources = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const dispatch = useDispatch();
  const list = useSelector(listSelector);
  const { getText, getFieldText } = useTranslatedText(resources.genericSources);

  const {
    onCancel,
    open,
    loading,
    handleDeleteIcon,
    onDelete,
  } = useDeleteRecord(deleteRecord);

  const editItem = id => redirect(`${GENERIC_SOURCES}/${id}`);
  const createItem = () => redirect(CREATE_GENERIC_SOURCE);

  const onToggleEnabled = (id, isActive) => {
    toggleEnabled(id, !isActive)
      .then(() => {
        const newArr = list.map(item =>
          item.id !== id ? item : { ...item, isActive: !isActive },
        );
        dispatch(actions.saveList(newArr));
      })
      .catch(e => notify(e.message, 'error'));
  };

  const columns = useMemo(
    () => [
      {
        name: (
          <TableLabel
            item={{
              name: getFieldText('name'),
              field: 'name',
            }}
          />
        ),
        key: 'name',
      },
      {
        name: (
          <TableLabel
            item={{
              name: getFieldText('genericPipelineName'),
              field: 'genericPipelineName',
            }}
          />
        ),
        key: 'genericPipelineName',
      },
      {
        name: 'Enabled',
        key: 'isActive',
        accessor: row => (
          <StatusCircle
            handleClick={onToggleEnabled}
            row={row}
            rowKey="isActive"
          />
        ),
        width: 150,
      },
      {
        name: 'Actions',
        key: 'actions',
        accessor: rowData => (
          <ActionField
            label="Actions"
            record={rowData}
            onEdit={row => editItem(row.id)}
            onDelete={row => handleDeleteIcon(row.id)}
          />
        ),
        width: 200,
      },
    ],
    [editItem, getFieldText],
  );

  return (
    <div>
      <MuiVirtualizedTable
        actions={<ListActions onCreate={createItem} />}
        columns={columns}
        resource={resources.genericSources}
      />
      <ConfirmDialog
        open={open}
        onCancel={onCancel}
        onAccept={onDelete}
        loading={loading}
        title={getText('deleteHeader')}
        content={getText('deleteDetails')}
      />
    </div>
  );
};

export default GenericSources;
