export const billingEmailUrl = '/Billing/billingEmail';
export const billingCustomerUrl = '/Billing/billingCustomer';
export const billingCardUrl = '/Billing/cardInfo';
export const usageUserUsage = '/Usage/userUsage/';
export const usegeStatUrl = '/Usage/userStatistic/';
export const usage = '/Usage/';
export const pricePlanMyPlan = '/PricePlan/myPlan/';
export const pricePlan = '/PricePlan';
export const tenantPalette = '/tenantPalettes';
export const mailBoxes = '/mailBoxes/';
export const mailBoxesExists = '/MailBoxes/isExists';
export const mailBoxStat = '/letters/statistic/';
export const letters = '/letters/';
export const letterDetails = '/Letters/details/';
export const letterDetailsById = id => `/Letters/${id}/getLetterDetails`;
export const parsers = '/parsers/';
export const testLeter = (messageId, mailBoxId) =>
  `/Parsers/testLetter/${messageId}/${mailBoxId}`;
export const addCustomField = value =>
  `/Parsers/addCustomField?customField=${value}`;
export const assignParser = (parserId, letterId) =>
  `/Parsers/${parserId}/assignParser/${letterId}`;
export const types = '/types/';
export const auditViewAutocomplete = '/UserData/GetAuditViewAutocomplete';
export const policy = '/UserData/';
export const dataViews = '/dataViews/';
export const typeValues = '/TypeValues/';
export const user = '/User/';
export const users = '/Users';
export const unrecognizedTypes = '/UnrecognizedTypes/';
export const ticketing = '/CrmSource/';
export const crmAutocomplete = '/CrmSource/getAsAutocomplete';
export const crmTicketDefaults = '/CrmTicketDefaults/';
export const crmTickets = '/CrmTickets/';
export const notificationSettings = '/notificationSettings/';
export const ticketSettings = '/ticketSettings/';
export const emailSettings = '/emailSettings/';
export const auditViewTemplateVariables =
  '/notificationSettings/getTemplateVariables/';
export const smsSettings = '/smsSettings/';
export const webHookSettings = '/webHookSettings/';
export const slackSettings = '/slackSettings/';
export const teamsSettings = '/teamsSettings/';
export const ftpSettings = '/ftpSettings/';
export const voiceSettings = '/voiceSettings/';
export const tenantSettings = '/tenantSettings/';
export const tenantImage = `${tenantSettings}tenant-image`;
export const timeZones = '/common/time-zones';
export const integration = '/Integration/';
export const integrationBoards = crmId =>
  `${integration}${crmId}/service-boards`;
export const integrationPriority = crmId => `${integration}${crmId}/priorities`;
export const integrationSources = crmId => `${integration}${crmId}/sources`;
export const integrationStatusesAndTypes = crmId =>
  `${integration}${crmId}/companies-statuses-types`;
export const integrationCompanies = '/Integration/companies/';
export const integrationSyncCompanies = '/Integration/sync-companies/';
export const integrationSyncUsers = '/Integration/sync-users/';
export const integrationBoardTypes = crmId =>
  `${integration}${crmId}/board-types/`;
export const integrationCallers = crmId => `${integration}${crmId}/callers/`;
export const integrationGroups = crmId => `${integration}${crmId}/groups/`;
export const integrationUrgency = crmId => `${integration}${crmId}/urgencies/`;
export const integrationImpacts = crmId => `${integration}${crmId}/impacts/`;
export const integrationCategories = crmId =>
  `${integration}${crmId}/categories/`;
export const integrationBoardStatuses = crmId =>
  `${integration}${crmId}/board-statuses/`;
export const ticketCategories = crmId =>
  `${integration}${crmId}/ticket-categories/`;
export const toggleAcknowledgeUri = `${policy}toggleAcknowledge/`;
export const getTenantUsersAutocompleteUri = `${user}TenantUsers`;
export const addAssigneeUri = `${policy}AddAssignee/`;
export const deletePolicyUri = `${policy}DeletePolicy/`;
export const authUrl = '/Token';
export const mfaInfo = '/User/MFCode';
export const verifyMfaInfo = '/User/VerifyMFCode2FASetup';
export const toggleMfa = '/User/ToggleTwoFactor';
export const mailBoxSettings = '/MailBoxSettings/';
export const subscriptions = '/Subscriptions/';
export const exportUrl = '/Export/';
export const getServiceNowSourcesUrl = '/CrmSource/getServiceNowTableSources';
export const ftpConnections = '/FtpConnections/';
export const deviceMonitors = '/DeviceMonitors/';
export const deviceThreshold = '/DeviceThresholds/';
export const dmNotificationSettings = '/DmNotificationSettings/';

export const dmTicketSettings = '/dmTicketSettings/';
export const dmEmailSettings = '/dmEmailSettings/';
export const dmSmsSettings = '/dmSmsSettings/';
export const dmWebHookSettings = '/dmWebHookSettings/';
export const dmSlackSettings = '/dmSlackSettings/';
export const dmTeamsSettings = '/dmTeamsSettings/';
export const upload = '/import';
export const webHooks = '/WebHooks/';
export const dataSourcesIntegrations = '/HooksIntegrations/';
export const merakiOrganizations = id =>
  `/Integrations/Meraki/${id}/organizations`;
export const merakiOrganizationNetworks = (id, organizationId) =>
  `/Integrations/Meraki/${id}/networks?organizationId=${organizationId}`;
export const merakiOrganizationDeviceStatuses = (id, organizationId) =>
  `/Integrations/Meraki/${id}/deviceStatuses?orgId=${organizationId}`;
export const merakiOrganizationAlertSettings = (id, organizationId) =>
  `/Integrations/Meraki/${id}/organizationAlertSettings?orgId=${organizationId}`;
export const merakiNetworkDevices = (id, networkId) =>
  `/Integrations/Meraki/${id}/devices?networkId=${networkId}`;

export const sophosTenants = id => `/Integrations/Sophos/${id}/tenants`;
export const sophosEndpoints = (
  id,
  tenantId,
  tenantUrl,
  useTenantName = false,
) =>
  `/Integrations/Sophos/${id}/endpoints?tenantId=${tenantId}&apiHost=${tenantUrl}&useTenantName=${useTenantName}`;

export const sophosAlerts = (id, tenantId, tenantUrl) =>
  `/Integrations/Sophos/${id}/alerts?tenantId=${tenantId}&apiHost=${tenantUrl}`;

export const localCompanies = '/LocalCompanies/';
export const getCompaniesAutocomplete = search =>
  search
    ? `${localCompanies}companies-autocomplete?search=${search}`
    : `${localCompanies}companies-autocomplete`;

export const integrationMeraki = '/Integrations/Meraki/';
export const integrationAutotask = '/Integrations/Autotask/';
export const integrationConnectWise = '/Integrations/ConnectWise/';
export const integrationOrganizations = '/Integrations/organizations/';

export const integrationOrganizationsChecked = checked =>
  `/Integrations/organizations?isCreate=${checked}`;
export const merakiAlertTemplate = '/MerakiAlertTemplates/';

export const customFields = '/customFields/';
export const demoReferralLinks = '/DemoReferralLinks';
export const coupons = '/coupons';
export const dashboard = '/Dashboard/';
export const genericPipelines = '/GenericPipelines/';
export const admin = '/Admin/';
export const tenants = '/Tenants/';
export const billingLinks = '/BillingLinks/';
export const notificationGroups = '/NotificationGroups/';
export const crmUsers = '/CrmUsers/';
export const triggers = '/MailTriggers/';
export const incidents = '/Incidents/';
export const incidentNotes = '/IncidentNotes/';
export const incidentTimeEntries = '/IncidentTimeEntry/';
export const account = '/Account/';
export const documents = '/Documents/';
export const genericSources = '/GenericSources/';
export const escalationRules = '/escalationRules/';
