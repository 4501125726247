import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';

import { BillingEmailSelector } from '@store/slices/billing';
import { ThunkUpdateBillingEmail } from '@store/slices/billing/thunks';

import { emailValidator } from '@utils/validators';

import ReusableButton from '@common/Button/Button';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

const ChangeBillingEmailDialog = ({ open, onClose }) => {
  const [processing, setProcessing] = useState(false);

  const sharedStyles = useSharedStyles();
  const dispatch = useDispatch();
  const billingEmail = useSelector(BillingEmailSelector);
  const notify = useNotify();

  const submit = useCallback(
    values => {
      setProcessing(true);
      dispatch(ThunkUpdateBillingEmail(values))
        .unwrap()
        .then(() => {
          notify('Billing Email Updated', 'success');
          onClose();
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch, notify, onClose],
  );

  const validate = useCallback(
    values => ({
      email: emailValidator(values.email),
    }),
    [],
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Change Billing Email</DialogTitle>

      <DialogContent>
        <Form
          onSubmit={submit}
          initialValues={{
            email: billingEmail,
          }}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Email"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    placeholder="Email"
                    name="email"
                    id="email"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <div className={sharedStyles.dialogActionsContainer}>
                <ReusableButton label="Close" onClick={onClose} />
                <ReusableButton label="Submit" type="submit" viewType="blue" />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

ChangeBillingEmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChangeBillingEmailDialog;
