import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  chatMessages: {
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: '60vh',
    maxHeight: '70vh',
  },
  chatInput: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 30px 30px',
    margin: '5px 0',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    '& > svg': {
      color: '#356d9e',
      cursor: 'pointer',
    },
    '@media (max-width: 767px)': {
      maxWidth: '100vw',
    },
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid lightgrey',
    padding: '10px',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      maxWidth: '100vw',
      gap: '20px',
      alignItems: 'flex-start',
    },
  },
  tableActionsWrapper: {
    width: '40%',
    margin: '20px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: '2',
    '& > button': {
      marginLeft: '35px',
    },
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 767px)': {
      gap: '15px',
    },
  },
}));

export default useStyles;
