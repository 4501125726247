import React from 'react';
import PropTypes from 'prop-types';
import { unlimitedMailBoxes } from '@constants/numericConstants';
import getRecordColor from '@components/Helpers/getRecordColor';
import useStyles from './styles';

const UsageField = ({ record = {}, usage, quota }) => {
  const backQuota =
    +record[quota] === unlimitedMailBoxes ? 'unlim' : record[quota];
  const color = getRecordColor(+record[usage] / Math.max(+record[quota], 0.1));
  const classes = useStyles({ color });

  return (
    <span className={classes.itemStyle}>
      {record[usage]}/{backQuota}
    </span>
  );
};

UsageField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
};

export default UsageField;
