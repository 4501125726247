import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-admin';
import { Field, Form } from 'react-final-form';
import MuiPhoneNumber from 'material-ui-phone-number';
import PropTypes from 'prop-types';
import Loading from '@common/Loading/Loading';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import Paper from '@common/Paper/Paper';
import { smsSettings } from '@store/selectors/notifications';
import { actions } from '@store/actions';
import iconArrow from '@assets/icons/arrowGrey.svg';
import { basePermissions } from '@constants/permissions';
import useDisabled from '@components//Hooks/useDisabled';

import useSmsSettings from '@components/Ticketing/Notifications/hooks/useSmsSettings';
import useTemplateVariables from '@components/Ticketing/Notifications/hooks/useTemplateVariables';
import { getTemplates } from '@components/Ticketing/Notifications/DmSettings/helpers';
import iconPlus from '@assets/icons/plusGrey.svg';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import useStyles from './styles';
import { getSmsSettings, postSmsSettings } from './helpers';
import useTicketSetting from '../../hooks/useTicketSetting';
import { smsSettingsValidator } from '../../validators';

const SmsDMSettings = ({ match }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const [inputName, setInputName] = useState('');

  const storedData = useSelector(smsSettings);

  const {
    submit,
    loading,
    handleBack,
    data,
    setData,
    anchorEl,
    setAnchorEl,
  } = useTicketSetting({
    storedData,
    paramsId: match.params.id,
    action: actions.saveSmsSetting,
    tileKey: 'isSms',
    apiPostRequest: postSmsSettings,
    apiGetSetting: getSmsSettings,
  });

  const { templateVariables } = useTemplateVariables({ func: getTemplates });

  const { handlePhoneChange } = useSmsSettings({
    data,
    setData,
  });

  const { editMode, disabled } = useDisabled(
    loading,
    'notificationSettings',
    basePermissions.update,
  );

  const dataLoaded = typeof data.id !== 'undefined';

  const openVariablesClick = (target, name) => {
    setAnchorEl(target);
    setInputName(name);
  };

  return dataLoaded ? (
    <Paper>
      <Form
        onSubmit={submit}
        validate={smsSettingsValidator}
        initialValues={data}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div>
              <FieldWrapper
                label="Phone number"
                labelSize={3}
                content={
                  <MuiPhoneNumber
                    defaultCountry="us"
                    value={data.phoneNumber}
                    onChange={handlePhoneChange}
                    disabled={disabled}
                  />
                }
              />
              <FieldWrapper
                label="SMS Template"
                labelSize={3}
                content={
                  <div className={classes.ticketSubject}>
                    <ActionButton
                      handler={event =>
                        openVariablesClick(event.currentTarget, 'template')
                      }
                      icon={<img src={iconPlus} alt="iconPlus" />}
                      toolTip="Add template variable"
                      disabled={disabled}
                    />
                    <Field
                      id="template"
                      name="template"
                      styleType="main"
                      inputView="text"
                      fullWidth
                      component={Input}
                      disabled={disabled}
                    />
                  </div>
                }
              />
              <FieldWrapper
                label="Recover Template"
                labelSize={3}
                content={
                  <div className={classes.ticketSubject}>
                    <ActionButton
                      handler={event =>
                        openVariablesClick(
                          event.currentTarget,
                          'recoverTemplate',
                        )
                      }
                      icon={<img src={iconPlus} alt="iconPlus" />}
                      toolTip="Add template variable"
                      disabled={disabled}
                    />
                    <Field
                      id="template"
                      name="recoverTemplate"
                      styleType="main"
                      inputView="text"
                      fullWidth
                      component={Input}
                      disabled={disabled}
                    />
                  </div>
                }
              />
            </div>
            <div className={classes.buttonWrapper}>
              <ReusableButton
                size="md"
                type="button"
                classNameWrapper={classes.button}
                disabled={loading}
                onClick={handleBack}
              >
                <>
                  <img src={iconArrow} alt="iconArrow" />
                  {translate('resources.buttons.back')}
                </>
              </ReusableButton>
              {editMode && (
                <ReusableButton
                  size="md"
                  viewType="black"
                  type="submit"
                  classNameWrapper={classes.button}
                  disabled={loading}
                  loading={loading}
                  label="resources.billing.updateButton"
                />
              )}
            </div>
            <TemplateMenu
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              form={form}
              name={inputName}
              templateVariables={templateVariables}
            />
          </form>
        )}
      />
    </Paper>
  ) : (
    <Loading />
  );
};

SmsDMSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default SmsDMSettings;
