import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      padding: '0.9375rem',
    },
    textAlign: 'center',
  },
  formContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  deleteButtonWrapper: {
    position: 'absolute',
    top: '0.3125rem',
    right: 0,
    padding: 0,
  },
  connectionStatusWrapper: {
    position: 'absolute',
    top: '0.7rem',
    right: '2rem',
    padding: 0,
  },
  buttonWrapper: {
    '@media (max-width: 1600px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
    width: '100%',
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  imageWrapper: {
    width: '25%',
    marginBottom: '0.3125rem',
  },
  imageLogo: {
    width: '100%',
  },
  inputLabel: {
    width: '100%',
    color: '#EC6A4E',
    marginTop: '0.5rem',
    margin: '0.5rem 0',
    textAlign: 'left',
    fontSize: '0.875rem',
  },
  inputWrapper: {
    marginBottom: '0.3125rem',
  },
  circle: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '1px 1px 6px 1px #848484',
    },
  },
}));

export default useStyles;
