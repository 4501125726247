import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';

import {
  ThunkCreateCompaniesVerificationSettings,
  ThunkUpdateCompaniesVerificationSettings,
} from '@store/slices/verificationSettings/thunks';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Form } from 'react-final-form';

import ReusableButton from '@ui/components/common/Button/Button';
import MinutesPeriodPicker from '@ui/components/common/form/DateTime/MinutesPeriodPicker';
import Checkbox from '@ui/components/common/Checkbox';
import Input from '@ui/components/Auth/Common/Input';
import AutocompleteFormInput from '@ui/components/common/AutocompleteFormInput/AutocompleteFormInput';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import useStyles from '../styles';

const CompanyVerificationSettingsDialog = ({
  open,
  onClose,
  record,
  companiesOptions,
  onSubmitHandle,
}) => {
  const [processing, setProcessing] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(
    record?.companyId || null,
  );
  const [verificationInMinutes, setVerificationInMinutes] = useState(
    record?.resetVerificationInMin || 0,
  );
  const [
    showVerificationBannerOnMount,
    setShowVerificationBannerOnMount,
  ] = useState(record?.showVerificationBannerOnMount || false);
  const [enableVerificationBanner, setEnableVerificationBanner] = useState(
    record?.enableVerificationBanner || false,
  );
  const [verificationBannerText, setVerificationBannerText] = useState(
    record?.verificationBannerText || null,
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const notify = useNotify();

  const onSubmit = () => {
    setProcessing(true);
    const item = {
      ...record,
      companyId: selectedCompany,
      resetVerificationInMin: verificationInMinutes,
      showVerificationBannerOnMount,
      enableVerificationBanner,
      verificationBannerText,
    };

    const isNew = !item.id;
    dispatch(
      isNew
        ? ThunkCreateCompaniesVerificationSettings(item)
        : ThunkUpdateCompaniesVerificationSettings(item),
    )
      .unwrap()
      .then(() => {
        notify(`Successfully ${isNew ? 'created' : 'updated'}`, 'success');
        onSubmitHandle();
        onClose();
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle>
        {record.id ? 'Edit ' : 'Add '}
        Company Policy
      </DialogTitle>

      <DialogContent>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ width: '950px' }}>
              <div className={classes.listItemField}>
                <div>PSA Company</div>
                <AutocompleteFormInput
                  input={{
                    value: selectedCompany,
                    onChange: e => {
                      setSelectedCompany(e);
                    },
                  }}
                  meta={{ touched: false }}
                  items={companiesOptions}
                />
              </div>
              <div className={classes.listItemField}>
                <div>Reset Verification in</div>
                <MinutesPeriodPicker
                  input={{
                    value: verificationInMinutes,
                    onChange: e => {
                      setVerificationInMinutes(e);
                    },
                  }}
                  meta={{ touched: false }}
                />
              </div>
              <div className={classes.listItemField}>
                <div>Enable Verification Banner On Pod</div>
                <Checkbox
                  checked={enableVerificationBanner}
                  meta={{ touched: false }}
                  onChange={e => setEnableVerificationBanner(e.target.checked)}
                />
              </div>
              {enableVerificationBanner && (
                <div className={classes.listItemField}>
                  <div>Verification Banner Text</div>
                  <Input
                    meta={{ touched: false }}
                    input={{
                      onChange: e => {
                        setVerificationBannerText(e.target.value);
                      },
                      value: verificationBannerText,
                    }}
                  />
                </div>
              )}

              {enableVerificationBanner && (
                <div className={classes.listItemField}>
                  <div>Show Verification Banner On Load</div>
                  <Checkbox
                    checked={showVerificationBannerOnMount}
                    meta={{ touched: false }}
                    onChange={e =>
                      setShowVerificationBannerOnMount(e.target.checked)
                    }
                  />
                </div>
              )}

              <div className={sharedClasses.dialogActionsContainer}>
                <ReusableButton
                  label="Close"
                  onClick={() => {
                    onClose();
                  }}
                  loading={processing}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  type="submit"
                  loading={processing}
                  disabled={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

CompanyVerificationSettingsDialog.propTypes = {
  psaId: PropTypes.number.isRequired,
};
export default CompanyVerificationSettingsDialog;
