import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import HaloContactTableActions from '@components/PsaTickets/components/Halo/HaloContactTableActions';
import HaloTicketTableActions from '@components/PsaTickets/components/Halo/HaloTicketTableActions';
import {
  ThunkGetHaloClientsByDefaultId,
  ThunkGetHaloSites,
  ThunkGetHaloTeams,
  ThunkGetHaloTicketStatuses,
  ThunkGetHaloTicketTypes,
  ThunkGetHaloUsers,
} from '@store/slices/HaloTickets/thunks';
import { getHaloClientsByCrmId } from '@services/requests/haloTickets';

import useStyles from '../../styles';

const HaloTicketTable = ({
  defaultId,
  crmId,
  resource,
  tableType = 'tickets',
  defaultSortName = 'ticketNumber',
  bulkActions,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [clientsOptions, setClientsOptions] = useState([]);
  const [options, setOptions] = useState({
    statusOptions: [],
    typesOptions: [],
    clientsOptions: [],
    sitesOptions: [],
    usersOptions: [],
    teamsOptions: [],
  });

  const handleTicketIdClick = useCallback(ticketLink => {
    window.open(ticketLink, '_blank');
  }, []);

  const onMount = useCallback(async () => {
    if (tableType === 'tickets') {
      await Promise.all([
        new Promise(res =>
          res(dispatch(ThunkGetHaloTicketStatuses(defaultId)).unwrap()),
        ),
        new Promise(res =>
          res(dispatch(ThunkGetHaloTicketTypes(defaultId)).unwrap()),
        ),
        new Promise(res =>
          res(dispatch(ThunkGetHaloClientsByDefaultId(defaultId)).unwrap()),
        ),
        new Promise(res =>
          res(dispatch(ThunkGetHaloSites({ crmId: defaultId })).unwrap()),
        ),
        new Promise(res =>
          res(dispatch(ThunkGetHaloUsers({ crmId: defaultId })).unwrap()),
        ),
        new Promise(res =>
          res(dispatch(ThunkGetHaloTeams(defaultId)).unwrap()),
        ),
      ]).then(res => {
        setOptions({
          statusOptions: res[0],
          typesOptions: res[1],
          clientsOptions: res[2],
          sitesOptions: res[3],
          usersOptions: res[4],
          teamsOptions: res[5],
        });
      });
    } else {
      getHaloClientsByCrmId(crmId).then(res => {
        setClientsOptions(res);
      });
    }
  }, [tableType, dispatch, defaultId, crmId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const columns = {
    tickets: [
      {
        name: 'Id',
        key: 'id',
        type: TableTypes.actions,
        accessor: item => (
          <div
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={() => {
              handleTicketIdClick(item.ticketLink);
            }}
            role="presentation"
          >
            {item.id}
          </div>
        ),
        searchable: true,
        sortable: true,
      },
      {
        name: 'Summary',
        key: 'summary',
        searchable: true,
        sortable: true,
      },
      {
        name: 'Status',
        key: 'statusName',
        filterByAutocomplete: 'statusId',
        sortName: 'statusId',
        searchable: true,
        sortable: true,
        type: TableTypes.dropdown,
        dropDownValues: options.statusOptions,
      },
      {
        name: 'Type',
        key: 'ticketTypeName',
        filterByAutocomplete: 'ticketTypeId',
        sortName: 'ticketTypeId',
        searchable: true,
        sortable: true,
        type: TableTypes.dropdown,
        dropDownValues: options.typesOptions,
      },
      {
        name: 'Customer',
        key: 'clientName',
        filterByAutocomplete: 'clientId',
        sortName: 'clientId',
        searchable: true,
        sortable: true,
        type: TableTypes.dropdown,
        dropDownValues: options.clientsOptions,
      },
      {
        name: 'Site',
        key: 'siteName',
        filterByAutocomplete: 'siteId',
        sortName: 'siteId',
        searchable: true,
        sortable: true,
        type: TableTypes.dropdown,
        dropDownValues: options.sitesOptions,
      },
      {
        name: 'User',
        key: 'userName',
        filterByAutocomplete: 'userId',
        searchable: true,
        sortable: true,
        type: TableTypes.dropdown,
        dropDownValues: options.usersOptions,
      },
      {
        name: 'Team',
        key: 'team',
        filterByAutocomplete: 'teamId',
        sortName: 'teamId',
        searchable: true,
        sortable: true,
        type: TableTypes.dropdown,
        dropDownValues: options.teamsOptions,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        accessor: record => (
          <HaloTicketTableActions
            record={record}
            crmDefaultId={defaultId}
            crmId={crmId}
          />
        ),
      },
    ],
    contacts: [
      {
        name: 'Name',
        key: 'name',
        sortable: true,
        searchable: true,
      },
      {
        name: 'Customer',
        key: 'companyName',
        filterByAutocomplete: 'clientId',
        searchable: true,
        sortable: true,
        type: TableTypes.dropdown,
        dropDownValues: clientsOptions,
      },
      {
        name: 'Phone',
        key: 'phone',
        searchable: true,
        type: TableTypes.phoneNumber,
      },
      { name: 'Email', key: 'email', sortable: true, searchable: true },
      {
        name: 'Is Verified',
        key: 'isVerified',
        type: TableTypes.boolean,
        labelAlignment: 'center',
      },
      {
        name: 'Is Opted-in',
        key: 'isOptedIn',
        type: TableTypes.boolean,
        labelAlignment: 'center',
      },
      {
        name: 'Client Portal',
        key: 'hasClientPortal',
        type: TableTypes.boolean,
        labelAlignment: 'center',
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        accessor: item => (
          <HaloContactTableActions record={item} psaId={crmId} />
        ),
      },
    ],
  };

  return (
    <div className={classes.tableWrapper}>
      {(!!options.usersOptions.length || !!clientsOptions.length) && (
        <Table
          resource={resource || `TicketUpdate/halo/${defaultId}/tickets`}
          columns={columns[tableType]}
          defaultSort={{ field: defaultSortName, order: 'DESC' }}
          bulkActions={bulkActions}
        />
      )}
    </div>
  );
};

HaloTicketTable.propTypes = {
  defaultId: PropTypes.number,
  crmId: PropTypes.number.isRequired,
  resource: PropTypes.string,
  tableType: PropTypes.string,
  defaultSortName: PropTypes.string,
  bulkActions: PropTypes.node,
};

export default HaloTicketTable;
