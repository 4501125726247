import React from 'react';
import { TopToolbar } from 'react-admin';
import ReusableButton from '@common/Button/Button';
import addIcon from '@assets/icons/add_blue.svg';

import PropTypes from 'prop-types';
import appConfig from '@configs/appConfig';
import useStyles from '../styles';

const ListActions = ({ onCreate, onCreateFromCrm, onCreateFromTenant }) => {
  const classes = useStyles();

  return (
    <TopToolbar classes={{ root: classes.root }} className={classes.toolBar}>
      {onCreate && (
        <ReusableButton
          size="xl"
          onClick={onCreate}
          textSize="mini"
          classNameWrapper={classes.buttonWrapper}
        >
          <>
            <img src={addIcon} alt="add" />
            <span>New Group</span>
          </>
        </ReusableButton>
      )}
      {onCreateFromCrm && (
        <ReusableButton
          size="xl"
          onClick={onCreateFromCrm}
          textSize="mini"
          classNameWrapper={classes.buttonWrapper}
        >
          <>
            <img src={addIcon} alt="add" />
            <span>From CRM Users</span>
          </>
        </ReusableButton>
      )}
      {onCreateFromTenant && (
        <ReusableButton
          size="xl"
          onClick={onCreateFromTenant}
          textSize="mini"
          classNameWrapper={classes.buttonWrapper}
        >
          <>
            <img src={addIcon} alt="add" />
            <span>{`From ${appConfig.projectName}`}</span>
          </>
        </ReusableButton>
      )}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onCreateFromCrm: PropTypes.func,
  onCreateFromTenant: PropTypes.func,
};

export default ListActions;
