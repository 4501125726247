import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useStyles from './styles';
import events from './events';

const EventsWrapper = ({ deviceEventType }) => {
  const event = events[deviceEventType];
  const { icon: Icon } = event;
  const classes = useStyles({ deviceEventType, color: event.color });

  return (
    <Box>
      <div className={classes.eventContainer}>
        {event.name}
        {<Icon className={classes.icon} />}
      </div>
    </Box>
  );
};

EventsWrapper.propTypes = {
  deviceEventType: PropTypes.number,
};

export default EventsWrapper;
