import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  nameField: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
  },
}));

export default useStyles;
