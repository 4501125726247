import React, { useState } from 'react';
import cx from 'classnames';
import { Field, Form } from 'react-final-form';
import { useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ThunkRegisterDemoUser } from '@store/slices/auth/thunks';
import { requiredValidator } from '@utils/validators';
import {
  getCountriesOptions,
  getStatesOptions,
} from '@constants/countries/countries';

import { LOGIN_ROUTE } from '@constants/routes';

import { ReactComponent as EyeIcon } from '@assets/icons/eye.svg';
import { ReactComponent as EyeClosedIcon } from '@assets/icons/eye-closed.svg';

import LoginPageBanner from '@ui/common/LoginPageBanner';
import UnauthorizedProvider from '@ui/common/UnauthorizedProvider/UnauthorizedProvider';
import AppLogo from '@ui/common/AppLogo';
import Checkbox from '@common/Checkbox/Checkbox';
import Input from '@components/Auth/Common/Input';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import ReusableButton from '@common/Button/Button';

import useSharedStyles from '../Login/styles';

const DemoRegistration = ({ location: { search } }) => {
  const [processing, setProcessing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const urlParams = new URLSearchParams(search);
  const authKey = urlParams.get('auth-key');
  const sharedClasses = useSharedStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const validate = values => ({
    username: requiredValidator(values.username),
    email: requiredValidator(values.email),
    companyName: requiredValidator(values.companyName),
    password: requiredValidator(values.password),
    country: requiredValidator(values.country),
    state: requiredValidator(values.state),
    passwordConfirmation:
      values.passwordConfirmation !== values.password
        ? 'Passwords don`t match'
        : undefined,
  });

  const submit = values => {
    if (!values.agreeToTermsOfUse) {
      notify('Please agree to terms of use first!', 'warning');
      return;
    }

    setProcessing(true);
    dispatch(ThunkRegisterDemoUser({ authKey, payload: values }))
      .unwrap()
      .then(() => {
        notify('User Created', 'success');
        push(LOGIN_ROUTE);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <UnauthorizedProvider>
      <div className={sharedClasses.container}>
        <LoginPageBanner />
        <div className={sharedClasses.activeContainer}>
          <AppLogo />

          <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit, values }) => (
              <div>
                <form onSubmit={handleSubmit}>
                  <div className={sharedClasses.typographyContainer}>
                    <h5>Demo Registration</h5>
                  </div>
                  <div className={sharedClasses.fieldItem}>
                    <p>Full Name</p>
                    <Field
                      id="username"
                      name="username"
                      component={Input}
                      placeholder="Enter your full name"
                      disabled={processing}
                    />
                  </div>

                  <div className={sharedClasses.fieldItem}>
                    <p>Email</p>
                    <Field
                      id="email"
                      name="email"
                      component={Input}
                      placeholder="Enter your email"
                      disabled={processing}
                    />
                  </div>

                  <div className={sharedClasses.fieldItem}>
                    <p>Company Name</p>
                    <Field
                      id="companyName"
                      name="companyName"
                      component={Input}
                      placeholder="Enter your company name"
                      disabled={processing}
                    />
                  </div>

                  <div className={sharedClasses.fieldItem}>
                    <p>Password</p>
                    <Field
                      id="password"
                      name="password"
                      component={Input}
                      placeholder="Enter your password"
                      type={showPassword ? 'text' : 'password'}
                      disabled={processing}
                      endAdornment={
                        <div
                          onClick={() => setShowPassword(!showPassword)}
                          role="presentation"
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {!showPassword ? <EyeIcon /> : <EyeClosedIcon />}
                        </div>
                      }
                    />
                  </div>

                  <div className={sharedClasses.fieldItem}>
                    <p>Confirm Password</p>
                    <Field
                      id="passwordConfirmation"
                      name="passwordConfirmation"
                      type={showConfirmPassword ? 'text' : 'password'}
                      component={Input}
                      placeholder="Enter your password"
                      disabled={processing}
                      endAdornment={
                        <div
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          role="presentation"
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {!showConfirmPassword ? (
                            <EyeIcon />
                          ) : (
                            <EyeClosedIcon />
                          )}
                        </div>
                      }
                    />
                  </div>

                  <div className={sharedClasses.fieldItem}>
                    <p>Country</p>
                    <Field
                      id="country"
                      name="country"
                      component={AutocompleteFormInput}
                      label="Country"
                      items={getCountriesOptions()}
                      disabled={processing}
                    />
                  </div>

                  <div className={sharedClasses.fieldItem}>
                    <p>State</p>
                    <Field
                      id="state"
                      name="state"
                      component={AutocompleteFormInput}
                      label="State"
                      items={getStatesOptions(values.country)}
                      disabled={processing}
                    />
                  </div>

                  <div
                    className={cx(
                      sharedClasses.fieldItem,
                      sharedClasses.checkboxContainer,
                    )}
                  >
                    <Field
                      id="agreeToTermsOfUse"
                      name="agreeToTermsOfUse"
                      component={Checkbox}
                    />
                    <p>I agree to Terms of Service and Privacy Policy</p>
                  </div>

                  <div>
                    <ReusableButton
                      label="Register"
                      type="submit"
                      loading={processing}
                      disabled={processing}
                      viewType="blue"
                      classNameWrapper={sharedClasses.button}
                    />
                  </div>
                </form>
              </div>
            )}
          />
        </div>
      </div>
    </UnauthorizedProvider>
  );
};

export default DemoRegistration;
