import React from 'react';
import PropTypes from 'prop-types';
import ViewAutoComplete from '@common/ViewAutoComplete/ViewAutoComplete';
import localStorage from '@services/localStorage';
import localStorageConst from '@constants/localStorage';

const SelectGroup = ({ groupByValue, setGroupByValue }) => {
  const handleCurrentGroupSelected = selectedOption => {
    if (selectedOption) {
      setGroupByValue(selectedOption);
      localStorage.setItem(localStorageConst.SELECTED_GROUPBY, selectedOption);
    } else {
      setGroupByValue(null);
      localStorage.removeItem(localStorageConst.SELECTED_GROUPBY);
    }
  };

  return (
    <>
      <ViewAutoComplete
        currentView={groupByValue}
        options={[
          { value: 'company', label: 'Company' },
          { value: 'type', label: 'Type' },
          { value: 'lastEventType', label: 'Status' },
          { value: 'region', label: 'Region' },
        ]}
        onChange={handleCurrentGroupSelected}
        textFieldLabel="Group by"
      />
    </>
  );
};

SelectGroup.propTypes = {
  groupByValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  setGroupByValue: PropTypes.func,
};

export default SelectGroup;
