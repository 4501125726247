import React from 'react';
import cx from 'classnames';
import AddIcon from '@assets/icons/AddPlus.svg';
import useStyles from '../styles';

const AddCard = ({ handleAddClick }) => {
  const classes = useStyles();

  const handleClick = () => {
    handleAddClick();
  };

  return (
    <div
      className={cx(
        classes.container,
        classes.addCrmCardContainer,
        classes.smallContainer,
      )}
      role="presentation"
      style={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      <div className={classes.headerContainer}>
        <span>Add new integration</span>
      </div>
      <div className={classes.block}>
        <div className={classes.iconWrapper}>
          <img src={AddIcon} alt="addIcon" />
        </div>
      </div>
    </div>
  );
};

export default AddCard;
