import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TableSortLabel from '@common/TableLabel/TableSortLabel';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import ZendeskTicketTableFilters from '@components/PsaTickets/components/Zendesk/ZendeskTicketTableFilters';
import TableLabel from '@common/TableLabel/TableLabel';
import useStyles from '../../styles';
import ZendeskTicketTableActions from './ZendeskTicketTableActions';

const ZendeskTicketTable = ({ defaultId, crmId }) => {
  const classes = useStyles();

  const handleTicketIdClick = useCallback(ticketLink => {
    window.open(ticketLink, '_blank');
  }, []);

  const columns = [
    {
      name: (
        <TableLabel
          item={{ field: 'id', name: 'ID' }}
          numbersOnly
          showSortButton={false}
        />
      ),
      key: 'id',
      accessor: item => (
        <div
          className={classes.link}
          onClick={() => {
            handleTicketIdClick(item.ticketLink);
          }}
          role="presentation"
        >
          {item.id}
        </div>
      ),
    },
    {
      name: (
        <TableLabel
          item={{ field: 'subject', name: 'Subject' }}
          showSortButton={false}
        />
      ),
      key: 'subject',
    },
    {
      name: (
        <TableLabel
          item={{ field: 'description', name: 'Description' }}
          showSortButton={false}
        />
      ),
      key: 'description',
    },
    {
      name: <TableSortLabel item={{ field: 'status', name: 'Status' }} />,
      key: 'status',
    },
    {
      name: <TableSortLabel item={{ field: 'type', name: 'Type' }} />,
      key: 'type',
    },
    {
      name: <TableSortLabel item={{ field: 'priority', name: 'Priority' }} />,
      key: 'priority',
    },
    {
      name: 'Requester',
      key: 'requester',
    },
    {
      name: 'Assignee',
      key: 'assignee',
    },
    {
      name: 'Actions',
      key: 'actions',
      accessor: record => (
        <ZendeskTicketTableActions
          record={record}
          crmDefaultId={defaultId}
          crmId={crmId}
        />
      ),
    },
  ];

  return (
    <div>
      <MuiVirtualizedTable
        actions={<></>}
        columns={columns}
        resource={`TicketUpdate/zendesk/${defaultId}/tickets`}
        defaultSort={{ field: 'id', order: 'DESC' }}
      >
        <ZendeskTicketTableFilters defaultId={defaultId} />
      </MuiVirtualizedTable>
    </div>
  );
};

ZendeskTicketTable.propTypes = {
  defaultId: PropTypes.number.isRequired,
  crmId: PropTypes.number.isRequired,
};

export default ZendeskTicketTable;
