import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import {
  incidents,
  mailBoxes,
  notificationSettings,
  triggers,
} from '@constants/appRoutes';
import postWithResponse from '@services/api/common/postWithResponse';
import putData from '@services/api/common/putData';
import putWithResponse from '@services/api/common/putWithResponse';
import deleteWithResponse from '@services/api/common/deleteWithResponse';

export const getMailboxesOptions = () =>
  getData(`${appConfig.baseUrl}${mailBoxes}getAsAutocomplete?skipEmpty=true`);

export const getTagsAutocomplete = () =>
  getData(`${appConfig.baseUrl}${triggers}get-tags-autocomplete`);

export const getIncidentsTagsAutocomplete = () =>
  getData(`${appConfig.baseUrl}${incidents}get-tags-autocomplete`);

export const createTrigger = data =>
  postWithResponse(`${appConfig.baseUrl}${triggers}`, data);

export const toggleEnabled = (id, data) =>
  putData(`${appConfig.baseUrl.concat(triggers)}${id}/makeEnabled`, data);

export const getNotificationOptions = () =>
  getData(
    `${appConfig.baseUrl}${notificationSettings}get-autocomplete?src=Trigger`,
  );

export const addIncidentAssignee = (assigneeId, incidentId, action) =>
  putWithResponse(
    `${
      appConfig.baseUrl
    }${incidents}addAssignee?assigneeId=${assigneeId}&incidentId=${incidentId}${
      action ? `&action=${action}` : ''
    }`,
  );

export const getDetails = id =>
  getData(`${appConfig.baseUrl.concat(triggers)}${id}`);

export const deleteIncident = id =>
  deleteWithResponse(`${appConfig.baseUrl}${incidents}?id=${id}`);

export const deleteIncidents = ids =>
  deleteWithResponse(`${appConfig.baseUrl}${incidents}?${ids}`);

export const addIncidentAssignees = (assigneeId, incidentsIds, action) =>
  putWithResponse(
    `${
      appConfig.baseUrl
    }${incidents}addAssignee?assigneeId=${assigneeId}&${incidentsIds}${
      action ? `&action=${action}` : ''
    }`,
  );

export const updateIncident = (id, data) =>
  putWithResponse(`${appConfig.baseUrl}${incidents}${id}`, data);

export const cloneTrigger = id =>
  postWithResponse(`${appConfig.baseUrl}${triggers}${id}/clone`);

export const getIncident = id =>
  getData(`${appConfig.baseUrl}${incidents}${id}`);

export const createTicketForIncident = data =>
  postWithResponse(`${appConfig.baseUrl}${incidents}create-ticket`, data);

export const createIncident = data =>
  postWithResponse(`${appConfig.baseUrl}${incidents}`, data);
