import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider, Tooltip } from '@material-ui/core';
import { ExitToAppRounded } from '@material-ui/icons';
import { Notification, useGetOne, useLogout } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '@store/actions';
import resources from '@constants/resources';

import { ThunkGetAutotaskPodDataByTicketId } from '@store/slices/psaPods/thunks';
import { MessagingChannelSelector } from '@store/slices/messaging';
import { changeStateOfPodPageFlag, setPodData } from '@store/slices/psaPods';

import EndUserVerification from '@components/integrations/connectwise/tabs/EndUserVerification';
import MessagingComponent from '@components/integrations/connectwise/components/MessagingComponent';
import ClientChatsWrapper from '@components/integrations/connectwise/components/ClientChatsWrapper';
import SecureLink from '@components/integrations/secureLink';
import PsaPodTechVerification from '@components/integrations/connectwise/tabs/PsaPodTechVerification';
import TabPanel from '@common/TabPanel/TabPanel';
import Loading from '@common/Loading/Loading';
import DisplayCompanyPolicies from '@components/integrations/components/DisplayCompanyPolicies';

import useSocketForHistory from '@components/integrations/connectwise/hooks/useSocketForHistory';
import useStyles from '@components/integrations/styles';
import { lightTheme } from '@services/themes/mainTheme';

import crmSources from '@constants/crmSources';
import LocalStorage from '@constants/localStorage';
import { parseJwtToken } from '@services/api';
import { getPodPhoneNumber } from '../helpers';

const theme = createTheme(lightTheme);

const AutotaskPod = ({ location: { search }, match }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ticketId, setTicketId] = useState(0);

  const classes = useStyles();
  const dispatch = useDispatch();
  const channel = useSelector(MessagingChannelSelector);
  const data = useSelector(state => state.pod.data);

  const phoneNumber = useMemo(() => {
    return getPodPhoneNumber(data);
  }, [data]);

  const onMount = useCallback(async () => {
    setLoading(true);
    const urlParams = new URLSearchParams(search);

    const ticketIdParam = urlParams.get('entityid');
    if (!+ticketIdParam) {
      setError(true);
      return;
    }

    setTicketId(+ticketIdParam);

    if (Number(ticketIdParam) && Number(match.params.crmId)) {
      dispatch(
        ThunkGetAutotaskPodDataByTicketId({
          crmId: match.params.crmId,
          ticketId: +ticketIdParam,
        }),
      )
        .unwrap()
        .catch(() => {
          setError(true);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  const handleRefresh = () => {
    onMount();
  };

  const ticketTabsData = [
    {
      label: 'End User ID',
      value: (
        <EndUserVerification
          data={data}
          match={match}
          onRefresh={handleRefresh}
          ticketId={ticketId}
          psaType={crmSources.Autotask.name}
        />
      ),
    },
    {
      label: 'SMS',
      value: (
        <MessagingComponent
          data={data}
          crmId={match.params.crmId}
          psaType={crmSources.Autotask.name}
          crmType={crmSources.Autotask.idx}
          ticketId={data.TicketNumber}
          phoneNumber={phoneNumber}
          channel={channel}
        />
      ),
    },
    data?.clientPortalChats?.length >= 1 && {
      label: 'Chat',
      value: <ClientChatsWrapper clientPortalChats={data?.clientPortalChats} />,
    },
    {
      label: 'Send Secure Data',
      value: (
        <SecureLink
          userPhone={phoneNumber}
          userEmail={data.ContactEmail}
          psaContactId={data.ContactId}
          psaId={match.params.crmId}
          psaType={crmSources.Autotask.label}
        />
      ),
    },
    {
      label: 'Tech Verification',
      value: <PsaPodTechVerification crmId={match.params.crmId} />,
    },
  ];

  const content = useMemo(
    () => (
      <TabPanel
        tabs={ticketTabsData.filter(i => i) || []}
        viewTypeLink="main"
        classNameWrapperTabs={classes.tabsWrapper}
        isTransparent
        classNameTabTitle={classes.tabTitle}
        classNameContentWrapper={classes.tabContent}
        classFlexContainer={classes.flex}
      />
    ),
    [data, match, ticketTabsData],
  );

  useEffect(() => {
    if (match) onMount();
  }, []);

  useEffect(() => {
    // first load setting properties for pod in store
    const urlParams = new URLSearchParams(search);
    const ticketIdParam = urlParams.get('entityid');
    dispatch(changeStateOfPodPageFlag(true));
    if (match.params.crmId !== 'crmId' && Number(match.params.crmId)) {
      dispatch(
        setPodData({
          crmId: match.params.crmId,
          ticketId: ticketIdParam,
          screen: 'ticket',
          type: 'autotask',
        }),
      );
    }
  }, [dispatch, search, match]);

  const { data: userData } = useGetOne(
    resources.user,
    localStorage.getItem('userId'),
  );

  useEffect(() => {
    if (userData) {
      dispatch(actions.setCurrentUser(userData));
      localStorage.setItem('tenantId', userData.tenantId);
    }
  }, [userData, dispatch]);

  //* Add this custom for creating socket connection for this pod page
  useSocketForHistory(match.params.crmId, data.ContactId);
  const logout = useLogout();
  const token = localStorage.getItem(LocalStorage.ACCESS_TOKEN);

  useEffect(() => {
    if (userData?.id && token) {
      const claims = parseJwtToken(token);

      window.pendo.initialize({
        visitor: {
          id: +userData.id,
          email: userData.email,
          fullName: userData.username,
        },
        account: {
          id: userData.tenantId,
          tenantName: claims.tenantAlias,
        },
      });
    }
  }, [userData?.id, token]);

  if (error) return <div>Error on page</div>;
  if (loading && !error) return <Loading />;
  return (
    <ThemeProvider theme={theme}>
      <DisplayCompanyPolicies
        crmId={match.params.crmId}
        companyId={data?.CompanyId}
      />

      <div className={classes.container}>{content}</div>

      <div className={classes.logOutContainer}>
        <Tooltip title="Log Out">
          <ExitToAppRounded onClick={logout} />
        </Tooltip>
      </div>

      <Notification />
    </ThemeProvider>
  );
};

AutotaskPod.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      psaId: PropTypes.string,
    }),
  }),
};

export default AutotaskPod;
