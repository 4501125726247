import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-final-form';
import { Draggable } from 'react-beautiful-dnd';
import { FormControlLabel } from '@material-ui/core';

import {
  inputTypes,
  ISSUE_TYPE_ITEMS_NAME,
} from '@ui/pages/clientPortal/IssueType/constants';

import DropDown from '@components/Auth/Common/DropDown';
import IssueTypeInput from '@ui/pages/clientPortal/IssueType/components/IssueTypeInput';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import CustomCheckbox from '@common/Checkbox';
import DeleteIcon from '@assets/icons/delete.svg';

import IssueTypeMultiSelect from '@ui/pages/clientPortal/IssueType/components/IssueTypeMultiSelect';
import useStyles from '../styles';

const IssueTypeItem = ({
  inputType,
  id,
  innerId,
  isRequired: required,
  title,
  index,
  disabled,
  values: options = undefined,
}) => {
  const [type, setType] = useState(inputType || 1);
  const [isRequired, setIsRequired] = useState(required || false);

  const form = useForm();
  const classes = useStyles();

  const updateItem = (newTitle = null) => {
    const { values } = form.getState();
    const questions = values[ISSUE_TYPE_ITEMS_NAME].map(i => {
      return (id && i.id === id) || (innerId && i.innerId === innerId)
        ? {
            ...i,
            inputType: type,
            isRequired,
            title: newTitle !== null ? newTitle : title,
          }
        : i;
    });
    form.change(ISSUE_TYPE_ITEMS_NAME, questions);
  };

  const updateMultiselectOptions = (newOptions = null) => {
    const { values } = form.getState();
    const questions = values[ISSUE_TYPE_ITEMS_NAME].map(i => {
      return (id && i.id === id) || (innerId && i.innerId === innerId)
        ? {
            ...i,
            inputType: type,
            isRequired,
            title,
            values: newOptions,
          }
        : i;
    });
    form.change(ISSUE_TYPE_ITEMS_NAME, questions);
  };

  const removeItem = () => {
    const { values } = form.getState();
    const questions = values[ISSUE_TYPE_ITEMS_NAME].filter(
      i => (id && i.id !== id) || (innerId && i.innerId !== innerId),
    );
    form.change(ISSUE_TYPE_ITEMS_NAME, questions);
  };

  const content = useMemo(() => {
    switch (type) {
      case 1:
        return (
          <IssueTypeInput
            updateTitle={updateItem}
            title={title}
            disabled={disabled}
          />
        );
      case 2:
        return (
          <IssueTypeInput
            updateTitle={updateItem}
            title={title}
            disabled={disabled}
          />
        );
      case 3:
        return (
          <IssueTypeInput
            updateTitle={updateItem}
            title={title}
            disabled={disabled}
          />
        );
      case 4:
        return (
          <IssueTypeMultiSelect
            updateTitle={updateItem}
            updateMultiselectOptions={updateMultiselectOptions}
            disabled={disabled}
            title={title}
            values={options}
          />
        );
      default:
        return (
          <IssueTypeInput
            updateTitle={updateItem}
            title={title}
            disabled={disabled}
          />
        );
    }
  }, [type]);

  useEffect(() => {
    updateItem();
  }, [type, isRequired]);

  return (
    <Draggable draggableId={id ? String(id) : String(innerId)} index={index}>
      {provided => (
        <div
          className={classes.itemContainer}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className={classes.itemHeader}>
            <div>
              <DropDown
                options={inputTypes}
                input={{
                  value: type,
                  onChange: e => {
                    setType(e.target.value);
                  },
                }}
              />
            </div>

            <div className={classes.actions}>
              {inputType === 2 && (
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      input={{
                        checked: isRequired,
                        onChange: () => {
                          setIsRequired(!isRequired);
                        },
                      }}
                    />
                  }
                  label="Required"
                />
              )}

              <ActionFieldItem
                handler={removeItem}
                icon={DeleteIcon}
                toolTip="Delete"
              />
            </div>
          </div>
          {content}
        </div>
      )}
    </Draggable>
  );
};

export default IssueTypeItem;
