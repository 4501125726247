const crmSources = Object.freeze({
  ConnectWise: { name: 'ConnectWiseRestApi', label: 'ConnectWise', idx: 0 },
  Autotask: { name: 'Autotask', label: 'Autotask', idx: 1 },
  Kaseya: { name: 'Kaseya', label: 'Kaseya', idx: 2 },
  ServiceNow: { name: 'ServiceNow', label: 'ServiceNow', idx: 3 },
  SyncroMsp: { name: 'SyncroMsp', label: 'SyncroMsp', idx: 4 },
  Halo: { name: 'Halo', label: 'Halo Service Desk', idx: 5 },
  Zendesk: { name: 'Zendesk', label: 'Zendesk', idx: 6 },
  SuperOps: { name: 'SuperOps', label: 'SuperOps', idx: 7 },
});

export const crmSourcesByType = Object.freeze({
  ConnectWiseRestApi: { name: 'ConnectWise' },
  Autotask: { name: 'Autotask' },
  Halo: { name: 'Halo' },
  ServiceNow: { name: 'ServiceNow' },
  SyncroMsp: { name: 'SyncroMsp' },
  Kaseya: { name: 'Kaseya' },
  Zendesk: { name: 'Zendesk' },
  SuperOps: { name: 'SuperOps' },
});

export default crmSources;

export const crmSourcesEnum = Object.freeze({
  0: 'ConnectWise',
  1: 'Autotask',
  2: 'Kaseya',
  3: 'ServiceNow',
  4: 'SyncroMsp',
  5: 'Halo Service Desk',
  6: 'Zendesk',
  7: 'SuperOps',
});

export const crmSourcesOptions = [
  { label: 'ConnectWise', value: 0 },
  { label: 'Autotask', value: 1 },
  { label: 'Kaseya', value: 2 },
  { label: 'ServiceNow', value: 3 },
  { label: 'SyncroMsp', value: 4 },
  { label: 'Halo Service Desk', value: 5 },
  { label: 'Zendesk', value: 6 },
  { label: 'SuperOps', value: 7 },
];
