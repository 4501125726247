import React from 'react';
import SignalRProvider from './SignalRProvider';
import TelemetryProvider from './TelemetryProvider';

const AppProviders = ({ children }) => {
  return (
    <TelemetryProvider>
      <SignalRProvider>{children}</SignalRProvider>
    </TelemetryProvider>
  );
};

export default AppProviders;
