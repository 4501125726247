import React from 'react';
import { useLogout } from 'react-admin';
import { ExitToApp as ExitToAppIcon } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

import { removeTenantImageFromStorage } from '@services/api';
import useStyles from './styles';

const Logout = () => {
  const logout = useLogout();
  const classes = useStyles();

  return (
    <div
      role="presentation"
      onClick={e => {
        logout(e).then(() => {
          removeTenantImageFromStorage();
        });
      }}
      className={classes.logout}
    >
      <Tooltip title="Log out">
        <ExitToAppIcon />
      </Tooltip>
    </div>
  );
};

export default Logout;
