import React from 'react';
import PropTypes from 'prop-types';

import ReusableButton from '@ui/components/common/Button/Button';
import useStyles from '../styles';

const TableActions = ({ openDialog }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ReusableButton
        label="Create"
        onClick={() => {
          openDialog();
        }}
      />
    </div>
  );
};

TableActions.propTypes = {
  openDialog: PropTypes.func,
};
export default TableActions;
