import React, { useEffect, useMemo, useState } from 'react';
import {
  useNotify,
  usePermissions,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import Authorize from '@common/Authorize/Authorize';
import { TICKETING, TICKETING_CREATE } from '@constants/routes';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import resources from '@constants/resources';
import iconEdit from '@assets/icons/edit.svg';
import iconView from '@assets/icons/visibility_blue.svg';
import iconDelete from '@assets/icons/delete.svg';
import { basePermissions } from '@constants/permissions';
import NotificationTypes from '@components/Ticketing/Notifications/TicketSettings/NotificationTypes';
import TableLabel from '@common/TableLabel/TableLabel';
import StatusCircle from '@common/StatusCircle/StatusCircle';
import CopyIcon from '@assets/icons/copy_blue.svg';
import TableDropDown from '@common/TableDropDown/TableDropDown';
import StatusesAccessor from '@common/MuiVirtualizedTable/components/accessors/StatusesAccessor';
import { deleteSetting, sanitizeProps, toggleEnabled } from './helpers';
import ListActions from './components/ListActions';
import MakeCopyDialog from './components/dialogs/MakeCopyDialog';
import notificationSources from './notificationSources';
import useStyles from './Form/stylesNotificationSettingsForm';
import BulkActionButtons from './components/BulkActionButtons';

const NotificationSettings = props => {
  const classes = useStyles();
  const redirect = useRedirect();
  const translate = useTranslate();
  const notify = useNotify();
  const dispatch = useDispatch();
  const { permissions } = usePermissions();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [makeCopyDialogOpen, setMakeCopyDialogOpen] = useState(false);
  const [clonedItem, setClonedItem] = useState({});
  const list = useSelector(listSelector);

  const resource = 'notificationSettings';

  const editIcon = useMemo(
    () =>
      permissions && permissions[resource][basePermissions.update]
        ? { src: iconEdit, alt: 'iconEdit', readOnly: false }
        : { src: iconView, alt: 'iconView', readOnly: true },
    [permissions, resource],
  );

  useEffect(() => {
    dispatch(actions.resetSetting());
  }, [dispatch]);

  const handleMakeDefault = async (id, isActive) => {
    if (editIcon.readOnly) {
      return;
    }
    await toggleEnabled(id, !isActive);

    const newArr = list.map(item =>
      item.id !== id ? item : { ...item, enabled: !isActive },
    );
    dispatch(actions.saveList(newArr));
  };

  const handleAddRecord = () => redirect(TICKETING_CREATE);

  const handleEditRecord = id => redirect(`${TICKETING}/${id}`);

  const handleDelete = id => {
    setIdForDelete(id);
    setDeleteDialogOpen(true);
  };

  const onCancelDeleteHandler = () => {
    setIdForDelete(null);
    setDeleteDialogOpen(false);
  };

  const onDeleteHandler = async () => {
    const result = await deleteSetting(idForDelete);
    if (!result) {
      notify('Unable to remove', 'warning');
    }
    const newArr = list.filter(({ id }) => id !== idForDelete);
    dispatch(actions.saveList(newArr));
    onCancelDeleteHandler();
  };

  const handleMakeCopy = item => {
    setClonedItem(item);
    setMakeCopyDialogOpen(true);
  };

  return (
    <div style={{ marginTop: '15px' }}>
      <MuiVirtualizedTable
        rowHeight={50}
        bulkActions={<BulkActionButtons />}
        actions={
          <ListActions
            customResource={resources.notificationSettings}
            handleAddRecord={handleAddRecord}
          />
        }
        columns={[
          {
            name: <TableLabel item={{ field: 'name', name: 'Name' }} />,
            key: 'name',
          },
          {
            name: (
              <TableDropDown
                item={{ field: 'notificationSettingSource', name: 'Source' }}
                options={notificationSources}
                labelHandler={key => notificationSources[key].name}
              />
            ),
            key: 'notificationSettingSource',
            accessor: row => (
              <StatusesAccessor
                item={row.notificationSettingSource}
                array={notificationSources}
              />
            ),
            width: 150,
          },
          {
            name: 'Notification Types',
            key: 'type',
            accessor: row => <NotificationTypes record={row} />,
          },
          {
            name: 'Enabled',
            key: 'enabled',
            accessor: row => (
              <StatusCircle handleClick={handleMakeDefault} row={row} />
            ),
          },
          {
            name: 'Actions',
            key: 'actions',
            accessor: row => (
              <div className={classes.buttonGroup}>
                <ActionButton
                  handler={() => handleEditRecord(row.id)}
                  icon={<img src={editIcon.src} alt={editIcon.alt} />}
                  toolTip="Edit"
                />
                <ActionButton
                  handler={() => handleMakeCopy(row)}
                  icon={<img src={CopyIcon} alt="Make a copy" />}
                  toolTip="Clone"
                />
                <Authorize
                  {...sanitizeProps(props)}
                  customResource={resources.notificationSettings}
                  requiredPermissions={[basePermissions.delete]}
                >
                  <ActionButton
                    handler={() => handleDelete(row.id)}
                    icon={<img src={iconDelete} alt="iconDelete" />}
                    toolTip="Delete"
                  />
                </Authorize>
              </div>
            ),
          },
        ]}
        resource={resources.notificationSettings}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onDeleteHandler}
        onCancel={onCancelDeleteHandler}
        title={translate('resources.notificationSettings.deleteTitle')}
        content={translate('resources.notificationSettings.deleteContent')}
      />
      <MakeCopyDialog
        open={makeCopyDialogOpen}
        onClose={() => setMakeCopyDialogOpen(false)}
        item={clonedItem}
      />
    </div>
  );
};

export default NotificationSettings;
