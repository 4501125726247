import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px',
    padding: '20px',
    height: '90vh',
    overflowY: 'auto',
    background: 'white',
    borderRadius: '15px',
    '& > div': {
      margin: '20px 0px',
    },
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg': {
      margin: '0px 5px',
    },
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '10px 0px',
    '& > button': {
      marginLeft: '10px',
    },
  },
  alignLeft: {
    textAlign: 'start',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: '10px',
    },
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '10px',
    '& > div': {
      width: '30%',
    },
  },
  actionField: {
    display: 'flex',
  },
}));

export default useStyles;
