import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  bagelChartTitle: {
    color: props => props.textColor || '#fff',
    borderBottom: props => (props.useLineSeparator ? '1px solid #222' : 'none'),
    display: 'flex',
    minHeight: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      marginLeft: '1rem',
    },
  },
  canvasContainer: {
    position: 'relative',
  },
  bagelChartCount: {
    cursor: 'pointer',
    color: props => props.textColor || '#fff',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '40px',
  },
}));

export default useStyles;
