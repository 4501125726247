import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import useStyles from './styles';

const FieldNameField = ({ source, record = {} }) => {
  const classes = useStyles();
  const data = record[source];
  const fields = JSON.parse(data);

  const fieldsNames = useMemo(() => fields.map(i => i.name).join(', '), []);

  if (!data) return null;
  return (
    <Grid container className={classes.root}>
      <Grid item sm={12} className={classes.nameField}>
        {fieldsNames}
      </Grid>
    </Grid>
  );
};

FieldNameField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  source: PropTypes.string.isRequired,
};

export default FieldNameField;
