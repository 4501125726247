import React from 'react';
import PropTypes from 'prop-types';
import crmSources from '@constants/crmSources';
import ZendeskForm from '@ui/components/Ticketing/Integrations/pages/CrmInfo/ZendeskForm';
import ConnectWiseForm from './ConnectWiseForm';
import AutotaskForm from './AutotaskForm';
import ServiceNowForm from './ServiceNowForm';
import KaseyaForm from './KaseyaForm';
import SyncroMspForm from './SyncroMspForm';
import HaloBaseSettings from '../HaloIntegration/HaloBaseSettings';
import SuperOpsForm from './SuperOpsForm';

const FormSelector = ({ crmType, classes, loading, useLabels }) => {
  switch (crmType) {
    case crmSources.ConnectWise.name:
      return (
        <ConnectWiseForm
          loading={loading}
          classes={classes}
          useLabels={useLabels}
        />
      );
    case crmSources.Autotask.name:
      return (
        <AutotaskForm
          loading={loading}
          classes={classes}
          useLabels={useLabels}
        />
      );
    case crmSources.ServiceNow.name:
      return (
        <ServiceNowForm
          loading={loading}
          classes={classes}
          useLabels={useLabels}
        />
      );
    case crmSources.Kaseya.name:
      return (
        <KaseyaForm loading={loading} classes={classes} useLabels={useLabels} />
      );
    case crmSources.SyncroMsp.name:
      return (
        <SyncroMspForm
          loading={loading}
          classes={classes}
          useLabels={useLabels}
        />
      );
    case crmSources.Halo.name:
      return (
        <HaloBaseSettings
          loading={loading}
          classes={classes}
          useLabels={useLabels}
        />
      );
    case crmSources.Zendesk.name:
      return (
        <ZendeskForm
          loading={loading}
          classes={classes}
          useLabels={useLabels}
        />
      );
    case crmSources.SuperOps.name:
      return (
        <SuperOpsForm
          loading={loading}
          classes={classes}
          useLabels={useLabels}
        />
      );
    default:
      return null;
  }
};

FormSelector.propTypes = {
  crmType: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  useLabels: PropTypes.bool,
};

export default FormSelector;
