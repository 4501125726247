import React, { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';

import { ThunkGetCrmSources } from '@store/slices/treeView/thunks';
import { ThunkGetHaloWebhookLink } from '@store/slices/verificationSettings/thunks';
import { requiredValidator } from '@utils/validators';
import crmSources from '@constants/crmSources';

import ReusableButton from '@common/Button/Button';
import InfoBox from '@common/InfoBox/InfoBox';
import FieldWrapper from '@common/form/FieldWrapper';
import DropDown from '@components/Auth/Common/DropDown';
import Checkbox from '@common/Checkbox';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import help from '@constants/help';
import HelpBox from '@common/HelpBox/HelpBox';
import useStyles from '../styles';

const HaloVerificationLink = () => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [psaOptions, setPsaOptions] = useState([]);
  const [link, setLink] = useState(undefined);

  const dispatch = useDispatch();
  const sharedStyles = useSharedStyles();
  const classes = useStyles();
  const notify = useNotify();

  const handleLinkClick = useCallback(() => {
    if (link) {
      window.navigator.clipboard.writeText(link).then(() => {
        notify('Copied to clipboard.', 'success');
      });
    }
  }, [link, notify]);

  const submit = values => {
    setProcessing(true);
    dispatch(ThunkGetHaloWebhookLink({ payload: values }))
      .unwrap()
      .then(res => {
        setLink(res.data);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = values => {
    return {
      psaId: requiredValidator(values.psaId),
    };
  };

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetCrmSources())
      .unwrap()
      .then(res => {
        const haloPSAs = res.filter(i => i.crmType === crmSources.Halo.name);
        setPsaOptions(haloPSAs);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Halo Verification Link
        <InfoBox text="Allow to get Halo webhook link." />
      </AccordionSummary>
      <AccordionDetails>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.formContainer}>
              <span>How to create a web hook on Halo</span>
              <HelpBox resource={help.haloCreateHook} />
              <FieldWrapper
                label="PSA"
                labelSize={12}
                contentSize={12}
                showLabel
                isRequired
                content={
                  <Field
                    name="psaId"
                    id="psaId"
                    render={DropDown}
                    options={psaOptions}
                    labelName="name"
                    valueName="id"
                    disabled={loading || processing}
                  />
                }
              />

              <div className={sharedStyles.dialogActionsContainer}>
                <ReusableButton
                  label="Get Link"
                  type="submit"
                  disabled={loading || processing}
                  loading={processing}
                />
              </div>

              {link && (
                <Tooltip title="Click to copy" onClick={handleLinkClick}>
                  <div className={classes.linkContainer}>{link}</div>
                </Tooltip>
              )}
            </form>
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default HaloVerificationLink;
