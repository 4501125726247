import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoBox from '@common/InfoBox/InfoBox';
import { ThunkGetVerificationPhone } from '@store/slices/verificationSettings/thunks';
import useThunk from '@store/slices/useThunk';
import Loading from '@common/Loading/Loading';
import ExistingPhone from '@components/Settings/VerificationSettings/components/verificationPhone/ExistingPhone';
import SelectPhone from '@components/Settings/VerificationSettings/components/verificationPhone/SelectPhone';

const VerificationPhone = () => {
  const [phone, setPhone] = useState(null);

  const [fetchVerificationPhone, isVerificationPhonePending] = useThunk(
    ThunkGetVerificationPhone,
  );

  const onMount = useCallback(() => {
    fetchVerificationPhone()
      .unwrap()
      .then(d => setPhone(d.data));
  }, [fetchVerificationPhone]);

  const content = useMemo(() => {
    if (isVerificationPhonePending) return <Loading />;
    if (phone) return <ExistingPhone phone={phone} />;
    return <SelectPhone refreshScreenHandler={onMount} />;
  }, [phone]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Verification phone number
        <InfoBox text="Select the phone number for verification process" />
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
};

export default VerificationPhone;
