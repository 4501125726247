import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  containerExpression: {
    width: '97%',
    display: 'flex',
    minHeight: '25px',
    border: '1px solid #e9e9e9',
    borderRadius: '4px',
    margin: '10px',
    boxShadow: '3px 2px 9px -4px rgba(0,0,0,0.75)',
    paddingTop: '6px',
    paddingLeft: '5px',
  },
  text: {
    display: 'flex',
    width: '87%',
  },
  icons: {
    width: '20%',
    textAlign: 'end',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
  horizontalContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0',
  },
  font: {
    fontStyle: 'normal',
  },
  subContainer: {
    width: '100%',
    marginTop: '10px',
  },
  itemValueContainer: {
    marginLeft: '10px',
    overflow: 'hidden',
  },
}));

export default useStyles;
