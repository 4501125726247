import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useListContext, useNotify } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, TextField } from '@material-ui/core';

import { currentUserDataSelector } from '@store/selectors';
import ViewAutoComplete from '@ui/components/common/ViewAutoComplete/ViewAutoComplete';

import {
  ThunkGetConnectWiseTicketBoards,
  ThunkGetConnectWiseTicketCompanies,
  ThunkGetConnectWiseTicketDepartments,
  ThunkGetConnectWiseTicketMembers,
  ThunkGetConnectWiseTicketPriorities,
  ThunkGetConnectWiseTicketStatuses,
  ThunkGetConnectWiseTicketSystemLocations,
} from '@store/slices/connectWiseTicket/thunks';
import Switch from '@common/FilterInputs/Switch';

import useStyles from '../../styles';

const localStorageItemName = 'connectWiseTableFilters';

const ConnectWiseTicketTableFilters = ({
  defaultId,
  handleDefaultFiltersChange,
}) => {
  const classes = useStyles();
  const [boardId, setBoardId] = useState({ value: 0, label: '' });
  const [companyId, setCompanyId] = useState({ value: 0, label: '' });
  const [locationId, setLocationId] = useState({ value: 0, label: '' });
  const [priorityId, setPriorityId] = useState({ value: 0, label: '' });
  const [departmentId, setDepartmentId] = useState({ value: 0, label: '' });
  const [statusId, setStatusId] = useState({ value: 0, label: '' });
  const [memberId, setMemberId] = useState({ value: 0, label: '' });
  const [showOnlyMyTickets, setShowOnlyMyTickets] = useState({ value: false });
  const [ticketDateRange, setTicketDateRange] = useState(null);
  const [ticketDateTimeFrom, setTicketDateTimeFrom] = useState('');
  const [ticketDateTimeTo, setTicketDateTimeTo] = useState('');

  const actions = {
    boardId: setBoardId,
    companyId: setCompanyId,
    locationId: setLocationId,
    priorityId: setPriorityId,
    departmentId: setDepartmentId,
    statusId: setStatusId,
    ownerId: setMemberId,
    ticketDateRange: setTicketDateRange,
  };

  const [options, setOptions] = useState({
    boardOptions: [],
    companyOptions: [],
    locationOptions: [],
    priorityOptions: [],
    departmentOptions: [],
    memberOptions: [],
  });
  const [statuses, setStatuses] = useState([]);

  const dispatch = useDispatch();
  const { setFilters, filterValues } = useListContext();
  const currentUser = useSelector(currentUserDataSelector);
  const notify = useNotify();

  const handleDateChange = (date, filterName) => {
    setFilters(
      {
        [filterName]: date,
      },
      filterValues,
      true,
    );
  };

  const handleRangeChange = (item, setter, filterName) => {
    setter(item);
    setFilters(
      {
        [filterName]: item?.value,
        CustomCreationDateUtcFrom: undefined,
        CustomCreationDateUtcTo: undefined,
      },
      filterValues,
      true,
    );

    const predefinedFilters = JSON.parse(
      localStorage.getItem(localStorageItemName),
    );

    localStorage.setItem(
      localStorageItemName,
      JSON.stringify({
        ...predefinedFilters,
        [filterName]: `${item?.value}/${item?.label}`,
      }),
    );
  };

  const handleChange = (item, setter, filterName) => {
    setter(item);
    setFilters(
      {
        [filterName]: item?.value,
      },
      filterValues,
      true,
    );

    const predefinedFilters = JSON.parse(
      localStorage.getItem(localStorageItemName),
    );

    localStorage.setItem(
      localStorageItemName,
      JSON.stringify({
        ...predefinedFilters,
        [filterName]: `${item?.value}/${item?.label}`,
      }),
    );
  };

  const handleBoardChange = async (
    item,
    setter,
    filterName,
    skipDrop = false,
  ) => {
    if (item) {
      handleChange(item, setter, filterName);

      const statusesAutocomplete = await dispatch(
        ThunkGetConnectWiseTicketStatuses({
          crmId: defaultId,
          boardId: item.value,
        }),
      ).unwrap();
      setStatuses(statusesAutocomplete);
      if (!skipDrop) {
        setStatusId({ value: 0, label: '' });
      }
    } else {
      setter({ value: 0, label: '' });
      setStatusId({ value: 0, label: '' });
      setFilters(
        {
          [filterName]: undefined,
          statusId: undefined,
        },
        filterValues,
        true,
      );

      const predefinedFilters = JSON.parse(
        localStorage.getItem(localStorageItemName),
      );

      localStorage.setItem(
        localStorageItemName,
        JSON.stringify({
          ...predefinedFilters,
          boardId: `undefined/undefined`,
          statusId: `undefined/undefined`,
        }),
      );
    }
  };

  const handleShowOnlySwitchChange = useCallback(
    item => {
      if (!item.value) {
        setFilters(
          {
            identity: '',
          },
          filterValues,
          true,
        );
      }
      if (item.value && currentUser?.cwUserLinked) {
        setFilters(
          {
            identity: `${currentUser.connectwiseLinkedUser.userId}|${currentUser.connectwiseLinkedUser.identity}`,
          },
          filterValues,
          true,
        );
        setShowOnlyMyTickets(item);
      }
      if (!currentUser.cwUserLinked) {
        notify('Connect ConnectWise user first. Go to Profile page.');
      }
    },
    [currentUser, filterValues, setFilters, notify],
  );

  const onMount = useCallback(async () => {
    await Promise.all([
      new Promise(res =>
        res(dispatch(ThunkGetConnectWiseTicketBoards(defaultId)).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetConnectWiseTicketCompanies(defaultId)).unwrap()),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetConnectWiseTicketSystemLocations(defaultId),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetConnectWiseTicketPriorities(defaultId)).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetConnectWiseTicketDepartments(defaultId)).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetConnectWiseTicketMembers(defaultId)).unwrap()),
      ),
    ]).then(res => {
      const responseOptions = {
        boardOptions: res[0],
        companyOptions: res[1],
        locationOptions: res[2],
        priorityOptions: res[3],
        departmentOptions: res[4],
        memberOptions: res[5],
      };

      setOptions({ ...options, ...responseOptions });
    });
  }, [dispatch, defaultId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useLayoutEffect(() => {
    // predefining filters on load
    const predefinedFilters = JSON.parse(
      localStorage.getItem(localStorageItemName),
    );

    if (predefinedFilters) {
      const keys = Object.keys(predefinedFilters);
      const filters = {};
      keys.forEach(item => {
        if (predefinedFilters[item]) {
          const [value, label] = predefinedFilters[item]?.split('/');
          if (value && value !== 'undefined') {
            actions[item]({ value, label });
            filters[item] = value;

            if (item === 'boardId') {
              handleBoardChange({ value, label }, setBoardId, 'boardId', true);
            }
          }
        }
      });
      handleDefaultFiltersChange(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.filtersContainer}>
      <ViewAutoComplete
        options={options.boardOptions}
        onChange={item => {
          handleBoardChange(item, setBoardId, 'boardId');
        }}
        currentView={boardId}
        textFieldLabel="Service Boards"
      />

      <ViewAutoComplete
        options={statuses}
        onChange={item => {
          handleChange(item, setStatusId, 'statusId');
        }}
        currentView={statusId}
        textFieldLabel="Statuses"
        disabled={+boardId.value === 0}
      />

      <ViewAutoComplete
        options={options.companyOptions}
        onChange={item => {
          handleChange(item, setCompanyId, 'companyId');
        }}
        currentView={companyId}
        textFieldLabel="Company"
      />

      <ViewAutoComplete
        options={options.locationOptions}
        onChange={item => {
          handleChange(item, setLocationId, 'locationId');
        }}
        currentView={locationId}
        textFieldLabel="Location"
      />

      <ViewAutoComplete
        options={options.priorityOptions}
        onChange={item => {
          handleChange(item, setPriorityId, 'priorityId');
        }}
        currentView={priorityId}
        textFieldLabel="Priority"
      />

      <ViewAutoComplete
        options={options.departmentOptions}
        onChange={item => {
          handleChange(item, setDepartmentId, 'departmentId');
        }}
        currentView={departmentId}
        textFieldLabel="Department"
      />

      <ViewAutoComplete
        options={options.memberOptions}
        onChange={item => {
          handleChange(item, setMemberId, 'ownerId');
        }}
        currentView={memberId}
        textFieldLabel="Owners"
      />

      <FormControlLabel
        style={{ alignItems: 'flex-end' }}
        control={
          <Switch
            input={{
              onChange: () => {
                setShowOnlyMyTickets(!showOnlyMyTickets.value);
                handleShowOnlySwitchChange(
                  { value: !showOnlyMyTickets.value },
                  setShowOnlyMyTickets,
                  'skipCompleted',
                );
              },
              value: showOnlyMyTickets.value,
            }}
            checked={showOnlyMyTickets.value}
          />
        }
        label="My Tickets"
        labelPlacement="start"
      />

      <ViewAutoComplete
        options={[
          { value: undefined, label: '-' },
          { value: 'Today', label: 'Today' },
          { value: 'ThisWeek', label: 'This Week' },
          { value: 'LastWeek', label: 'Last Week' },
          { value: 'ThisMonth', label: 'This Month' },
          { value: 'LastMonth', label: 'Last Month' },
          { value: 'Custom', label: 'Custom' },
        ]}
        onChange={item => {
          handleRangeChange(item, setTicketDateRange, 'ticketDateRange');
        }}
        currentView={ticketDateRange}
        textFieldLabel="Date"
      />
      {ticketDateRange?.value === 'Custom' && (
        <>
          <TextField
            type="date"
            variant="outlined"
            className={classes.datePicketInput}
            value={ticketDateTimeFrom}
            onChange={e => {
              setTicketDateTimeFrom(e.target.value);

              handleDateChange(
                new Date(e.target.value).toUTCString(),
                'CustomCreationDateUtcFrom',
              );
            }}
          />
          <TextField
            type="date"
            variant="outlined"
            className={classes.datePicketInput}
            value={ticketDateTimeTo}
            onChange={e => {
              setTicketDateTimeTo(e.target.value);

              handleDateChange(
                new Date(e.target.value).toUTCString(),
                'CustomCreationDateUtcTo',
              );
            }}
          />
        </>
      )}
    </div>
  );
};

ConnectWiseTicketTableFilters.propTypes = {
  defaultId: PropTypes.number.isRequired,
  handleDefaultFiltersChange: PropTypes.func,
};

export default ConnectWiseTicketTableFilters;
