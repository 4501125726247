import React from 'react';

import getFormattedDate from '@utils/getFormattedDate';

import BooleanAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanAccessor';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

import RefreshIcon from '@assets/icons/refresh_circle.svg';
import DeleteIcon from '@assets/icons/delete.svg';
import TableDropDown from '../common/TableDropDown/TableDropDown';

const isRegisteredOptions = {
  true: { name: 'Yes', value: 'true' },
  false: { name: 'No', value: 'false' },
};

const valueHandler = key => isRegisteredOptions[key]?.value;

const columns = [
  {
    name: 'Email',
    key: 'email',
  },
  {
    name: 'Invite Date',
    key: 'inviteDateTime',
    accessor: rowData => <>{getFormattedDate(rowData.inviteDateTime)}</>,
  },
  {
    name: (
      <TableDropDown
        item={{ name: 'Is Registered', field: 'isRegistered' }}
        options={isRegisteredOptions}
        valueHandler={valueHandler}
      />
    ),
    key: 'isRegistered',
    accessor: rowData => (
      <BooleanAccessor value={rowData.isRegistered} useMultiColor />
    ),
  },
  {
    name: 'Registration Date',
    key: 'registrationDateTime',
    accessor: rowData => <>{getFormattedDate(rowData.registrationDateTime)}</>,
  },
];

export const getColumns = (
  handleReInviteDialogOpen,
  handleDeleteDialogOpen,
  allowEdit,
) =>
  allowEdit
    ? columns.concat([
        {
          name: 'Actions',
          key: 'actions',
          accessor: rowData => (
            <>
              {!rowData.isRegistered && (
                <ActionFieldItem
                  icon={RefreshIcon}
                  toolTip="Re-invite User"
                  handler={() => {
                    handleReInviteDialogOpen(rowData.email);
                  }}
                />
              )}
              <ActionFieldItem
                icon={DeleteIcon}
                toolTip="Delete invite"
                handler={() => {
                  handleDeleteDialogOpen(rowData.id);
                }}
              />
            </>
          ),
        },
      ])
    : columns;

export default {};
