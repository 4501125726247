import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tableWrapper: {
    marginTop: '4rem',
  },
  buttonWrapper: {
    margin: '0 0 5px 25px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  monoSpace: {
    fontFamily: 'monospace',
  },
  textCenter: {
    textAlign: 'center',
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: '0.25rem',
  },
  marginTop: {
    marginTop: '2rem',
  },
}));

export default useStyles;
