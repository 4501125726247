import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Field } from 'react-final-form';
import Input from '@ui/components/common/Input/Input';
import { useParams } from 'react-router-dom';
import HaloPodLink from '@components/Ticketing/Integrations/pages/CrmInfo/components/HaloPodLink';

const HaloBaseSettings = ({ classes, loading, useLabels }) => {
  const { id } = useParams();
  return (
    <>
      {useLabels && (
        <Typography className={classes.inputLabel}>Client ID:</Typography>
      )}
      <Field
        id="publicKey"
        name="publicKey"
        styleType="main"
        fullWidth
        inputView="text"
        type="text"
        component={Input}
        classNameWrapper={classes.inputWrapper}
        placeholder="Client ID"
        disabled={loading}
      />
      {useLabels && (
        <Typography className={classes.inputLabel}>Secret:</Typography>
      )}
      <Field
        id="privateKey"
        name="privateKey"
        styleType="main"
        fullWidth
        inputView="text"
        type="password"
        component={Input}
        classNameWrapper={classes.inputWrapper}
        placeholder="Secret"
        disabled={loading}
      />
      {useLabels && (
        <Typography className={classes.inputLabel}>Resource Server:</Typography>
      )}
      <Field
        id="uri"
        name="uri"
        styleType="main"
        fullWidth
        inputView="text"
        type="text"
        component={Input}
        classNameWrapper={classes.inputWrapper}
        placeholder="Resource Server"
        disabled={loading}
      />
      {!!id && <HaloPodLink crmId={id} />}
    </>
  );
};

HaloBaseSettings.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
  useLabels: PropTypes.bool,
};

export default HaloBaseSettings;
