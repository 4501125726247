import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

import ReusableButton from '@common/Button/Button';
import OptInForm from '@components/Messaging/MessagingTemplates/components/OptInForm';
import TemplateDialog from '@components/Messaging/MessagingTemplates/dialogs/TemplateDialog';
import VerificationSettings from '@components/Settings/VerificationSettings/VerificationSettings';

const useStyles = makeStyles(() => ({
  buttonsRegion: {
    marginRight: '0.5rem',
    '& button:nth-child(odd)': {
      marginRight: '0.1rem',
    },
  },
}));

const TemplateActions = () => {
  const classes = useStyles();

  const [verDialogOpen, setVerDialogOpen] = useState(false);
  const [optInDialogOpen, setOptInDialogOpen] = useState(false);

  return (
    <div className={classes.buttonsRegion}>
      <ReusableButton
        size="md"
        textSize="mini"
        label="Opt-in template"
        onClick={() => setOptInDialogOpen(true)}
      />
      <ReusableButton
        size="md"
        textSize="mini"
        label="Verification template"
        onClick={() => setVerDialogOpen(true)}
      />
      <TemplateDialog
        open={verDialogOpen}
        setOpen={setVerDialogOpen}
        content={
          <VerificationSettings
            onCancel={() => setVerDialogOpen(false)}
            skipBorders
          />
        }
        title="Verification process template form"
      />
      <TemplateDialog
        open={optInDialogOpen}
        setOpen={setOptInDialogOpen}
        content={
          <OptInForm
            onSubmit={() => setOptInDialogOpen(false)}
            onCancel={() => setOptInDialogOpen(false)}
          />
        }
        title="Opt-in process template form"
      />
    </div>
  );
};

export default TemplateActions;
