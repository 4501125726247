import { actionTypes } from '../actions';

const defaultState = {
  list: [],
  isRemovedRowElement: null,
};

const listReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_LIST:
      if (action.payload.key) {
        return {
          list: {
            ...state.list,
            [action.payload.key]: action.payload.data,
          },
        };
      }
      return { list: action.payload };
    case actionTypes.PATCH_LIST_ITEM: {
      const { id, field, value } = action.payload;
      const index = state.list.findIndex(
        e => e.id.toString() === id.toString(),
      );
      if (index !== -1) {
        const item = { ...state.list[index] };
        item[field] = value;
        const newList = [...state.list];
        newList[index] = item;
        return { list: newList };
      }
      return state;
    }
    case actionTypes.ADD_OR_UPDATE_ITEM_IN_LIST: {
      const index = state.list.findIndex(
        e => e.id.toString() === action.payload.id.toString(),
      );
      const newList = [...state.list];
      if (index !== -1) {
        newList.splice(index, 1, action.payload);
      } else {
        newList.unshift(action.payload);
      }
      return { list: newList };
    }
    case actionTypes.REMOVE_ITEM_FROM_LIST: {
      if (action.payload.key) {
        return {
          list: {
            ...state.list,
            [action.payload.key]: state.list[action.payload.key].filter(
              e => e.id !== action.payload.id,
            ),
            isRemovedRowElement: !state.isRemovedRowElement,
          },
        };
      }
      const filteredList = state.list.filter(e => e.id !== action.payload.id);
      return {
        list: filteredList,
        isRemovedRowElement: !state.isRemovedRowElement,
      };
    }
    default:
      return state;
  }
};

export default listReducer;
