import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px',
    height: '83vh',
  },
  dialogContent: {
    padding: '20px',
  },
  link: {
    color: '#2853ff',
    cursor: 'pointer',
    '&:hover': {
      color: '#001597',
    },
  },
  cell: {
    padding: '5px',
  },
  codeContainer: {
    color: 'white',
    margin: '15px 0px',
    display: 'flex',
    padding: '10px',
    background: '#1b3670',
    fontWeight: 800,
    justifyContent: 'center',
    fontSize: 'large',
  },
  codeConfirm: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0px',
    fontWeight: 700,
  },
  ticketsContainer: {
    maxHeight: '350px',
    overflowY: 'auto',
    margin: '20px 0 20px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    '@media (max-height: 767px)': {
      maxHeight: '150px !important',
    },
  },
}));

export default useStyles;
