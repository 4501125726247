import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useListContext } from 'ra-core';
import Grid from '@material-ui/core/Grid';
import ViewAutoComplete from '@common/ViewAutoComplete/ViewAutoComplete';
import useAsyncDispatch from '@services/api/common/useAsyncDispatch';
import { actions } from '@store/actions';
import { smsInfo, tenantOptions } from '@store/selectors/smsList';
import moment from 'moment';
import { defaultDateFormat } from '@constants/formats';
import { getInfo, getTenants } from './helpers';
import useStyles from './styles';

const getDate = value =>
  value
    ? moment
        .utc(value)
        .local()
        .format(defaultDateFormat)
    : '';

const statisticArray = [
  {
    key: 1,
    label: 'Subscription starts at:',
    field: 'subscriptionStart',
    transformToDate: true,
  },
  { key: 2, label: 'Total sent:', field: 'usageTotal' },
  { key: 3, label: 'Successfully delivered:', field: 'usageSuccess' },
  { key: 4, label: 'Subscription quota:', field: 'quota' },
];

const TenantPanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const options = useSelector(tenantOptions);
  const info = useSelector(smsInfo);
  const loadData = useAsyncDispatch(getTenants, actions.smsList_setOptions);
  const { setFilters, filterValues } = useListContext();

  useEffect(() => {
    if (value) {
      setLoading(true);
      getInfo(value.id)
        .then(d => dispatch(actions.smsList_setInfo(d)))
        .catch(e => notify(e.message, 'error'))
        .finally(() => setLoading(false));
    }
  }, [dispatch, notify, value]);

  const handleChange = item => {
    setValue(item);
    setFilters({ tenantId: item?.id }, filterValues, true);
  };

  return (
    <div className={cx(classes.border, classes.displayFlex)}>
      <Grid container justify="space-between" wrap="nowrap">
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4} lg={3} xl={2}>
            <ViewAutoComplete
              options={options}
              onChange={handleChange}
              currentView={value}
              optionLabel="value"
              textFieldLabel="Tenant"
              loading={loadData}
            />
          </Grid>
          {value && !loading && (
            <div className={classes.displayFlex}>
              {statisticArray.map(i => (
                <div className={classes.marginEnd} key={i.key}>
                  <span className={cx(classes.marginEnd, classes.bold)}>
                    {i.label}
                  </span>
                  <span>
                    {i.transformToDate ? getDate(info[i.field]) : info[i.field]}
                  </span>
                  <span>;</span>
                </div>
              ))}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default TenantPanel;
