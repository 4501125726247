import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { SelectDataView, SelectValues } from './steps';
import useStyles from '../styles';

const steps = ['Select DataView', 'Select items'];

const ImportDataViewNamesWizard = ({ onSelect, open, setOpen }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [currentView, setCurrentView] = useState({ label: '', value: 0 });

  useEffect(() => {
    if (open) setActiveStep(0);
  }, [open]);

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const onSelectDataView = dw => {
    setCurrentView(dw);
    if (dw) setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <SelectDataView
            currentView={currentView}
            setCurrentView={onSelectDataView}
          />
        );
      case 1:
        return (
          <SelectValues
            handleBack={handleBack}
            handleSubmit={onSelect}
            currentView={currentView}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className={classes.wizardModalBox}>
        <div className={classes.stepperBody}>
          <Stepper
            activeStep={activeStep}
            classes={{ root: classes.stepperRoot }}
          >
            {steps.map(label => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {getStepContent(activeStep)}
        </div>
      </div>
    </Modal>
  );
};

ImportDataViewNamesWizard.propTypes = {
  onSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ImportDataViewNamesWizard;
