import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';

import MuiVirtualizedTable from '@common/MuiVirtualizedTable';
import DateTimeField from '@common/TableComponents/DateTimeField';
import { actions } from '@store/actions';
import UsageField from '@components/Admin/Subscriptions/UsageField';
import BooleanAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanAccessor';
import useTranslation from '@common/hooks/useTranslation';

import TableLabel from '@common/TableLabel/TableLabel';
import TableDropDown from '@common/TableDropDown/TableDropDown';
import TableDateLabel from '@common/TableLabel/TableDateLabel';
import useTableDateLabel from '@common/hooks/useTableDateLabel';
import TableDatePicker from '@common/TableDatePicker/TableDatePicker';
import ActionField from './ActionField';
import { pauseSubscription, syncWithStripe } from './helpers';
import useStyles from '../styles';

export const isActiveOptions = {
  true: { name: 'true', value: 'true' },
  false: { name: 'false', value: 'false' },
};

const valueHandler = key => isActiveOptions[key]?.value;

const List = props => {
  const { resource } = props;
  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const { getLabel } = useTranslation(resource);

  const {
    date: dateTime,
    isOpen: isOpenDateTime,
    setIsOpen: setIsOpenDateTime,
    dateDispatcher: dateTimeDispatcher,
  } = useTableDateLabel();

  const handleToggleStateClick = useCallback(
    item => {
      pauseSubscription(item.stripeId)
        .then(resp => {
          dispatch(actions.updateItemInList(resp));
        })
        .catch(e => notify(e.message, 'error'));
    },
    [dispatch, notify],
  );

  const handleSyncClick = useCallback(
    item => {
      syncWithStripe(item.stripeId)
        .then(resp => {
          dispatch(actions.updateItemInList(resp));
        })
        .catch(e => notify(e.message, 'error'));
    },
    [dispatch, notify],
  );

  const columns = useMemo(
    () => [
      {
        name: (
          <TableLabel
            item={{ name: getLabel('tenantName'), field: 'tenantName' }}
          />
        ),
        key: 'tenantName',
        width: 180,
      },
      {
        name: (
          <TableLabel
            item={{ name: getLabel('tenantAdmin'), field: 'tenantAdmin' }}
          />
        ),
        key: 'tenantAdmin',
        width: 180,
      },
      {
        name: (
          <TableDropDown
            item={{ name: getLabel('active'), field: 'active' }}
            options={isActiveOptions}
            valueHandler={valueHandler}
          />
        ),
        key: 'active',
        accessor: rowData => (
          <BooleanAccessor value={rowData.active} useMultiColor />
        ),
        width: 70,
      },
      {
        name: (
          <TableDropDown
            item={{ name: getLabel('isDemo'), field: 'isDemoSubscription' }}
            options={isActiveOptions}
            valueHandler={valueHandler}
          />
        ),
        key: 'isDemoSubscription',
        accessor: rowData => (
          <BooleanAccessor value={rowData.isDemoSubscription} useMultiColor />
        ),
        width: 70,
      },
      {
        name: getLabel('status'),
        key: 'status',
        sort: 'status',
        width: 130,
      },
      {
        name: getLabel('name'),
        key: 'planId',
        sort: 'planId',
        minWidth: 130,
      },
      {
        name: getLabel('email'),
        key: 'emailUsage',
        accessor: rowData => (
          <UsageField
            record={rowData}
            quota="monthlyQuota"
            usage="emailUsage"
          />
        ),
        width: 70,
      },
      {
        name: getLabel('sms'),
        key: 'smsUsage',
        accessor: rowData => (
          <UsageField
            record={rowData}
            quota="monthlySmsQuota"
            usage="smsUsage"
          />
        ),
        width: 70,
      },
      {
        name: getLabel('devices'),
        key: 'devicesUsage',
        accessor: rowData => (
          <UsageField
            record={rowData}
            quota="devicesQuota"
            usage="devicesUsage"
          />
        ),
        width: 70,
      },

      {
        name: getLabel('stripeId'),
        key: 'stripeId',
        width: 165,
      },
      {
        name: (
          <TableDateLabel
            item={{ field: 'nextPayment', name: 'Next Payment' }}
            date={dateTime}
            setIsOpen={setIsOpenDateTime}
          />
        ),
        key: 'nextPayment',
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="nextPayment"
            source="nextPayment"
            label={getLabel('nextPayment')}
            sortable
          />
        ),
        width: 165,
      },
      {
        name: getLabel('lastSyncDate'),
        key: 'lastSyncDate',
        sort: 'lastSyncDate',
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="lastSyncDate"
            source="lastSyncDate"
            label={getLabel('lastSyncDate')}
            sortable
          />
        ),
        width: 150,
      },
      {
        name: 'Actions',
        key: 'actions',
        accessor: rowData => (
          <ActionField
            label="Actions"
            record={rowData}
            handleToggleStateClick={handleToggleStateClick}
            handleSyncClick={handleSyncClick}
          />
        ),
        width: 100,
      },
    ],
    [
      classes.activeFieldContainer,
      getLabel,
      handleSyncClick,
      handleToggleStateClick,
    ],
  );

  return (
    <MuiVirtualizedTable
      classNameWrapper={classes.tableWrapper}
      actions={null}
      columns={columns}
      resource={resource}
    >
      <TableDatePicker
        date={dateTime}
        dateDispatcher={dateTimeDispatcher}
        setIsOpen={setIsOpenDateTime}
        isOpen={isOpenDateTime}
        field="nextPayment"
      />
    </MuiVirtualizedTable>
  );
};

List.propTypes = {
  resource: PropTypes.string,
};

export default List;
