import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';
import ReusableButton from '@common/Button/Button';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import ActionField from '@common/ActionFields/ActionField';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import copyText from '@utils/copy';
import { actions } from '@store/actions';
import CreateDialog from './CreateDialog';
import useTranslation from '../../common/hooks/useTranslation';
import useStyles from './styles';

const getFullLink = key =>
  `${window.location.origin}/#/demoRegistration?auth-key=${key}`;

const DemoReferralLinks = ({ resource }) => {
  const classes = useStyles();
  const { getLabel, getText } = useTranslation(resource);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const onCopy = key => {
    copyText(getFullLink(key));
    notify('resources.apiKeys.dialogs.copied');
  };

  const onDelete = record => {
    setIdForDelete(record.id);
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setIdForDelete(null);
    setDeleteDialogOpen(false);
    setLoading(false);
  };

  const handleDelete = () => {
    setLoading(true);
    dataProvider
      .delete(resource, { id: idForDelete })
      .then(() => {
        dispatch(actions.removeItemFromList({ id: idForDelete }));
      })
      .catch(e => notify(e.message, 'error'))
      .finally(handleCancelDelete);
  };

  const handleAddKey = model => {
    setCreateDialogOpen(false);
    dispatch(actions.updateItemInList(model));
  };

  return (
    <div className={classes.tableWrapper}>
      <ReusableButton
        size="md"
        viewType="black"
        label="common.add"
        onClick={() => setCreateDialogOpen(true)}
        classNameWrapper={classes.buttonWrapper}
        disabled={loading}
      />
      <MuiVirtualizedTable
        actions={null}
        columns={[
          {
            name: getLabel('name'),
            key: 'name',
          },
          {
            name: getLabel('link'),
            key: 'link',
            accessor: rowData => (
              <>
                <div className={classes.container}>
                  <span className={classes.monoSpace}>
                    {getFullLink(rowData.link)}
                  </span>
                </div>
              </>
            ),
          },
          {
            name: getLabel('consumer'),
            key: 'consumer',
          },
          {
            name: 'Actions',
            key: 'actions',
            accessor: rowData => (
              <ActionField
                label="Actions"
                record={rowData}
                onCopy={onCopy}
                onDelete={onDelete}
                fieldKey="link"
              />
            ),
            width: 200,
          },
        ]}
        resource={resource}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDelete}
        onCancel={handleCancelDelete}
        title={getText('caption')}
        content={getText('text')}
        loading={loading}
      />
      <CreateDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onCreate={handleAddKey}
      />
    </div>
  );
};

DemoReferralLinks.propTypes = {
  resource: PropTypes.string,
};

export default DemoReferralLinks;
