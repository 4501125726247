import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  CRM_TICKET_EDIT,
  CRM_TICKET_SCHEDULE_ENTRIES,
  CRM_TICKET_TIME_ENTRIES,
} from '@constants/routes';
import crmSources from '@constants/crmSources';

import AddCWTicketTimeEntryDialog from '@ui/components/CrmTicket/dialogs/AddCWTimeEntry';
import AddScheduleEntryDialog from '@ui/components/CrmTicket/dialogs/AddScheduleEntry';
import ItemMenu from '@ui/components/common/ItemMenu/ItemMenu';
import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import EditIcon from '@assets/icons/edit.svg';
import CalendarIcon from '@assets/icons/calendar_green.svg';
import CalendarPlus from '@assets/icons/calendar_plus.svg';
import AuditIcon from '@assets/icons/audit.svg';
import AuditPlus from '@assets/icons/audit_plus.svg';
import UserIcon from '@assets/icons/user.svg';
import SMSIcon from '@assets/icons/sms.svg';
import ShieldPersonIcon from '@assets/icons/shield_person.svg';
import SyncIcon from '@assets/icons/secureDataSend.svg';
import PodDialog from '@components/PodDialog';
import AssignContactDialog from '../../dialogs/AssignContactDialog';
import CreateMessagingChannelDialog from '../../../Messaging/dialogs/CreateMessagingChannelDialog';
import AssignMemberDialog from '../../dialogs/AssignMemberDialog';

import useStyles from '../../styles';

const ConnectWiseTicketTableActions = ({ record, crmDefaultId, crmId }) => {
  const [openAddTimeEntryDialog, setOpenAddTimeEntryDialog] = useState(false);
  const [openAddScheduleEntryDialog, setOpenAddScheduleEntryDialog] = useState(
    false,
  );
  const [openAssignContactDialog, setOpenAssignContactDialog] = useState(false);
  const [openCreateChannelDialog, setOpenCreateChannelDialog] = useState(false);
  const [openAssignMemberDialog, setOpenAssignMemberDialog] = useState(false);
  const [openPodDialog, setOpenPodDialog] = useState(false);
  const [openSecureDialog, setOpenSecureDialog] = useState(false);

  const classes = useStyles();
  const { push } = useHistory();

  const handleEditRedirect = () => {
    push(
      CRM_TICKET_EDIT.replace(':crmDefaultId', crmDefaultId)
        .replace(':ticketId', record.id)
        .replace(':crmName', crmSources.ConnectWise.label),
    );
  };

  const handleTimeEntriesRedirect = useCallback(() => {
    push(
      CRM_TICKET_TIME_ENTRIES.replace(':crmDefaultId', crmDefaultId).replace(
        ':ticketId',
        record.id,
      ),
    );
  }, [crmDefaultId, push, record.id]);

  const handleScheduleEntriesRedirect = useCallback(() => {
    push(
      CRM_TICKET_SCHEDULE_ENTRIES.replace(
        ':crmDefaultId',
        crmDefaultId,
      ).replace(':ticketId', record.id),
    );
  }, [crmDefaultId, push, record.id]);

  const handleAssignContactClick = useCallback(() => {
    setOpenAssignContactDialog(true);
    setOpenCreateChannelDialog(false);
  }, []);

  const menuOptions = useMemo(
    () => [
      {
        icon: <img src={CalendarIcon} alt="timeEntries" />,
        caption: 'Time Entries',
        handler: handleTimeEntriesRedirect,
      },
      {
        icon: <img src={CalendarPlus} alt="addTimeEntries" />,
        caption: 'Add Time Entry',
        handler: () => {
          setOpenAddTimeEntryDialog(true);
        },
      },
      {
        icon: <img src={AuditIcon} alt="scheduleEntries" />,
        caption: 'Schedule Entries',
        handler: handleScheduleEntriesRedirect,
      },
      {
        icon: <img src={AuditPlus} alt="addScheduleEntry" />,
        caption: 'Add Schedule Entry',
        handler: () => {
          setOpenAddScheduleEntryDialog(true);
        },
      },
      {
        icon: <img src={UserIcon} alt="assignContact" />,
        caption: 'Assign Contact',
        handler: () => {
          setOpenAssignContactDialog(true);
        },
      },
      {
        icon: <img src={SMSIcon} alt="createChannel" />,
        caption: 'Add SMS Channel',
        handler: () => {
          setOpenCreateChannelDialog(true);
        },
      },
      {
        icon: <img src={UserIcon} alt="assignMember" />,
        caption: 'Assign Member',
        handler: () => {
          setOpenAssignMemberDialog(true);
        },
      },
    ],
    [handleScheduleEntriesRedirect, handleTimeEntriesRedirect],
  );

  return (
    <div className={classes.tableActions}>
      {record?.contactId && (
        <>
          <ActionFieldItem
            alt="Open POD"
            handler={() => {
              setOpenPodDialog(true);
            }}
            toolTip="Open POD"
            icon={ShieldPersonIcon}
            ignoreDefaultIconSize
          />
          <ActionFieldItem
            alt="Secure Data"
            handler={() => setOpenSecureDialog(true)}
            toolTip="Secure data"
            icon={SyncIcon}
            ignoreDefaultIconSize
          />
        </>
      )}

      <ActionFieldItem
        alt="Edit"
        handler={handleEditRedirect}
        toolTip="Edit"
        icon={EditIcon}
      />
      <ItemMenu options={menuOptions} itemsHeightMultiplier={10} openOnHover />

      {openAddTimeEntryDialog && (
        <AddCWTicketTimeEntryDialog
          open={openAddTimeEntryDialog}
          setOpen={setOpenAddTimeEntryDialog}
          crmDefaultId={crmDefaultId}
          ticketId={record.id}
        />
      )}

      {openAddScheduleEntryDialog && (
        <AddScheduleEntryDialog
          open={openAddScheduleEntryDialog}
          setOpen={setOpenAddScheduleEntryDialog}
          crmDefaultId={crmDefaultId}
          ticketId={record.id}
        />
      )}

      {openAssignContactDialog && (
        <AssignContactDialog
          open={openAssignContactDialog}
          setOpen={setOpenAssignContactDialog}
          record={record}
          crmDefaultId={crmDefaultId}
          crmName={crmSources.ConnectWise.label}
        />
      )}

      {openAssignMemberDialog && (
        <AssignMemberDialog
          open={openAssignMemberDialog}
          setOpen={setOpenAssignMemberDialog}
          record={record}
          crmDefaultId={crmDefaultId}
        />
      )}

      {openCreateChannelDialog && (
        <CreateMessagingChannelDialog
          open={openCreateChannelDialog}
          setOpen={setOpenCreateChannelDialog}
          record={record}
          crmDefaultId={crmDefaultId}
          crmType={crmSources.ConnectWise.name}
          handleAssignContactClick={handleAssignContactClick}
        />
      )}

      {openPodDialog && (
        <PodDialog
          onClose={() => {
            setOpenPodDialog(false);
          }}
          psaId={crmId}
          itemId={+record.id}
          itemType="ticket"
          page="verification"
          psa={crmSources.ConnectWise.label}
        />
      )}

      {openSecureDialog && (
        <PodDialog
          onClose={() => {
            setOpenSecureDialog(false);
          }}
          psaId={crmId}
          itemId={+record.id}
          itemType="ticket"
          page="secureData"
          psa={crmSources.ConnectWise.label}
        />
      )}
    </div>
  );
};

ConnectWiseTicketTableActions.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
  }),
  crmDefaultId: PropTypes.number,
};
export default ConnectWiseTicketTableActions;
