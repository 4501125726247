import React from 'react';
import crmSources from '@constants/crmSources';

import HaloTicketTable from '@components/PsaTickets/components/Halo/HaloTicketTable';
import AutotaskContacts from '@components/PsaContacts/components/integrations/autotask/AutotaskContacts';
import ZendeskContacts from '@components/PsaContacts/components/integrations/zendesk/ZendeskContacts';
import SuperOpsContacts from '@components/PsaContacts/components/integrations/superOps/SuperOpsContacts';
import PsaContactsTableBulkActions from './components/PsaContactsTableBulkActions';

export default {};

export const getTable = (psaName, psaId, resource) => {
  switch (psaName) {
    case crmSources.Autotask.name:
      return (
        <AutotaskContacts psaId={psaId} psaName={psaName} resource={resource} />
      );
    case crmSources.Halo.name:
      return (
        <>
          <HaloTicketTable
            crmId={psaId}
            resource={resource}
            defaultSortName="name"
            tableType="contacts"
            bulkActions={
              <PsaContactsTableBulkActions psaId={psaId} psaName={psaName} />
            }
          />
        </>
      );
    case crmSources.Zendesk.name:
      return (
        <ZendeskContacts psaId={psaId} psaName={psaName} resource={resource} />
      );
    case crmSources.SuperOps.name:
      return (
        <SuperOpsContacts psaId={psaId} psaName={psaName} resource={resource} />
      );
    default:
      return <></>;
  }
};
