import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '16px 16px 0 11px',
    '& tbody': {
      maxHeight: '74vh',
    },
  },
  dropDownContainer: {
    width: '25%',
    marginBottom: '16px',
  },
  filtersContainer: {
    margin: '10px 0px',
    '& > div': {
      margin: '5px 0px',
    },
  },
  link: {
    color: 'blue',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tableActions: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    '& > span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  },
  form: {
    width: '350px',
  },
  formActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px 0px',
    '& > button': {
      marginLeft: '10px',
    },
  },
  alignLeft: {
    textAlign: 'start',
  },
  datePicketInput: {
    '& input': {
      padding: '10px',
    },
  },
  noUserText: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Montserrat',
  },
  tableWrapper: {
    margin: '10px 10px 0 0',
  },
}));

export default useStyles;
