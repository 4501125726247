import React, { useCallback, useState } from 'react';
import Loading from '@common/Loading/Loading';
import useAsync from '@services/api/common/useAsync';
import { getSettingById } from './helpers';
import NotificationSettingsForm from './Form/NotificationSettingsForm';
import useStyles from './styles';

const UpdateNotificationSettings = ({ match }) => {
  const [data, setData] = useState(null);
  const getData = useCallback(() => getSettingById(match.params.id), [match]);
  const loading = useAsync(getData, setData);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {loading ? <Loading /> : <NotificationSettingsForm data={data} />}
    </div>
  );
};

export default UpdateNotificationSettings;
