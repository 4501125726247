import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';

import DeleteIcon from '@assets/icons/delete.svg';
import EditIcon from '@assets/icons/edit.svg';

import { ThunkGetCrmSources } from '@store/slices/treeView/thunks';
import { CrmSourcesSelector } from '@store/slices/treeView/selectors';
import { PSACompaniesSelector } from '@store/slices/createTicket';
import {
  ThunkDeleteCompaniesVerificationSettings,
  ThunkGetCompaniesVerificationSettings,
} from '@store/slices/verificationSettings/thunks';

import psaSources from '@constants/crmSources';

import ReusableButton from '@common/Button/Button';
import Loading from '@common/Loading/Loading';
import DropDown from '@components/Auth/Common/DropDown';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';

import { getCompaniesThunk } from './helpers';
import useStyles from './styles';
import CompanyVerificationSettingsDialog from './components/CompanyVerificationSettingsDialog';

const CompaniesVerificationSettings = () => {
  const [loading, setLoading] = useState(false);
  const [selectedPsa, setSelectedPsa] = useState(null);
  const [settings, setSettings] = useState([]);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [
    showCompanyVerificationDialog,
    setShowCompanyVerificationDialog,
  ] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const crmSources = useSelector(CrmSourcesSelector);
  const companiesOptions = useSelector(PSACompaniesSelector);

  const onePsaMode = useMemo(() => crmSources?.length === 1 ?? false, [
    crmSources?.length,
  ]);

  useEffect(() => {
    dispatch(ThunkGetCrmSources());
  }, []);

  const getCompaniesSettings = () => {
    setLoading(true);
    setSettings([]);
    const psa = crmSources.find(i => i.id === selectedPsa.id);
    const getCompanies = getCompaniesThunk(psa.crmType);
    dispatch(
      psa.crmType === psaSources.Halo.name
        ? getCompanies(psa.id)
        : getCompanies({ psaId: psa.id }),
    )
      .unwrap()
      .then(companies => {
        dispatch(
          ThunkGetCompaniesVerificationSettings({ psaId: selectedPsa.id }),
        )
          .unwrap()
          .then(res => {
            const values = res.map(i => ({
              ...i,
              name: companies.find(p => p.value === i.companyId)?.label,
            }));
            setSettings(values);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedPsa) {
      getCompaniesSettings();
    }
  }, [dispatch, selectedPsa]);

  const handleItemDelete = useCallback(
    item => {
      if (item.isNew) {
        setSettings(settings.filter(i => i.id !== item.id));
      } else {
        setLoading(true);
        dispatch(ThunkDeleteCompaniesVerificationSettings(item.id))
          .unwrap()
          .then(() => {
            setSettings(settings.filter(i => i.id !== item.id));
          })
          .finally(() => {
            setLoading(false);
            setItemToEdit(null);
          });
      }
    },
    [dispatch, selectedPsa, settings],
  );

  const handleOnAddItem = useCallback(() => {
    setItemToEdit({ crmId: selectedPsa.id });
    setShowCompanyVerificationDialog(true);
  });

  useEffect(() => {
    if (onePsaMode) {
      setSelectedPsa(crmSources[0]);
    }
  }, [crmSources, onePsaMode]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <Typography variant="h6">Companies Policies</Typography>
      <div>
        <div className={classes.dropDownContainer}>
          {!onePsaMode && (
            <>
              <div>Select PSA:</div>
              <DropDown
                options={crmSources}
                input={{
                  value: selectedPsa?.id,
                  onChange: e => {
                    setSelectedPsa(
                      crmSources.find(i => +i.id === +e.target.value),
                    );
                  },
                }}
                labelName="name"
                valueName="id"
              />
            </>
          )}
          {selectedPsa && (
            <div>
              <ReusableButton label="Add" onClick={handleOnAddItem} />
            </div>
          )}
        </div>
      </div>

      <div>
        {selectedPsa && settings?.length === 0 && (
          <Typography>No company policies</Typography>
        )}
        {selectedPsa &&
          settings?.map(i => (
            <div
              className={classes.listItem}
              key={i.id}
              style={{
                display: 'grid',
                gridTemplateColumns: '2fr 0fr 0fr',
              }}
            >
              <div>
                {
                  companiesOptions?.find(
                    p => p.value?.toString() === i.companyId?.toString(),
                  )?.label
                }
              </div>
              <div>
                <ActionFieldItem
                  alt="edit"
                  toolTip="Click to edit"
                  icon={EditIcon}
                  handler={() => {
                    setItemToEdit(i);
                    setShowCompanyVerificationDialog(true);
                  }}
                />
              </div>
              <div>
                <ActionFieldItem
                  alt="Delete"
                  toolTip="Delete this policy"
                  icon={DeleteIcon}
                  handler={() => {
                    setItemToEdit(i);
                    setOpenDeleteDialog(true);
                  }}
                />
              </div>
            </div>
          ))}
      </div>
      {showCompanyVerificationDialog && (
        <CompanyVerificationSettingsDialog
          open={showCompanyVerificationDialog}
          onClose={() => {
            setShowCompanyVerificationDialog(false);
          }}
          onSubmitHandle={() => {
            getCompaniesSettings();
          }}
          record={itemToEdit}
          companiesOptions={companiesOptions}
        />
      )}

      {openDeleteDialog && (
        <ConfirmDialog
          onAccept={() => {
            handleItemDelete(itemToEdit);
            setOpenDeleteDialog(false);
          }}
          onCancel={() => {
            setOpenDeleteDialog(false);
          }}
          title="Confirm Action"
          content="Are you sure you want to delete this item?"
          open={openDeleteDialog}
        />
      )}
    </div>
  );
};

export default CompaniesVerificationSettings;
