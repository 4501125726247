import React, { useState } from 'react';

import ReusableButton from '../../common/Button/Button';
import AddNewTeamsConnection from '../dialogs/AddNewTeamsConnection';
import useStyles from '../styles';

const TeamsConnectionsTableActions = () => {
  const [openAddNewConnectionDialog, setOpenAddNewConnectionDialog] = useState(
    false,
  );

  const classes = useStyles();

  return (
    <div className={classes.actionsContainer}>
      <ReusableButton
        label="Add"
        onClick={() => {
          setOpenAddNewConnectionDialog(true);
        }}
      />

      {openAddNewConnectionDialog && (
        <AddNewTeamsConnection
          open={openAddNewConnectionDialog}
          setOpen={setOpenAddNewConnectionDialog}
        />
      )}
    </div>
  );
};

TeamsConnectionsTableActions.propTypes = {};

export default TeamsConnectionsTableActions;
