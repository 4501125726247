import appConfig from '@configs/appConfig';
import putWithResponse from '@services/api/common/putWithResponse';
import { subscriptions } from '@constants/appRoutes';

export const syncWithStripe = stripeId =>
  putWithResponse(`${appConfig.baseUrl}${subscriptions}${stripeId}/sync`, null);

export const pauseSubscription = stripeId =>
  putWithResponse(
    `${appConfig.baseUrl}${subscriptions}${stripeId}/pause`,
    null,
  );
