import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AT_TICKET_TIME_ENTRIES, CRM_TICKET_EDIT } from '@constants/routes';
import crmSources from '@constants/crmSources';

import CalendarIcon from '@assets/icons/calendar_green.svg';
import CalendarPlus from '@assets/icons/calendar_plus.svg';
import EditIcon from '@assets/icons/edit.svg';
import UserIcon from '@assets/icons/user.svg';
import SMSIcon from '@assets/icons/sms.svg';
import ShieldPersonIcon from '@assets/icons/shield_person.svg';
import SyncIcon from '@assets/icons/secureDataSend.svg';

import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import ItemMenu from '@ui/components/common/ItemMenu/ItemMenu';
import AddATTimeEntryDialog from '@ui/components/CrmTicket/dialogs/AddATTimeEntry';
import CreateMessagingChannelDialog from '@ui/components/Messaging/dialogs/CreateMessagingChannelDialog';
import PodDialog from '@components/PodDialog';
import AssignResourceDialog from '../../dialogs/AssignResourcesDialog';
import AssignContactDialog from '../../dialogs/AssignContactDialog';

import useStyles from '../../styles';

const AutotaskTicketTableActions = ({ record, crmDefaultId, crmId }) => {
  const [openAddTimeEntryDialog, setOpenAddTimeEntryDialog] = useState(false);
  const [openPodDialog, setOpenPodDialog] = useState(false);

  const classes = useStyles();
  const { push } = useHistory();

  const [openAssignResourceDialog, setOpenAssignResourceDialog] = useState(
    false,
  );
  const [openAssignContactDialog, setOpenAssignContactDialog] = useState(false);
  const [openCreateChannelDialog, setOpenCreateChannelDialog] = useState(false);
  const [openSecureDialog, setOpenSecureDialog] = useState(false);

  const handleEditRedirect = () => {
    push(
      CRM_TICKET_EDIT.replace(':crmDefaultId', crmDefaultId)
        .replace(':ticketId', record.ticketNumber)
        .replace(':crmName', crmSources.Autotask.label),
    );
  };

  const handleTimeEntriesRedirect = useCallback(() => {
    push(
      AT_TICKET_TIME_ENTRIES.replace(':crmDefaultId', crmDefaultId).replace(
        ':ticketId',
        record.id,
      ),
    );
  }, [push, crmDefaultId, record.id]);

  const handleAssignContactClick = useCallback(() => {
    setOpenAssignContactDialog(true);
    setOpenCreateChannelDialog(false);
  }, []);

  const menuOptions = useMemo(
    () => [
      {
        icon: <img src={UserIcon} alt="assignResource" />,
        caption: 'Assign Resource',
        handler: () => {
          setOpenAssignResourceDialog(true);
        },
      },
      {
        icon: <img src={UserIcon} alt="assignContact" />,
        caption: 'Assign Contact',
        handler: () => {
          setOpenAssignContactDialog(true);
        },
      },
      {
        icon: <img src={CalendarIcon} alt="timeEntries" />,
        caption: 'Time Entries',
        handler: handleTimeEntriesRedirect,
      },
      {
        icon: <img src={CalendarPlus} alt="addTimeEntries" />,
        caption: 'Add Time Entry',
        handler: () => {
          setOpenAddTimeEntryDialog(true);
        },
      },
      {
        icon: <img src={SMSIcon} alt="addMessagingChannel" />,
        caption: 'Add SMS Channel',
        handler: () => {
          setOpenCreateChannelDialog(true);
        },
      },
    ],
    [handleTimeEntriesRedirect],
  );

  return (
    <div className={classes.tableActions}>
      {record?.contactId && (
        <>
          <ActionFieldItem
            alt="Open POD"
            handler={() => {
              setOpenPodDialog(true);
            }}
            toolTip="Open POD"
            icon={ShieldPersonIcon}
            ignoreDefaultIconSize
          />
          <ActionFieldItem
            alt="Secure Data"
            handler={() => setOpenSecureDialog(true)}
            toolTip="Secure data"
            icon={SyncIcon}
            ignoreDefaultIconSize
          />
        </>
      )}

      <ActionFieldItem
        alt="Edit"
        handler={handleEditRedirect}
        toolTip="Edit"
        icon={EditIcon}
      />
      <ItemMenu options={menuOptions} itemsHeightMultiplier={10} openOnHover />

      {openAssignResourceDialog && (
        <AssignResourceDialog
          open={openAssignResourceDialog}
          setOpen={setOpenAssignResourceDialog}
          crmDefaultId={crmDefaultId}
          record={record}
        />
      )}

      {openAssignContactDialog && (
        <AssignContactDialog
          open={openAssignContactDialog}
          setOpen={setOpenAssignContactDialog}
          record={record}
          crmDefaultId={crmDefaultId}
          crmName={crmSources.Autotask.label}
        />
      )}

      {openAddTimeEntryDialog && (
        <AddATTimeEntryDialog
          open={openAddTimeEntryDialog}
          setOpen={setOpenAddTimeEntryDialog}
          defaultId={crmDefaultId}
          ticketId={record.id}
        />
      )}

      {openCreateChannelDialog && (
        <CreateMessagingChannelDialog
          open={openCreateChannelDialog}
          setOpen={setOpenCreateChannelDialog}
          record={{ ...record, id: record.ticketNumber }}
          crmDefaultId={crmDefaultId}
          crmType={crmSources.Autotask.name}
          handleAssignContactClick={handleAssignContactClick}
        />
      )}

      {openPodDialog && (
        <PodDialog
          itemId={+record.id}
          onClose={() => {
            setOpenPodDialog(false);
          }}
          itemType="ticket"
          psaId={crmId}
          psa={crmSources.Autotask.label}
          page="verification"
        />
      )}

      {openSecureDialog && (
        <PodDialog
          itemId={+record.id}
          onClose={() => {
            setOpenSecureDialog(false);
          }}
          itemType="ticket"
          psaId={crmId}
          psa={crmSources.Autotask.label}
          page="secureData"
        />
      )}
    </div>
  );
};

AutotaskTicketTableActions.propTypes = {
  record: PropTypes.shape({
    ticketNumber: PropTypes.string,
    id: PropTypes.string,
  }),
  crmDefaultId: PropTypes.number,
};
export default AutotaskTicketTableActions;
