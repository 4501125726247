import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  border: {
    border: '1px solid #e5e5e5',
    borderRadius: '3px',
    margin: '2rem 2rem 0.5rem',
    minHeight: '3.5rem',
    boxShadow: '4px 3px 5px #b7b7b7',
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  marginEnd: {
    marginRight: '0.5rem',
  },
  bold: {
    fontWeight: 'bold',
  },
}));

export default useStyles;
