import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px',
  },
  font: {
    fontFamily: 'Montserrat',
  },
}));

export default useStyles;
