import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';

import { ThunkAssignTicketToTechVerification } from '@store/slices/psaTechVerification/thunks/assignTicketToTechVerification';

import { PSA_TECH_VERIFICATION } from '@constants/routes';

import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';

const ConnectWiseTicketSelector = ({ psaDefaultId, psaId, itemId }) => {
  const [processing, setProcessing] = useState(false);

  const notify = useNotify();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const handleAssign = payload => {
    setProcessing(true);
    dispatch(ThunkAssignTicketToTechVerification(payload))
      .unwrap()
      .then(() => {
        push(PSA_TECH_VERIFICATION);
        notify('Linked Successfully', 'success');
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Table
      resource={`/TicketUpdate/connectWise/${psaDefaultId}/tickets`}
      columns={[
        {
          name: 'Id',
          key: 'id',
          sortable: true,
          searchable: true,
          width: 80,
        },
        { name: 'Summary', key: 'summary', searchable: true },
        { name: 'Board', key: 'board', sortable: true },
        { name: 'Status', key: 'status', sortable: true },
        { name: 'Contact', key: 'contact', searchable: true },
        {
          name: 'Actions',
          key: TableTypes.actions,
          type: TableTypes.actions,
          width: 120,
          labelAlignment: 'center',
          accessor: item => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Tooltip title="Assign" style={{ cursor: 'pointer' }}>
                <AddIcon
                  onClick={() => {
                    if (!processing) {
                      handleAssign({
                        crmId: psaId,
                        ticketId: String(item.id),
                        contactId: item.contactId ?? null,
                        itemId: +itemId,
                        contactName: item.contact,
                      });
                    }
                  }}
                />
              </Tooltip>
            </div>
          ),
        },
      ]}
    />
  );
};

export default ConnectWiseTicketSelector;
