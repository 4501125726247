import React from 'react';
import { useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import copyText from '@utils/copy';

import CopyIcon from '@assets/icons/copy_blue.svg';
import iconEdit from '@assets/icons/edit.svg';
import DeleteIcon from '@assets/icons/delete.svg';
import iconView from '@assets/icons/visibility.svg';

import useStyles from '@common/Styles/actionFieldStyles';

const ActionField = ({ record, onEdit, onDelete }) => {
  const isAllowEdit = record.integrationName && record.fieldTransformers;
  const classes = useStyles();
  const notify = useNotify();
  const { push } = useHistory();

  const onCopy = () => {
    copyText(record.hookUrl);
    notify('resources.apiKeys.dialogs.copied');
  };

  const handleViewClick = () => {
    push(`/webHooks/${record.id}/results`);
  };

  return (
    <div className={classes.container}>
      {isAllowEdit && (
        <>
          <ActionFieldItem
            handler={onCopy}
            toolTip="Copy hook url to clipboard"
          >
            <img src={CopyIcon} alt="copy to clipboard" />
          </ActionFieldItem>
          <ActionFieldItem handler={() => onEdit(record)} toolTip="Edit hook">
            <img src={iconEdit} alt="iconEdit" />
          </ActionFieldItem>
        </>
      )}
      <ActionFieldItem handler={() => onDelete(record)} toolTip="Delete">
        <img src={DeleteIcon} alt="delete" />
      </ActionFieldItem>

      <ActionFieldItem
        handler={() => {
          handleViewClick();
        }}
        toolTip="View data"
      >
        <img src={iconView} alt="ViewData" />
      </ActionFieldItem>
    </div>
  );
};

ActionField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ActionField;
