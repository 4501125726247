import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    fontStyle: 'normal',
    margin: '1rem',
    height: '90vh',
    overflowY: 'auto',
  },
  urlInput: {
    margin: '0 1rem',
    height: '2rem',
    width: '50vw',
    border: '1px solid #E5E5E5',
    borderRadius: '5px',
    alignItems: 'center',
  },
  urlText: {
    clear: 'both',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: '0 0.5rem',
  },
  jsonTextStyle: {
    [theme.breakpoints.up('md')]: {
      maxHeight: '24vw',
      maxWidth: '82.5vw',
    },
  },
  textStyle: {
    overflowY: 'auto',
    maxHeight: 'calc(100vw - 40px)',
    maxWidth: 'calc(100vw - 40px)',
  },
  textBorder: {
    border: '1px solid #dddddd',
    borderRadius: '5px',
    padding: '0 5px',
  },
  splitContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
      '& #displayEmailElement, #parserElement': {
        width: '100% !important',
      },
    },
    flexWrap: 'wrap',
    display: 'flex',
  },
  ul: {
    listStyleType: 'circle',
  },
  li: {
    lineHeight: '1.5',
  },
  buttonMargin: {
    margin: '1rem',
  },
  tableWrapper: {
    marginTop: '2rem',
  },
  marginBottom: {
    marginBottom: '2rem',
  },
  templatesContainer: {
    padding: '5px',
    marginBottom: '1rem',
  },
  chip: {
    width: '10rem',
  },
  alignCenter: {
    alignItems: 'center',
  },
  justifySpace: {
    justifyContent: 'space-between',
  },
  flexDirColumn: {
    flexDirection: 'column',
  },
  topMargin: {
    marginTop: '1rem',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    display: 'flex',
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
