/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { ThunkGetCompaniesVerificationSettings } from '@store/slices/verificationSettings/thunks';

const initialState = {
  companiesVerificationSettings: [],
};

const verificationSettingsSlice = createSlice({
  name: VERIFICATION_SETTINGS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      ThunkGetCompaniesVerificationSettings.fulfilled,
      (state, action) => {
        state.companiesVerificationSettings = action.payload;
      },
    );
  },
});

export default verificationSettingsSlice.reducer;
