import React, { useState } from 'react';
import PropTypes from 'prop-types';

import crmSources from '@constants/crmSources';
import ShieldPersonIcon from '@assets/icons/shield_person.svg';
import SyncIcon from '@assets/icons/secureDataSend.svg';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

import OptInIcon from '@assets/icons/documents.svg';
import OptInDialog from '@components/integrations/connectwise/dialogs/OptInDialog';
import EditIcon from '@assets/icons/edit.svg';
import EditContactDialog from '@components/PsaContacts/dialogs/EditContactDialog';
import PodDialog from '@components/PodDialog';

import useStyles from '../../styles';

const SyncroMspTicketTableActions = ({
  record,
  crmId,
  tableType = 'tickets',
}) => {
  const [openSecureDialog, setOpenSecureDialog] = useState(false);
  const [openOptInDialog, setOpenOptInDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openPodDialog, setOpenPodDialog] = useState(false);

  const classes = useStyles();

  const disabled = !record?.contactId;

  const disabledToolTip = disabled
    ? '. This option is disabled because this ticket doesn’t have an assigned contact.'
    : '';

  return (
    <div className={classes.tableActions}>
      <>
        {tableType === 'contacts' && (
          <ActionFieldItem
            alt="Edit"
            handler={() => setOpenEditDialog(true)}
            toolTip="Edit"
            icon={EditIcon}
          />
        )}
        <ActionFieldItem
          alt="Verify User"
          handler={() => {
            setOpenPodDialog(true);
          }}
          toolTip={`Verify User ${disabledToolTip}`}
          icon={ShieldPersonIcon}
          disabled={disabled}
          ignoreDefaultIconSize
        />
        {tableType === 'contacts' && (
          <ActionFieldItem
            alt="Opt-in form"
            handler={() => setOpenOptInDialog(true)}
            toolTip="Opt-in form"
            icon={OptInIcon}
          />
        )}
        <ActionFieldItem
          alt="Secure Data"
          handler={() => setOpenSecureDialog(true)}
          toolTip={`Secure data ${disabledToolTip}`}
          icon={SyncIcon}
          disabled={disabled}
          ignoreDefaultIconSize
        />
      </>
      {openEditDialog && (
        <EditContactDialog
          open={openEditDialog}
          setOpen={setOpenEditDialog}
          record={record}
          psaId={crmId}
          contactId={record.id}
          psaType={crmSources.SyncroMsp.label}
        />
      )}
      {openOptInDialog && (
        <OptInDialog
          open={openOptInDialog}
          setOpen={setOpenOptInDialog}
          data={{
            ContactPhone: record.phone,
            ContactEmail: record.email,
            ContactId: record.id,
            TicketId: 0,
          }}
          psaId={crmId}
          hideNote
        />
      )}
      {openPodDialog && (
        <PodDialog
          itemId={tableType === 'contact' ? record.id : record.contactId}
          onClose={() => {
            setOpenPodDialog(false);
          }}
          itemType="contact"
          psaId={crmId}
          psa={crmSources.SyncroMsp.label}
        />
      )}
      {openSecureDialog && (
        <PodDialog
          itemId={tableType === 'contact' ? record.id : record.contactId}
          onClose={() => {
            setOpenSecureDialog(false);
          }}
          itemType="contact"
          psaId={crmId}
          psa={crmSources.SyncroMsp.label}
          page="secureData"
        />
      )}
    </div>
  );
};

SyncroMspTicketTableActions.propTypes = {
  record: PropTypes.shape({
    ticketNumber: PropTypes.string.isRequired,
    crmDefaultId: PropTypes.number.isRequired,
    contactId: PropTypes.number,
    email: PropTypes.number,
    phone: PropTypes.number,
    id: PropTypes.number,
  }),
  crmId: PropTypes.number.isRequired,
  tableType: PropTypes.string,
};
export default SyncroMspTicketTableActions;
