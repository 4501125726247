import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useListContext } from 'react-admin';
import { useDispatch } from 'react-redux';
import { FormControlLabel } from '@material-ui/core';

import {
  ThunkGetAutotaskTicketCompanies,
  ThunkGetAutotaskTicketContacts,
  ThunkGetAutotaskTicketOptions,
  ThunkGetAutotaskTicketResources,
} from '@store/slices/crmTicket/thunks';

import ViewAutoComplete from '@ui/components/common/ViewAutoComplete/ViewAutoComplete';
import Switch from '@common/FilterInputs/Switch';

import useStyles from '../../styles';

const localStorageItemName = 'autotaskTableFilters';

const AutotaskTicketTableFilters = ({
  defaultId,
  handleDefaultFiltersChange,
}) => {
  const classes = useStyles();

  const [options, setOptions] = useState({
    ticketType: [],
    issueType: [],
    queueID: [],
    priority: [],
    source: [],
    status: [],
    ticketCategory: [],
    company: [],
    resources: [],
    contacts: [],
  });
  const [ticketTypeId, setTicketTypeId] = useState({ value: 0, label: '' });
  const [issueTypeId, setIssueTypeId] = useState({ value: 0, label: '' });
  const [queueID, setQueueID] = useState({ value: 0, label: '' });
  const [priorityId, setPriorityId] = useState({ value: 0, label: '' });
  const [sourceId, setSourceId] = useState({ value: 0, label: '' });
  const [statusId, setStatusId] = useState({ value: 0, label: '' });
  const [ticketCategoryId, setTicketCategoryId] = useState({
    value: 0,
    label: '',
  });
  const [companyId, setCompanyId] = useState({ value: 0, label: '' });
  const [resourceId, setResourceId] = useState({ value: 0, label: '' });
  const [contactId, setContactId] = useState({ value: 0, label: '' });
  const [skipCompleted, setSkipCompleted] = useState({ value: true });

  const actions = {
    ticketTypeId: setTicketTypeId,
    issueTypeId: setIssueTypeId,
    companyId: setCompanyId,
    priorityId: setPriorityId,
    sourceId: setSourceId,
    queueID: setQueueID,
    statusId: setStatusId,
    ticketCategoryId: setTicketCategoryId,
    assignedResourceId: setResourceId,
    contactId: setContactId,
    skipCompleted: setSkipCompleted,
  };

  const dispatch = useDispatch();
  const { setFilters, filterValues } = useListContext();

  const handleChange = (item, setter, filterName) => {
    setter(item);
    setFilters(
      {
        [filterName]: item?.value,
      },
      filterValues,
      true,
    );

    const predefinedFilters = JSON.parse(
      localStorage.getItem(localStorageItemName),
    );

    localStorage.setItem(
      localStorageItemName,
      JSON.stringify({
        ...predefinedFilters,
        [filterName]: `${item?.value}/${item?.label}`,
      }),
    );
  };

  const onMount = useCallback(async () => {
    await Promise.all([
      new Promise(res =>
        res(
          dispatch(
            ThunkGetAutotaskTicketOptions({
              crmId: defaultId,
              options: [
                'ticketType',
                'issueType',
                'queueID',
                'priority',
                'source',
                'status',
                'ticketCategory',
              ],
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetAutotaskTicketCompanies(defaultId)).unwrap()),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetAutotaskTicketResources({ crmId: defaultId }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetAutotaskTicketContacts({ crmId: defaultId }),
          ).unwrap(),
        ),
      ),
    ]).then(res => {
      const responseOptions = {
        ...res[0],
        company: res[1],
        resources: res[2],
        contacts: res[3],
      };

      setOptions(responseOptions);
    });
  }, [dispatch, defaultId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useLayoutEffect(() => {
    // predefining filters on load
    const predefinedFilters = JSON.parse(
      localStorage.getItem(localStorageItemName),
    );

    if (predefinedFilters) {
      const keys = Object.keys(predefinedFilters);
      const filters = {};
      keys.forEach(item => {
        if (predefinedFilters[item]) {
          const [value, label] = predefinedFilters[item]?.split('/');
          if (value && value !== 'undefined') {
            if (item === 'skipCompleted') {
              actions[item]({ value: value === 'true' });
            } else if (actions[item]) {
              actions[item]({ value, label });
            }
            filters[item] = value;
          }
        }
      });
      handleDefaultFiltersChange(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.filtersContainer}>
      <ViewAutoComplete
        options={options.company}
        onChange={item => {
          handleChange(item, setCompanyId, 'companyId');
        }}
        currentView={companyId}
        textFieldLabel="Company"
      />

      <ViewAutoComplete
        options={options.queueID}
        onChange={item => {
          handleChange(item, setQueueID, 'queueID');
        }}
        currentView={queueID}
        textFieldLabel="Queue"
      />

      <ViewAutoComplete
        options={options.issueType}
        onChange={item => {
          handleChange(item, setIssueTypeId, 'issueTypeId');
        }}
        currentView={issueTypeId}
        textFieldLabel="Issue Type"
      />

      <ViewAutoComplete
        options={options.priority}
        onChange={item => {
          handleChange(item, setPriorityId, 'priorityId');
        }}
        currentView={priorityId}
        textFieldLabel="Priority"
      />

      <ViewAutoComplete
        options={options.source}
        onChange={item => {
          handleChange(item, setSourceId, 'sourceId');
        }}
        currentView={sourceId}
        textFieldLabel="Source"
      />

      <ViewAutoComplete
        options={options.status}
        onChange={item => {
          handleChange(item, setStatusId, 'statusId');
        }}
        currentView={statusId}
        textFieldLabel="Status"
      />

      <ViewAutoComplete
        options={options.ticketCategory}
        onChange={item => {
          handleChange(item, setTicketCategoryId, 'ticketCategoryId');
        }}
        currentView={ticketCategoryId}
        textFieldLabel="Ticket Category"
      />

      <ViewAutoComplete
        options={options.ticketType}
        onChange={item => {
          handleChange(item, setTicketTypeId, 'ticketTypeId');
        }}
        currentView={ticketTypeId}
        textFieldLabel="Ticket Type"
      />

      <ViewAutoComplete
        options={options.resources}
        onChange={item => {
          handleChange(item, setResourceId, 'assignedResourceId');
        }}
        currentView={resourceId}
        textFieldLabel="Resource"
      />

      <ViewAutoComplete
        options={options.contacts}
        onChange={item => {
          handleChange(item, setContactId, 'contactId');
        }}
        currentView={contactId}
        textFieldLabel="Contact"
      />

      <FormControlLabel
        style={{ alignItems: 'flex-end' }}
        control={
          <Switch
            input={{
              onChange: () => {
                handleChange(
                  { value: !skipCompleted.value },
                  setSkipCompleted,
                  'skipCompleted',
                );
              },
              value: skipCompleted.value,
            }}
            checked={skipCompleted.value}
          />
        }
        label="Skip Completed"
        labelPlacement="start"
      />
    </div>
  );
};

AutotaskTicketTableFilters.propTypes = {
  defaultId: PropTypes.number.isRequired,
  handleDefaultFiltersChange: PropTypes.func,
};

export default AutotaskTicketTableFilters;
