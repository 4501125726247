import React, { useEffect } from 'react';
import { useNotify } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { removeFirstElementOfErrorArray } from '@store/slices/errors';

const ErrorProvider = ({ children }) => {
  const errors = useSelector(state => state.errors.errors);

  const notify = useNotify();
  const dispatch = useDispatch();
  useEffect(() => {
    if (errors.length > 0) {
      notify(errors[0].message.slice(13), 'error');
      dispatch(removeFirstElementOfErrorArray());
    }
  }, [errors, notify, dispatch]);
  return <>{children}</>;
};

ErrorProvider.propTypes = {
  children: PropTypes.element,
};

export default ErrorProvider;
