const phoneWithCharactersValidator = (
  input,
  minLength = 10,
  isRequired = false,
) => {
  const errorMessage = 'Invalid phone number';

  if (input === '' && isRequired) {
    return 'Required field';
  }

  if (!input) {
    return undefined;
  }

  const lastSymbol = input[input.length - 1];
  if (
    lastSymbol === '-' ||
    lastSymbol === ')' ||
    lastSymbol === '(' ||
    lastSymbol === ' '
  ) {
    return errorMessage;
  }

  const regexBrackets = /^\+?\(?\d+\)?(?:[\s-]?\(?\d+\)?)*$/;

  const openBrackets = (input.match(/\(/g) || []).length;
  const closeBrackets = (input.match(/\)/g) || []).length;
  const correctBrackets =
    regexBrackets.test(input) &&
    openBrackets === closeBrackets &&
    !input.includes('()');

  if (!correctBrackets) {
    return errorMessage;
  }

  if (input.replace(/\D/g, '').length < minLength) {
    return `The minimum length required is ${minLength} characters.`;
  }

  return undefined;
};

export default phoneWithCharactersValidator;
