import React, { useCallback, useRef, useState } from 'react';
import { useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';

import { emailValidator } from '@utils/validators';
import { ThunkSendUsersInvites } from '@store/slices/usersInvite/thunks/sendUsersInites';

import DataSelector from '@components/Settings/Types/components/subComponents/DataSelector';
import ReusableButton from '@common/Button/Button';

import { actions } from '@store/actions';
import useSharedStyles from '@ui/components/CrmTicket/sharedStyles';
import useStyles from '../styles';
import InviteUsersResult from '../components/InviteUsersResult';

const InviteUsers = ({
  handleForward,
  addChipStyles = true,
  showCloseButton = false,
}) => {
  const [processing, setProcessing] = useState(false);
  const [emails, setEmails] = useState([]);
  const [enteredValue, setEnteredValue] = useState(null);
  const [inviteResult, setInviteResult] = useState(null);

  const inputTextRef = useRef();
  const notify = useNotify();
  const classes = useStyles({ addChipStyles });
  const sharedStyles = useSharedStyles();
  const dispatch = useDispatch();

  const handleArrowClick = form => {
    const input = inputTextRef.current;
    if (input && input.value) {
      const inputText = input.value.replace(',', `\\,`);
      if (emails.indexOf(inputText) > -1) {
        // * Checking if this email already added
        notify('Record Exist', 'warning');
      } else if (emailValidator(inputText) !== 'ra.auth.validation.email') {
        // * Email has correct format
        setEmails([...emails, inputText]);
        form.reset();
      } else {
        // * Notify user that email is incorrect
        notify('Incorrect email format', 'warning');
      }
    }
  };

  const handleChipsChange = (form, event) => {
    if (event.charCode === 13 && event.target.value) {
      event.preventDefault();
      handleArrowClick(form);
    }
  };

  const handleDelete = chipToDelete =>
    setEmails(chips => chips.filter(chip => chip !== chipToDelete));

  const sendInvites = useCallback(() => {
    setProcessing(true);
    let emailsToInvite = emails;
    if (emails.length === 0 && enteredValue) {
      const emailValidationError = emailValidator(enteredValue);
      if (!emailValidationError) {
        emailsToInvite = [enteredValue];
      }
    }

    dispatch(ThunkSendUsersInvites({ payload: { emails: emailsToInvite } }))
      .unwrap()
      .then(data => {
        data
          .filter(i => i.sent)
          .map(d => dispatch(actions.updateItemInList(d.data)));
        setInviteResult(data);
        setProcessing(false);
      })
      .catch(() => {
        setProcessing(false);
      });
  }, [dispatch, emails, enteredValue]);

  return (
    <div className={classes.itemContainer}>
      <DataSelector
        chipData={emails}
        handleArrowClick={handleArrowClick}
        handleChipsChange={handleChipsChange}
        handleDelete={handleDelete}
        inputRef={inputTextRef}
        label="Enter email address"
        setEnteredValue={setEnteredValue}
      />

      <div className={sharedStyles.dialogActionsContainer}>
        {showCloseButton && (
          <ReusableButton
            label="Close"
            onClick={handleForward}
            loading={processing}
            disabled={processing}
          />
        )}

        <ReusableButton
          viewType="black"
          label="Send Invites"
          onClick={sendInvites}
          loading={processing}
          disabled={processing}
        />
      </div>

      {inviteResult && (
        <InviteUsersResult items={inviteResult} handleForward={handleForward} />
      )}
    </div>
  );
};

export default InviteUsers;
