/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { COMMON } from '@store/slices/resources';
import { ThunkGetTenantSettings, ThunkGetTimeZones } from './thunks';

const getTimeZonesSlice = createSlice({
  name: COMMON,
  initialState: {
    timeZones: [],
    isLoading: true,
    tenantSettings: undefined,
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ThunkGetTimeZones.fulfilled, (state, action) => {
      state.types = action.payload;
    });

    builder.addCase(ThunkGetTenantSettings.fulfilled, (state, action) => {
      state.tenantSettings = action.payload;
    });

    builder.addMatcher(isAnyOf(ThunkGetTimeZones.fulfilled), state => {
      state.isLoading = false;
    });
  },
});

export const TenantSettingsSelector = state => state[COMMON].tenantSettings;

export default getTimeZonesSlice.reducer;
