import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useTranslatedText from '@common/hooks/useTranslatedText';
import { sortOrders } from '@constants/filters';
import TableDatePicker from '@common/TableDatePicker/TableDatePicker';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import useTableDateLabel from '@common/hooks/useTableDateLabel';
import TableDateLabel from '@common/TableLabel/TableDateLabel';
import DateTimeField from '@common/TableComponents/DateTimeField';
import TableLabel from '@common/TableLabel/TableLabel';
import TableDropDown from '@common/TableDropDown/TableDropDown';
import StatusesAccessor from '@common/MuiVirtualizedTable/components/accessors/StatusesAccessor';
import crmTicketStatuses from '@constants/crmTicketStatuses';
import ErrorField from '@components/Settings/CrmTickets/ErrorField';
import CoverToLinkAccessor from '@common/MuiVirtualizedTable/components/accessors/CoverToLinkAccessor';
import useStyles from './styles';

const valueHandlerStatus = key => crmTicketStatuses[key].name;

const CrmTickets = ({ resource }) => {
  const classes = useStyles();
  const { getFieldText } = useTranslatedText(resource);
  const { date, isOpen, setIsOpen, dateDispatcher } = useTableDateLabel();

  const columns = useMemo(
    () => [
      {
        name: (
          <TableDateLabel
            item={{ field: 'creationDate', name: getFieldText('creationDate') }}
            date={date}
            setIsOpen={setIsOpen}
          />
        ),
        key: 'creationDate',
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="creationDate"
            source="creationDate"
            label={getFieldText('creationDate')}
            sortable
          />
        ),
        width: 130,
      },
      {
        name: (
          <TableLabel
            item={{ name: getFieldText('ticketId'), field: 'ticketId' }}
          />
        ),
        key: 'ticketId',
        width: 120,
      },
      {
        name: (
          <TableLabel
            item={{ name: getFieldText('mailBoxName'), field: 'mailBoxName' }}
          />
        ),
        key: 'mailBoxName',
        width: 200,
      },
      {
        name: (
          <TableDropDown
            item={{ field: 'ticketStatus', name: 'Status' }}
            options={crmTicketStatuses}
            labelHandler={valueHandlerStatus}
          />
        ),
        key: 'ticketStatus',
        accessor: row => (
          <StatusesAccessor item={row.ticketStatus} array={crmTicketStatuses} />
        ),
        width: 150,
      },
      {
        name: (
          <TableLabel
            item={{ name: getFieldText('subject'), field: 'subject' }}
          />
        ),
        key: 'subject',
      },
      {
        name: (
          <TableLabel item={{ name: getFieldText('body'), field: 'body' }} />
        ),
        key: 'body',
        accessor: row => <CoverToLinkAccessor text={row.body} />,
      },
      {
        name: getFieldText('errorMessage'),
        key: 'errorMessage',
        accessor: row => <ErrorField value={row.errorMessage} />,
        width: 100,
      },
    ],
    [date, getFieldText, setIsOpen],
  );

  return (
    <>
      <MuiVirtualizedTable
        classNameWrapper={classes.tableWrapper}
        actions={null}
        defaultSort={{
          field: 'creationDate',
          order: sortOrders.desc,
        }}
        columns={columns}
        resource={resource}
      >
        <TableDatePicker
          date={date}
          dateDispatcher={dateDispatcher}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          field="creationDate"
        />
      </MuiVirtualizedTable>
    </>
  );
};

CrmTickets.propTypes = {
  resource: PropTypes.string,
};

export default CrmTickets;
