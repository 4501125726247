import React from 'react';
import PropTypes from 'prop-types';
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';

const CoverToLinkAccessor = ({ text }) => {
  return typeof text === 'string' && linkify.find(text).length ? (
    <div
      dangerouslySetInnerHTML={{
        __html: linkifyHtml(text, { target: 'blank' }),
      }}
    />
  ) : (
    text
  );
};

CoverToLinkAccessor.propTypes = {
  text: PropTypes.string,
};

export default CoverToLinkAccessor;
