import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';

import { ThunkGetClientPortals } from '@store/slices/clientPortalUsers/thunks';
import { ThunkBulkSendUsersInvites } from '@store/slices/usersInvite/thunks';
import { requiredValidator } from '@utils/validators';

import FieldWrapper from '@common/form/FieldWrapper';
import DropDown from '@components/Auth/Common/DropDown';
import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import useStyles from '../styles';
import { useTableContext } from '../../../../hooks/table';

const BulkInviteDialog = ({ open, onClose, psaId }) => {
  const [processing, setProcessing] = useState(false);
  const [clientPortals, setClientPortals] = useState([]);
  const [result, setResult] = useState(undefined);

  const dispatch = useDispatch();
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const notify = useNotify();
  const { selectedIds, list } = useTableContext();

  const contactsData = useMemo(() => {
    return selectedIds
      .map(i => {
        const selected = list.find(l => l.id === i);

        if (selected.hasClientPortal) {
          return undefined;
        }

        return {
          contactId: selected.id,
          contactName: selected.name,
          login: selected.email,
        };
      })
      .filter(i => i);
  }, [list, selectedIds]);

  const submit = ({ clientPortalId }) => {
    const payload = {
      clientPortalId,
      crmId: psaId,
      clientPortalAssignments: contactsData,
    };

    setProcessing(true);

    dispatch(ThunkBulkSendUsersInvites({ payload }))
      .unwrap()
      .then(res => {
        setResult(res);
        notify('Invites Send', 'success');
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = values => ({
    clientPortalId: requiredValidator(values.clientPortalId),
  });

  const onMount = useCallback(() => {
    setProcessing(true);
    dispatch(ThunkGetClientPortals(psaId))
      .unwrap()
      .then(res => {
        setClientPortals(res);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [dispatch, psaId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Send Invites</DialogTitle>

      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {!result && (
                <FieldWrapper
                  label="Client Portal"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  content={
                    <Field
                      name="clientPortalId"
                      id="clientPortalId"
                      render={DropDown}
                      disabled={processing}
                      options={clientPortals}
                    />
                  }
                />
              )}

              {result && (
                <div>
                  <div
                    className={cx(classes.statsContainer, classes.successStat)}
                  >
                    <b>Successfully:</b>
                    {result.success}
                  </div>
                  <div
                    className={cx(classes.statsContainer, classes.failedStat)}
                  >
                    <b>Failed:</b>
                    {result.failure}
                  </div>

                  <div>
                    <div>
                      <b>Errors:</b>
                    </div>
                    {result.errors.map(i => (
                      <div key={i}>{i}</div>
                    ))}
                  </div>
                </div>
              )}

              <div className={sharedClasses.dialogActionsContainer}>
                <ReusableButton
                  label="Close"
                  onClick={() => {
                    onClose();
                  }}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing || result}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

BulkInviteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  psaId: PropTypes.number.isRequired,
};

export default BulkInviteDialog;
