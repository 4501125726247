import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useListContext } from 'react-admin';
import { Button } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';

import { sortOrders, COUNT_PRODUCT_PER_PAGE } from '@constants/filters';

import useStyles from './style';

const TableSortLabel = ({ item: { field, name } }) => {
  const classes = useStyles();
  const {
    setFilters,
    filterValues,
    currentSort,
    setSort,
    setPerPage: setCountData,
  } = useListContext();

  const isCurrentFieldSorted = currentSort.field === field;

  const setSorting = () => {
    setCountData(COUNT_PRODUCT_PER_PAGE);
    setSort(field);
    setFilters(filterValues, filterValues, true);
  };

  const classNameForButtonIcon = cx(classes.tableHeaderButtonIcon, {
    [classes.tableHeaderButtonIconRotate]:
      isCurrentFieldSorted && currentSort.order === sortOrders.asc,
  });

  return (
    <>
      <span className={classes.marginForText}>{name}</span>
      <Button onClick={setSorting} className={classes.tableHeaderButton}>
        <ArrowUpward className={classNameForButtonIcon} />
      </Button>
    </>
  );
};

TableSortLabel.propTypes = {
  item: PropTypes.shape({
    field: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default TableSortLabel;
