import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import TableLabel from '@common/TableLabel/TableLabel';

import { selectedNetworkSelector } from '@store/selectors/selectedNetworks';
import { actions } from '@store/actions';

import HyperlinkAccessor from '@common/MuiVirtualizedTable/components/accessors/HyperlinkAccessor';
import DetailedViewAccessor from '@common/MuiVirtualizedTable/components/accessors/DetailedViewAccessor';
import BooleanValueAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanValueAccessor';
import TableDropDown from '@common/TableDropDown/TableDropDown';
import { MuiVirtualizedListView } from '@common/MuiVirtualizedTable';
import {
  booleanStatuses,
  tableKeys,
} from '@common/MuiVirtualizedTable/constants';

const valueHandlerStatus = key => booleanStatuses[key].name;

const columns = [
  {
    key: 'networkName',
    name: <TableLabel item={{ name: 'Network', field: 'networkName' }} />,
    width: 200,
    renderCell: row => (
      <HyperlinkAccessor href={row.networkUrl} value={row.networkName} />
    ),
  },
  {
    key: 'allAdmins',
    name: (
      <TableDropDown
        item={{ field: 'allAdmins', name: 'All Admins' }}
        options={booleanStatuses}
        labelHandler={valueHandlerStatus}
      />
    ),
    width: 150,
    accessor: row => <BooleanValueAccessor value={row.allAdmins} />,
  },
  {
    key: 'snmp',
    name: (
      <TableDropDown
        item={{ field: 'snmp', name: 'snmp' }}
        options={booleanStatuses}
        labelHandler={valueHandlerStatus}
      />
    ),
    width: 150,
    accessor: row => <BooleanValueAccessor value={row.snmp} />,
  },
  {
    key: 'emails',
    name: <TableLabel item={{ name: 'Emails', field: 'emails' }} />,
    width: 350,
    accessor: row => <DetailedViewAccessor value={row.emails} />,
  },
  {
    key: 'webHooks',
    name: <TableLabel item={{ name: 'Web hooks', field: 'webHooks' }} />,
    width: 350,
    accessor: row => <DetailedViewAccessor value={row.httpServerIds} />,
  },
];

const Grid = ({ data, organizationId }) => {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const selectedNetworks =
    useSelector(selectedNetworkSelector)[organizationId] || [];

  useMemo(() => {
    const newRows = data.map((i, idx) => {
      return {
        id: idx,
        allAdmins: i.alertSettings?.defaultDestinations.allAdmins,
        snmp: i.alertSettings?.defaultDestinations.snmp,
        emails: i.alertSettings?.defaultDestinations.emails,
        httpServerIds: i.alertSettings?.defaultDestinations.httpServerIds,
        ...i,
      };
    });
    setRows(newRows);
  }, [data]);

  const setSelected = value => {
    dispatch(
      actions.setSelectedNetworks({
        entityType: organizationId,
        value,
      }),
    );
  };

  const onToggleItem = selectData => {
    if (Array.isArray(selectData) && selectData.length === data.length) {
      const filteredIds =
        selectedNetworks.length === selectData.length ? [] : selectData;
      setSelected(filteredIds);
    } else {
      const idsForFiltering = Array.isArray(selectData)
        ? selectData
        : [selectData];
      const isExistId = selectedNetworks.some(o => idsForFiltering.includes(o));

      const updatedIds = isExistId
        ? selectedNetworks.filter(id => !idsForFiltering.includes(id))
        : [...selectedNetworks, ...idsForFiltering];
      setSelected(updatedIds);
    }
  };

  return (
    <div style={{ height: 650, width: '100%' }}>
      <MuiVirtualizedListView
        tableKey={tableKeys.alertSettingGrid}
        bulkActions
        data={rows}
        columns={columns}
        selectedIds={selectedNetworks}
        onToggleItem={onToggleItem}
      />
    </div>
  );
};

Grid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  organizationId: PropTypes.string,
};

export default Grid;
