import {
  ArrowDownward,
  ArrowUpward,
  Pause,
  PlayArrow,
} from '@material-ui/icons';
import iconPause from '@assets/icons/notify.svg';
import iconOk from '@assets/icons/accept.svg';
import iconDown from '@assets/icons/remove.svg';

const events = {
  Start: {
    icon: PlayArrow,
    name: 'Started',
    enumKey: 'Start',
    color: '#0E96E2',
    statusIcon: iconOk,
    statusColor: '#67AD5B',
  },
  Pause: {
    icon: Pause,
    name: 'Pause',
    enumKey: 'Pause',
    color: '#F0BD3A',
    statusIcon: iconPause,
    statusColor: '#F0BD3A',
  },
  Up: {
    icon: ArrowUpward,
    name: 'Up',
    enumKey: 'Up',
    color: '#67AD5B',
    statusIcon: iconOk,
    statusColor: '#67AD5B',
  },
  Down: {
    icon: ArrowDownward,
    name: 'Down',
    enumKey: 'Down',
    color: '#EC6A4E',
    statusIcon: iconDown,
    statusColor: '#EC6A4E',
  },
};

export default events;
