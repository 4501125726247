import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  formText: {
    marginTop: '1rem',
    fontStyle: 'normal',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputWrapper: {
    marginTop: '0.625rem',
  },
}));

export default useStyles;
