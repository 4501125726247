import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';

import { ThunkBeautifyPhoneNumber } from '@store/slices/common/thunks';
import { ThunkAddUsersToSmsCampaignGroup } from '@store/slices/smsCampaigns/thunks';

import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';

import { SMS_CAMPAIGN_USER_TYPES } from '@constants/smsCampaign';
import maxLengthValidator from '@utils/validators/maxLengthValidator';
import phoneValidation from '@constants/phoneValidation';

import { ReactComponent as PhoneIcon } from '@assets/icons/phone_password.svg';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

const SmsCampaignManualUsers = ({ open, onClose, smsCampaignGroupId }) => {
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const sharedStyles = useSharedStyles();
  const list = useSelector(listSelector);
  const notify = useNotify();

  const beautifyPhone = useCallback(
    (phone, form) => {
      setProcessing(true);
      dispatch(ThunkBeautifyPhoneNumber(phone))
        .unwrap()
        .then(d => form.change('phone', d.data))
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch],
  );

  const submit = values => {
    setProcessing(true);

    const payload = {
      type: SMS_CAMPAIGN_USER_TYPES.MANUAL,
      smsCampaignGroupId,
      contacts: [values],
    };

    dispatch(ThunkAddUsersToSmsCampaignGroup({ payload }))
      .unwrap()
      .then(res => {
        dispatch(actions.saveList([...list, ...res]));
        onClose();
        notify('Successfully added', 'success');
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = values => {
    return {
      name: maxLengthValidator(values.name, 250),
      phone: phoneValidation(values.phone),
    };
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={open}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Full Name"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    name="name"
                    id="name"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Phone number"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    name="phone"
                    id="phone"
                    render={Input}
                    disabled={processing}
                    endAdornment={
                      <ActionButton
                        icon={<PhoneIcon />}
                        handler={() => beautifyPhone(values.phone, form)}
                        toolTip="Fix Phone Number Format"
                      />
                    }
                  />
                }
              />

              <div className={sharedStyles.dialogActionsContainer}>
                <ReusableButton
                  label="Cancel"
                  onClick={onClose}
                  disabled={processing}
                />
                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

SmsCampaignManualUsers.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  smsCampaignGroupId: PropTypes.number.isRequired,
};

export default SmsCampaignManualUsers;
