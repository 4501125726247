import React from 'react';
import PropTypes from 'prop-types';
import { Pause, PlayArrow } from '@material-ui/icons';
import useStyles from './styles';

const RunningState = ({ row, handleClick }) => {
  const Icon = row.enabled ? PlayArrow : Pause;
  const classes = useStyles();

  return (
    <div className={classes.runningStateContainer}>
      <Icon
        className={classes.runningStateIcon}
        onClick={() => handleClick(row.id, row.enabled)}
      />
    </div>
  );
};
RunningState.propTypes = {
  row: PropTypes.objectOf(PropTypes.any),
  handleClick: PropTypes.func,
};

export default RunningState;
