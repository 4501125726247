import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import { useHistory } from 'react-router-dom';

import { ThunkSendResetPasswordEmail } from '@store/slices/auth/thunks';
import { requiredValidator } from '@utils/validators';
import { LOGIN_ROUTE, RESET_PASSWORD } from '@constants/routes';

import LoginPageBanner from '@ui/common/LoginPageBanner';
import AppLogo from '@ui/common/AppLogo';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import UnauthorizedProvider from '@ui/common/UnauthorizedProvider/UnauthorizedProvider';

import useSharedStyles from '../Login/styles';
import useStyles from './styles';

const ForgotPassword = () => {
  const [processing, setProcessing] = useState(false);

  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const { push } = useHistory();

  const validate = values => ({
    email: requiredValidator(values.email),
  });

  const submit = values => {
    setProcessing(true);
    const payload = {
      email: values.email,
      redirectPrefix: `${window.location.origin}/#${RESET_PASSWORD}?code=`,
    };

    dispatch(ThunkSendResetPasswordEmail({ payload }))
      .unwrap()
      .then(() => {
        notify('Email Send', 'success');
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <UnauthorizedProvider>
      <div className={sharedClasses.container}>
        <LoginPageBanner />
        <div className={sharedClasses.activeContainer}>
          <AppLogo />
          <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
              <div>
                <form onSubmit={handleSubmit}>
                  <div>
                    Enter your email and we send link to reset your password.
                  </div>
                  <FieldWrapper
                    label="Email"
                    labelSize={12}
                    contentSize={12}
                    fullWidth
                    content={
                      <Field
                        placeholder="Enter your email here"
                        name="email"
                        id="email"
                        render={Input}
                        disabled={processing}
                      />
                    }
                  />

                  <div className={classes.buttons}>
                    <ReusableButton
                      label="Back"
                      onClick={() => {
                        push(LOGIN_ROUTE);
                      }}
                      disabled={processing}
                    />
                    <ReusableButton
                      label="Send"
                      type="submit"
                      viewType="blue"
                      disabled={processing}
                      loading={processing}
                    />
                  </div>
                </form>
              </div>
            )}
          />
        </div>
      </div>
    </UnauthorizedProvider>
  );
};

export default ForgotPassword;
