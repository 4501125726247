import React, { useMemo, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

import Input from '@ui/components/Auth/Common/Input';
import { useHistory } from 'react-router-dom';
import ReusableButton from '../Button/Button';

import useStyles from './styles';

const SearchableMenu = ({
  menuItems,
  onMenuItemClick,
  buttonLabel = 'Menu',
  searchable = false,
  allowNavigate = false,
  navigateItem = undefined,
}) => {
  const [anchorElement, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');

  const classes = useStyles();
  const { push } = useHistory();

  const filteredItems = useMemo(() => {
    if (search !== '') {
      return menuItems.filter(i =>
        i.name.toLowerCase().includes(search.toLowerCase()),
      );
    }
    return menuItems;
  }, [menuItems, search]);

  return (
    <>
      <ReusableButton
        label={buttonLabel}
        size="md"
        onClick={e => setAnchorEl(e.currentTarget)}
      />
      <Menu
        id="conversation-selector"
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={() => setAnchorEl(null)}
      >
        {allowNavigate && navigateItem && (
          <MenuItem
            onKeyDown={e => e.stopPropagation()}
            key={navigateItem.name}
            value={navigateItem.name}
            onClick={() => {
              push(navigateItem.path);
            }}
          >
            {navigateItem.name}
          </MenuItem>
        )}
        {searchable && (
          <MenuItem
            onKeyDown={e => e.stopPropagation()}
            className={classes.menuItem}
          >
            <Input
              input={{
                value: search,
                onChange: e => {
                  e.stopPropagation();
                  setSearch(e.target.value);
                },
              }}
              meta={{ touched: false }}
              placeholder="Search"
            />
          </MenuItem>
        )}

        {filteredItems.map(item => (
          <MenuItem
            onKeyDown={e => e.stopPropagation()}
            key={item.id}
            value={item.id}
            onClick={() => {
              onMenuItemClick(item);
              setAnchorEl(null);
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

SearchableMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ).isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
  searchable: PropTypes.bool,
  allowNavigate: PropTypes.bool,
  navigateItem: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
  }),
};
export default SearchableMenu;
