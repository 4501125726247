import React from 'react';
import PropTypes from 'prop-types';
import events from './events';

import useStyles from './styles';

const Statuses = ({ lastStatus }) => {
  const { statusIcon, statusColor } = events[lastStatus];
  const classes = useStyles({ statusColor });
  return (
    <div className={classes.statusBlock}>
      <img src={statusIcon} alt="statusIcon" />
    </div>
  );
};

Statuses.propTypes = {
  lastStatus: PropTypes.string,
};

export default Statuses;
