import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import TableLabel from '@common/TableLabel/TableLabel';

const map = [
  { item: 'tenantName', width: 'auto', filter: true },
  { item: 'name', width: 'auto', filter: true },
  { item: 'parsersCount', width: 120, sort: true, filter: false },
  { item: 'total', width: 120, sort: true, filter: false },
  { item: 'processed', width: 120, sort: true, filter: false },
  { item: 'attentionRequired', sort: true, width: 120, filter: false },
  { item: 'failed', width: 120, sort: true, filter: false },
  { item: 'quotaExceeded', width: 120, sort: true, filter: false },
  { item: 'ignored', width: 120, sort: true, filter: false },
];

const defaultSort = { field: 'name', order: 'ASC' };

const ParsingStatus = ({ resource }) => {
  const translate = useTranslate();
  const getLabel = useCallback(
    val => translate(`resources.${resource}.columns.${val}`),
    [resource, translate],
  );

  const columns = useMemo(
    () =>
      map.map(i => {
        return {
          name: i.filter ? (
            <TableLabel item={{ field: i.item, name: getLabel(i.item) }} />
          ) : (
            getLabel(i.item)
          ),
          key: i.item,
          width: i.width,
          sort: i.sort && i.item,
        };
      }),
    [getLabel],
  );

  return (
    <>
      <h2>Parsing status</h2>
      <MuiVirtualizedTable
        actions={null}
        columns={columns}
        resource="mailBoxes/statistic"
        defaultSort={defaultSort}
      />
    </>
  );
};

ParsingStatus.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default ParsingStatus;
