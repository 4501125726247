import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import HyperlinkAccessor from '@common/MuiVirtualizedTable/components/accessors/HyperlinkAccessor';
import TitledValueAccessor from '@common/MuiVirtualizedTable/components/accessors/TitledValueAccessor';
import TableLabel from '@common/TableLabel/TableLabel';
import { MuiVirtualizedListView } from '@common/MuiVirtualizedTable';
import { tableKeys } from '@common/MuiVirtualizedTable/constants';

const columns = [
  {
    key: 'networkName',
    name: <TableLabel item={{ name: 'Network', field: 'networkName' }} />,
    width: 300,
    accessor: row => (
      <HyperlinkAccessor href={row.networkUrl} value={row.networkName} />
    ),
  },
  {
    key: 'devices',
    name: 'Devices',
    width: 300,
    accessor: row =>
      row.devices.map(i => (
        <>
          <HyperlinkAccessor href={i.url} value={i.name} />
          &nbsp;
        </>
      )),
  },
  {
    key: 'category',
    name: <TableLabel item={{ name: 'Category', field: 'category' }} />,
    width: 200,
  },
  {
    key: 'type',
    name: <TableLabel item={{ name: 'Type', field: 'type' }} />,
    width: 300,
    accessor: row => <TitledValueAccessor value={row.type} />,
  },
  {
    key: 'severity',
    name: <TableLabel item={{ name: 'Severity', field: 'severity' }} />,
    width: 150,
  },
];

const HookAlertsGrid = ({ data }) => {
  const rowsData = useMemo(() => {
    return data.map((i, idx) => {
      return {
        id: idx,
        wan: [i.wan1Ip, i.wan2Ip],
        wanGw: [i.wan1Gateway, i.wan2Gateway],
        ...i,
      };
    });
  }, [data]);

  return (
    <MuiVirtualizedListView
      tableKey={tableKeys.hookAlertsGrid}
      data={rowsData}
      columns={columns}
    />
  );
};

HookAlertsGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
};

export default HookAlertsGrid;
