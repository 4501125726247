import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tableWrapper: {
    marginTop: '0.5rem',
  },
  activeFieldContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonWrapper: {
    margin: '0 0 5px 25px',
  },
  formText: {
    marginTop: '1rem',
    fontStyle: 'normal',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  colorBlock: {
    fontWeight: 'bold',
    color: props => props.color,
  },
  errorBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '30rem',
    transform: 'translate(-50%, -50%)',
    padding: '1rem',
  },
}));

export default useStyles;
