import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';

import { actions } from '@store/actions';

import {
  ThunkDeleteUserInvite,
  ThunkSendUsersInvites,
} from '@store/slices/usersInvite/thunks';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import InviteUsersTableActions from '@components/InvitedUsers/InviteUsersTableActions';

import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { getRoleIdFromStorage } from '@services/api';
import roles from '@constants/roles';

import { getColumns } from './constants';
import useStyles from './styles';

const InvitedUsers = () => {
  const [processing, setProcessing] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [openReInviteDialog, setOpenReInviteDialog] = useState(false);
  const [openDeleteInviteDialog, setOpenDeleteInviteDialog] = useState(false);
  const allowEdit = getRoleIdFromStorage() !== roles.READ_ONLY;

  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  const handleUserReInviteDialogClose = useCallback(() => {
    setProcessing(false);
    setOpenReInviteDialog(false);
    setSelectedUser(undefined);
  }, []);

  const handleDeleteInviteDialogClose = useCallback(() => {
    setProcessing(false);
    setOpenDeleteInviteDialog(false);
    setSelectedUser(undefined);
  }, []);

  const handleUserReInvite = useCallback(
    userEmail => {
      setProcessing(true);
      dispatch(
        ThunkSendUsersInvites({
          payload: {
            emails: [userEmail],
            reinvite: true,
          },
        }),
      )
        .unwrap()
        .then(data => {
          data
            .filter(i => i.sent)
            .map(d => dispatch(actions.updateItemInList(d.data)));
          notify('Successfully reinvited');
          handleUserReInviteDialogClose();
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch],
  );

  const handleDeleteInvite = useCallback(
    id => {
      setProcessing(true);
      dispatch(ThunkDeleteUserInvite(id))
        .unwrap()
        .then(() => {
          notify('Successfully deleted');
          dispatch(actions.removeItemFromList({ id }));
          handleDeleteInviteDialogClose();
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch],
  );

  const handleReInviteDialogOpen = useCallback(userEmail => {
    setSelectedUser({ email: userEmail });
    setOpenReInviteDialog(true);
  }, []);

  const handleDeleteDialogOpen = useCallback(id => {
    setSelectedUser({ id });
    setOpenDeleteInviteDialog(true);
  }, []);

  return (
    <div className={classes.container}>
      <MuiVirtualizedTable
        actions={<InviteUsersTableActions />}
        defaultSort={{ field: 'isRegistered', order: 'ASC' }}
        columns={getColumns(
          handleReInviteDialogOpen,
          handleDeleteDialogOpen,
          allowEdit,
        )}
        resource="InvitedUsers"
      />

      {openReInviteDialog && (
        <ConfirmDialog
          onAccept={() => {
            handleUserReInvite(selectedUser.email);
          }}
          title="Confirm Action"
          loading={processing}
          content="Are you sure you want to this re-invite user?"
          onCancel={() => {
            handleUserReInviteDialogClose();
          }}
          open={openReInviteDialog}
        />
      )}

      {openDeleteInviteDialog && (
        <ConfirmDialog
          onAccept={() => {
            handleDeleteInvite(selectedUser.id);
          }}
          title="Confirm Action"
          loading={processing}
          content="Are you sure you want to delete the invite for the user?"
          onCancel={() => {
            handleDeleteInviteDialogClose();
          }}
          open={openDeleteInviteDialog}
        />
      )}
    </div>
  );
};

export default InvitedUsers;
