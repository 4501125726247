import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';

import deleteIcon from '@assets/icons/deleteWhite.svg';
import ReusableButton from '@common/Button/Button';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';

import { ThunkDeleteSmsBlackListNumbers } from '@store/slices/smsBlackList/thunks';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import { useTableContext } from '../../../../../hooks/table';

import useStyles from '../styles';

const BulkActionButtons = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  const { selectedIds, deselectAllItems } = useTableContext();
  const list = useSelector(listSelector);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleDelete = () => {
    setProcessing(true);
    dispatch(ThunkDeleteSmsBlackListNumbers(selectedIds))
      .unwrap()
      .then(() => {
        const newList = list.filter(item => !selectedIds.includes(item.id));
        notify(`Successfully removed`);
        dispatch(actions.saveList(newList));
        deselectAllItems(false);
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => {
        setOpenDeleteDialog(false);
        setProcessing(false);
      });
  };

  return (
    <>
      <ReusableButton
        onClick={() => setOpenDeleteDialog(true)}
        viewType="red"
        classNameWrapper={classes.btnWrapper}
        size="xl"
      >
        <>
          <img src={deleteIcon} alt="deleteIcon" />
          Remove
        </>
      </ReusableButton>
      {openDeleteDialog && (
        <ConfirmDialog
          loading={processing}
          onAccept={() => {
            handleDelete();
          }}
          onCancel={() => {
            setOpenDeleteDialog(false);
          }}
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          title="Confirm Action"
          content="Are you sure you want to delete the selected items?"
        />
      )}
    </>
  );
};

export default BulkActionButtons;
