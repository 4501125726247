import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate, useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';
import { actions } from '@store/actions';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable';
import BulkActionButtons from '@common/BulkActionButtons';
import StatusCircle from '@common/StatusCircle/StatusCircle';
import TableLabel from '@common/TableLabel/TableLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import ActionField from '@common/ActionFields/ActionField';
import DeleteDialog from './DeleteDialog';
import { toggleEnabled } from './helpers';
import useStyles from '../styles';

const List = ({ resource }) => {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [recordForDelete, setRecordForDelete] = useState(null);

  const getLabel = useCallback(
    src =>
      translate(
        'resources.'
          .concat(resource)
          .concat('.fields.')
          .concat(src),
      ),
    [resource, translate],
  );

  const handleMakeEnabled = useCallback(
    (id, isActive) => {
      setLoading(true);
      toggleEnabled(id, !isActive)
        .then(() => {
          dispatch(
            actions.patchListItem({
              id,
              field: 'enabled',
              value: !isActive,
            }),
          );
        })
        .catch(e => notify(e.message, 'error'))
        .finally(() => setLoading(false));
    },
    [dispatch, notify],
  );

  const columns = useMemo(
    () => [
      {
        name: getLabel('id'),
        key: 'id',
        sort: 'id',
        width: 100,
      },
      {
        name: (
          <TableLabel
            item={{ name: getLabel('tenantName'), field: 'tenantName' }}
          />
        ),
        key: 'tenantName',
        width: 270,
      },
      {
        name: (
          <TableLabel
            item={{ name: getLabel('tenantAlias'), field: 'tenantAlias' }}
          />
        ),
        key: 'tenantAlias',
        width: 270,
      },
      {
        name: getLabel('usersCount'),
        key: 'usersCount',
        sort: 'usersCount',
        width: 200,
      },
      {
        name: (
          <TableLabel
            item={{ name: getLabel('tenantAdmin'), field: 'tenantAdmin' }}
          />
        ),
        key: 'tenantAdmin',
      },
      {
        name: 'Enabled',
        key: 'enabled',
        accessor: row => (
          <StatusCircle
            handleClick={handleMakeEnabled}
            row={row}
            rowKey="enabled"
          />
        ),
        width: 120,
      },
      {
        name: 'Actions',
        key: 'actions',
        accessor: rowData => (
          <ActionField
            label="Actions"
            record={rowData}
            onDelete={setRecordForDelete}
            fieldKey="delete"
          />
        ),
        width: 90,
      },
    ],
    [getLabel],
  );

  return (
    <>
      {loading && <LinearProgress />}
      <MuiVirtualizedTable
        classNameWrapper={classes.tableWrapper}
        actions={null}
        bulkActions={<BulkActionButtons />}
        columns={columns}
        resource={resource}
      />
      <DeleteDialog
        isOpen={!!recordForDelete}
        onClose={() => setRecordForDelete(null)}
        record={recordForDelete}
      />
    </>
  );
};

List.propTypes = {
  resource: PropTypes.string,
};

export default List;
