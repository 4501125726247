import { regions } from '@constants/regions';

export const searchTypesAutocomplete = [
  { label: 'Number Starts With', value: 0 },
  { label: 'Number Contains', value: 1 },
  { label: 'Number Ends With', value: 2 },
];

export const typesAutocomplete = [
  { label: 'SMS', value: 'sms' },
  { label: 'MMS', value: 'mms' },
];

export const tableDefaultFilters = {
  region: regions.US,
  searchType: 1,
};
