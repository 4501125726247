import React from 'react';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import TableLabel from '@ui/components/common/TableLabel/TableLabel';
import TableDropDown from '@ui/components/common/TableDropDown/TableDropDown';

import useTranslation from '@ui/components/common/hooks/useTranslation';
import webHookStatuses from '@constants/webHookStatuses';
import TableDateLabel from '@common/TableLabel/TableDateLabel';
import DateTimeField from '@common/TableComponents/DateTimeField';
import TableDatePicker from '@common/TableDatePicker/TableDatePicker';
import useTableDateLabel from '@common/hooks/useTableDateLabel';
import BulkActions from './components/BulkActions';

import ActionsField from './components/ActionsFields';

import useStyles from './styles';

const WebHookResults = ({ match }) => {
  const { getLabel } = useTranslation('webHookResults');
  const classes = useStyles();

  const {
    date: dateTime,
    isOpen: isOpenDateTime,
    setIsOpen: setIsOpenDateTime,
    dateDispatcher: dateTimeDispatcher,
  } = useTableDateLabel();

  return (
    <div className={classes.container}>
      <MuiVirtualizedTable
        actions={<></>}
        defaultSort={{ field: 'date', order: 'DESC' }}
        bulkActions={<BulkActions />}
        columns={[
          {
            name: <TableLabel item={{ name: getLabel('id'), field: 'id' }} />,
            key: 'id',
          },
          {
            name: (
              <TableDateLabel
                item={{
                  field: 'date',
                  name: getLabel('date'),
                }}
                date={dateTime}
                setIsOpen={setIsOpenDateTime}
              />
            ),
            key: 'date',
            accessor: rowData => (
              <DateTimeField
                record={rowData}
                key="date"
                source="date"
                label={getLabel('date')}
                sortable
              />
            ),
            minWidth: 165,
          },
          {
            name: (
              <TableDropDown
                item={{ name: getLabel('status'), field: 'status' }}
                options={webHookStatuses}
                labelHandler={key => webHookStatuses[key].label}
              />
            ),
            key: 'status',
            accessor: rowData => (
              <span
                style={{
                  backgroundColor: webHookStatuses[rowData.status]?.hex,
                }}
                className={classes.statusField}
              >
                {webHookStatuses[rowData.status]?.name}
              </span>
            ),
          },
          {
            name: (
              <TableLabel
                item={{ name: getLabel('errorMessage'), field: 'errorMessage' }}
              />
            ),
            key: 'errorMessage',
          },
          {
            name: 'Actions',
            key: 'actions',
            accessor: rowData => (
              <ActionsField webHook={rowData} webHookId={match.params.id} />
            ),
          },
        ]}
        resource={`webHooks/${match.params.id}/results`}
      >
        <TableDatePicker
          date={dateTime}
          dateDispatcher={dateTimeDispatcher}
          setIsOpen={setIsOpenDateTime}
          isOpen={isOpenDateTime}
          field="date"
        />
      </MuiVirtualizedTable>
    </div>
  );
};

export default WebHookResults;
