import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import { OnChange } from 'react-final-form-listeners';
import { useHistory } from 'react-router-dom';

import {
  BillingDataSelector,
  MinTechCountSelectorSelector,
  PricePlansSelector,
  TechCountSelector,
} from '@store/slices/billing';
import { ThunkCreateSubscription } from '@store/slices/billing/thunks/createSubscription';
import { ThunkUpdateBillingAddress } from '@store/slices/billing/thunks';

import { BILLING } from '@constants/routes';
import useValidate from '@components/Hooks/useValidate';

import PricePlansList from '@ui/pages/billing/EditPricePlan/components/PricePlansList';
import TechnicianCount from '@components/Auth/Registration/components/TechnicianCount';
import CreditCardForm from '@components/Billing/CreditCardForm';
import ReusableButton from '@common/Button/Button';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';

import { checkCoupon } from '@components/Auth/Registration/components/helpers';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import {
  getCountriesOptions,
  getStatesOptions,
} from '@constants/countries/countries';

import useStyles from '../styles';

const DemoPricePlanForm = () => {
  const [processing, setProcessing] = useState(false);
  const [discountData, setDiscountData] = useState(null);

  const { push } = useHistory();
  const notify = useNotify();
  const dispatch = useDispatch();

  const techCount = useSelector(TechCountSelector);
  const plan = useSelector(BillingDataSelector);
  const pricePlans = useSelector(PricePlansSelector);
  const minTechCount = useSelector(MinTechCountSelectorSelector);
  const classes = useStyles();

  const {
    required,
    numberInRange,
    numberLengthRange,
    validateCreditCardNumber,
  } = useValidate();

  const getDiscountData = async coupon => {
    if (!coupon) {
      setDiscountData(null);
      return;
    }

    const couponData = await checkCoupon(coupon);

    if (couponData?.amount) {
      setDiscountData(couponData);
    } else {
      setDiscountData(undefined);
    }
  };

  const submit = values => {
    setProcessing(true);

    const payload = {
      fullName: undefined,
      phone: undefined,
      address: {
        line1: values.address,
        country: values.country,
        state: values.state,
        postalCode: values.zipCode,
        city: values.city,
      },
    };

    dispatch(ThunkCreateSubscription(values))
      .then(() => {
        dispatch(ThunkUpdateBillingAddress(payload))
          .then(() => {
            push(BILLING);
            notify('Subscription Created', 'success');
          })
          .finally(() => setProcessing(false));
      })
      .finally(() => setProcessing(false));
  };

  const validate = async values => {
    const errors = {};

    if (!values.isObsolete) {
      errors.cardHolder = required(values.cardHolder);
      errors.cardNumber = validateCreditCardNumber(values.cardNumber);
      errors.billingEmail = required(values.billingEmail);

      errors.cardExpMonth = numberInRange(values.cardExpMonth, 1, 12);
      errors.cardExpYear = numberInRange(values.cardExpYear, 22, 35);
      errors.cardCvc = numberLengthRange(values.cardCvc, 3, 4);
    }

    errors.country = required(values.country);
    errors.state = required(values.state);
    errors.zipCode = required(values.zipCode);
    errors.address = required(values.address);
    errors.city = required(values.city);

    return errors;
  };

  return (
    <Form
      onSubmit={submit}
      initialValues={{
        techCount,
        pricingPlan: plan.id,
        discountData: undefined,
      }}
      validate={validate}
      render={({ handleSubmit, form, values }) => (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '10px',
          }}
        >
          <PricePlansList form={form} values={values} />

          <form onSubmit={handleSubmit} className={classes.form}>
            <TechnicianCount
              loading={processing}
              form={form}
              values={values}
              pricePlansOptions={pricePlans}
              minTechCount={minTechCount}
              discountData={discountData}
            />

            <CreditCardForm loading={processing} />

            <FieldWrapper
              label="Code"
              labelSize={12}
              contentSize={12}
              fullWidth
              content={
                <Field
                  id="coupon"
                  name="coupon"
                  styleType="main"
                  inputView="text"
                  fullWidth
                  component={Input}
                  placeholder="Dealer Code"
                  disabled={processing}
                  helperText={
                    values?.coupon && !discountData?.amount
                      ? 'Wrong Dealer Code'
                      : null
                  }
                  meta={{
                    touched: true,
                    error:
                      values?.coupon && !discountData?.amount
                        ? 'Wrong Dealer Code'
                        : null,
                  }}
                />
              }
            />

            <OnChange name="coupon">{getDiscountData}</OnChange>

            <div style={{ margin: '10px 0px' }}>
              <div style={{ fontWeight: 'bold', margin: '5px' }}>
                Billing Address
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
              >
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Field
                    id="country"
                    name="country"
                    component={AutocompleteFormInput}
                    label="Country"
                    items={getCountriesOptions()}
                    disabled={processing}
                  />
                  <Field
                    id="state"
                    name="state"
                    component={AutocompleteFormInput}
                    label="State"
                    items={getStatesOptions(values.country)}
                    disabled={processing}
                    style={{ padding: '0 !important' }}
                    classNameWrapper={classes.dd}
                  />
                </div>
                <Field
                  id="city"
                  name="city"
                  component={Input}
                  label="City"
                  disabled={processing}
                />
                <Field
                  id="address"
                  name="address"
                  component={Input}
                  label="Address"
                  disabled={processing}
                />

                <Field
                  id="zipCode"
                  name="zipCode"
                  component={Input}
                  label="Zip Code"
                  disabled={processing}
                />
              </div>
            </div>

            <div
              style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}
            >
              <ReusableButton
                viewType="blue"
                type="submit"
                disabled={
                  processing || (values?.coupon && !discountData?.amount)
                }
                loading={processing}
                label="Checkout"
              />
            </div>
          </form>
        </div>
      )}
    />
  );
};

export default DemoPricePlanForm;
