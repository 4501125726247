import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: 'max-content',
    padding: '8px 5px 0 5px',
    fontFamily: ['Nunito', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
  },
}));

export default useStyles;
