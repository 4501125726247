import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  useTranslate,
  useRedirect,
  useNotify,
  usePermissions,
} from 'react-admin';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Field, Form } from 'react-final-form';
import { Tooltip, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { ThunkGetCrmSyncSettings } from '@store/slices/integration/thunks';
import { ThunkGetCrmOptions } from '@store/slices/crmTicket/thunks';

import Input from '@common/Input/Input';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import { INTEGRATIONS } from '@constants/routes';
import useAsync from '@services/api/common/useAsync';
import ReusableButton from '@common/Button/Button';
import { actions } from '@store/actions';
import { basePermissions } from '@constants/permissions';
import crmSources from '@constants/crmSources';

import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import iconDelete from '@assets/icons/delete.svg';
import { grayColor, greenColor, redColor } from '@constants/palette';
import {
  deleteIntegration,
  getById,
  getCrmImage,
  syncCompanies,
  updateIntegration,
  getStatusesAndTypes,
  verifyCredentials,
  syncUsers,
} from '../../helpers';
import getValidation from '../CrmInfo/validators';
import getInitialValues from '../CrmInfo/initialValues';
import useStyles from './updateFormStyles';
import FormSelector from '../CrmInfo/FormSelector';
import SyncCompaniesDialog from './SyncCompaniesDialog';

const initStatusesAndTypes = {
  statuses: [],
  types: [],
};

const UpdateForm = ({ id, setCrmType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const { permissions } = usePermissions();

  const allowUpdate = useMemo(
    () => permissions && permissions.integrations[basePermissions.update],
    [permissions],
  );

  const allowDelete = useMemo(
    () => permissions && permissions.integrations[basePermissions.delete],
    [permissions],
  );

  const [loading, setLoading] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [syncSettings, setSyncSettings] = useState(null);
  const [crmOptions, setCrmOptions] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [data, setData] = useState({});
  const getData = useCallback(() => getById(id), [id]);
  useAsync(getData, setData);
  const [displaySyncDialog, setDisplaySyncDialog] = useState(false);
  const [statusesAndTypes, setStatusesAndTypes] = useState(
    initStatusesAndTypes,
  );
  const [checkCredsColor, setCheckCredsColor] = useState(grayColor);

  useEffect(() => {
    dispatch(ThunkGetCrmOptions())
      .unwrap()
      .then(res => {
        setCrmOptions(res);
      });

    getStatusesAndTypes(id)
      .then(d => {
        const statuses = d.statuses.map(i => {
          return {
            label: i.status,
            value: i.status,
          };
        });
        const types = d.types.map(i => {
          return {
            label: i.type,
            value: i.type,
          };
        });
        setStatusesAndTypes({ statuses, types });
      })
      .catch(e => notify(e.message, 'error'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(ThunkGetCrmSyncSettings(id))
      .unwrap()
      .then(res =>
        setSyncSettings({
          crmId: res.crmId,
          statuses: res.statuses.map(i => ({ label: i, value: i })),
          types: res.types.map(i => ({ label: i, value: i })),
        }),
      );
  }, [id]);

  useEffect(() => {
    dispatch(actions.setCrmType({ crmType: data?.crmType, crmId: id }));
  }, [data, dispatch]);

  const submit = async values => {
    setLoading(true);
    const payload = {
      crmType: data.crmType,
      ...values,
    };
    if (await updateIntegration(id, payload)) {
      notify('Record updated');
    } else {
      notify('ERROR!', 'error');
    }
    setLoading(false);
  };

  const handleDeleteClick = () => setDeleteDialogOpen(true);

  const onCancelDeleteHandler = () => setDeleteDialogOpen(false);
  const onDeleteHandler = async () => {
    const result = await deleteIntegration(id);
    if (result.error) {
      notify(result.error.message, 'warning');
    }
    setDeleteDialogOpen(false);
    if (result === true) {
      notify('Integration was deleted', 'success');
      redirect(INTEGRATIONS);
    }
  };

  const syncCompaniesSubmitHandler = companyFilters => {
    setDisplaySyncDialog(false);
    setSyncing(true);
    syncCompanies(id, companyFilters)
      .then(() => notify('Successfully synchronized'))
      .catch(e => notify(e.message, 'error'))
      .finally(() => setSyncing(false));
  };

  const syncCompaniesHandler = () => {
    if (
      syncSettings &&
      crmOptions.find(i => i.crmId === syncSettings.crmId)?.type ===
        crmSources.ConnectWise.idx
    ) {
      setDisplaySyncDialog(true);
    } else {
      syncCompaniesSubmitHandler({
        statuses: [],
        types: [],
      });
    }
  };

  const syncUsersHandler = () => {
    setSyncing(true);
    syncUsers(id)
      .then(() => notify('Successfully synchronized'))
      .catch(e => notify(e.message, 'error'))
      .finally(() => setSyncing(false));
  };

  const handleCheckCredentials = async () => {
    const isValid = await verifyCredentials(id);
    setCheckCredsColor(isValid ? greenColor : redColor);
    setTimeout(function() {
      setCheckCredsColor(grayColor);
    }, 5000);
  };

  useEffect(() => {
    if (data) {
      setCrmType(data.crmType);
    }
  }, [data, setCrmType]);

  return (
    <div className={classes.container}>
      <Form
        onSubmit={submit}
        validate={values => getValidation(values, data.crmType)}
        initialValues={getInitialValues(data, data.crmType)}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.formContentWrapper}>
              <Tooltip title="Check credentials" interactive>
                <div
                  role="presentation"
                  onClick={handleCheckCredentials}
                  className={cx(
                    classes.connectionStatusWrapper,
                    classes.circle,
                  )}
                  style={{ backgroundColor: checkCredsColor }}
                />
              </Tooltip>
              {allowDelete && (
                <ActionButton
                  icon={<img src={iconDelete} alt="iconDelete" />}
                  handler={handleDeleteClick}
                  toolTip="Delete integration"
                  disabled={loading || syncing}
                  classNameWrapper={classes.deleteButtonWrapper}
                />
              )}
              <div className={classes.imageWrapper}>
                <img
                  className={classes.imageLogo}
                  src={getCrmImage(data.crmType)}
                  alt=""
                />
              </div>
              <Typography className={classes.inputLabel}>
                Integration name:
              </Typography>
              <Field
                id="name"
                name="name"
                component={Input}
                classNameWrapper={classes.inputWrapper}
                styleType="main"
                inputView="text"
                fullWidth
                placeholder="Integration name"
                disabled={loading || syncing}
              />
              <FormSelector
                crmType={data.crmType}
                loading={loading}
                classes={classes}
                useLabels
              />
            </div>
            <div className={classes.buttonWrapper}>
              {allowUpdate && (
                <>
                  <ReusableButton
                    onClick={syncCompaniesHandler}
                    size="xl"
                    classNameWrapper={classes.button}
                    disabled={loading || syncing}
                    loading={syncing}
                    label="Sync Companies"
                  />
                  <ReusableButton
                    onClick={syncUsersHandler}
                    size="xl"
                    classNameWrapper={classes.button}
                    disabled={loading || syncing}
                    loading={syncing}
                    label="Sync Users"
                  />
                </>
              )}
            </div>
            <div className={classes.buttonWrapper}>
              {allowUpdate && (
                <ReusableButton
                  type="submit"
                  size="md"
                  viewType="black"
                  classNameWrapper={classes.button}
                  disabled={loading || syncing}
                  loading={loading}
                  label="resources.buttons.submit"
                />
              )}
            </div>
          </form>
        )}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onDeleteHandler}
        onCancel={onCancelDeleteHandler}
        title={translate('resources.integrations.update.deleteTitle')}
        content={translate('resources.integrations.update.deleteContent')}
      />
      <SyncCompaniesDialog
        open={displaySyncDialog}
        onClose={() => setDisplaySyncDialog(false)}
        onSelect={syncCompaniesSubmitHandler}
        statuses={statusesAndTypes.statuses}
        types={statusesAndTypes.types}
        syncSettings={syncSettings}
      />
    </div>
  );
};

UpdateForm.propTypes = {
  id: PropTypes.string.isRequired,
  setCrmType: PropTypes.func,
};

export default UpdateForm;
