import React from 'react';
import { ReactComponent as TicketIcon } from '@assets/icons/ticket_active.svg';
import List from './CrmTickets';

const Icon = () => <TicketIcon alt="smsIcon" />;

export default {
  list: List,
  icon: Icon,
};
