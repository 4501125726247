import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import useStyles from '@common/Styles/actionFieldStyles';
import addIcon from '@assets/icons/plus.svg';
import deleteIcon from '@assets/icons/delete.svg';
import refreshIcon from '@assets/icons/refreshItem.svg';
import editIcon from '@assets/icons/edit.svg';
import ignoreIcon from '@assets/icons/hide_blue.svg';
import testIcon from '@assets/icons/test.svg';
import attachIcon from '@assets/icons/add_blue.svg';
import logIcon from '@assets/icons/document_blue.svg';
import eyeIcon from '@assets/icons/visibility_blue.svg';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import { actions } from '@store/actions';
import ItemMenu from '@common/ItemMenu/ItemMenu';
import IgnoreModal from './IgnoreModal';
import AssignParserDialog from './AssignParserDialog';
import { assignParserToLetter } from '../../../helpers';
import LogsDialog from './LogsDialog';
import TextDialog from './TextDialog';

const ActionField = ({
  record = {},
  handleUpdateClick,
  handleDeleteClick,
  handleAddClick,
  handleUpdateParserClick,
  handleTestClick,
  mailBoxId,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const [ignoreDialogOpen, setIgnoreDialogOpen] = useState(false);
  const [assignParserDialogOpen, setAssignParserDialogOpen] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [letterLogs, setLetterLogs] = useState(null);
  const [letterId, setLetterId] = useState(null);

  const menuOptions = [
    {
      icon: (
        <img
          className={classes.iconImageSize}
          src={ignoreIcon}
          alt="ignoreIcon"
        />
      ),
      caption: 'Ignore',
      handler: () => setIgnoreDialogOpen(true),
    },
    {
      icon: (
        <img className={classes.iconImageSize} src={testIcon} alt="testIcon" />
      ),
      caption: 'Test parser',
      handler: () => handleTestClick(record.messageId),
    },
    {
      icon: (
        <img
          className={classes.iconImageSize}
          src={attachIcon}
          alt="attachIcon"
        />
      ),
      caption: 'Assign parser',
      handler: () => {
        setSelectedMessageId(record.id);
        setAssignParserDialogOpen(true);
      },
    },
    {
      icon: (
        <img className={classes.iconImageSize} src={logIcon} alt="logIcon" />
      ),
      caption: 'View logs',
      handler: () => {
        setLetterLogs(record.parsingLogs);
      },
      disabled: !record.parsingLogs,
    },
    {
      icon: (
        <img className={classes.iconImageSize} src={eyeIcon} alt="text mode" />
      ),
      caption: 'View Text',
      handler: () => {
        setLetterId(record.id);
      },
    },
  ];

  const assignParserHandler = parserId => {
    assignParserToLetter(parserId, selectedMessageId)
      .then(data => {
        dispatch(actions.updateItemInList(data));
        notify('Parser attached');
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => {
        setSelectedMessageId(null);
        setAssignParserDialogOpen(false);
      });
  };

  return (
    <div className={classes.flexContainer}>
      {!!record.parserId && (
        <ActionFieldItem
          icon={editIcon}
          alt="editIcon"
          handler={() => handleUpdateParserClick(record)}
          toolTip="Edit parser"
        />
      )}
      <ActionFieldItem
        icon={addIcon}
        alt="addIcon"
        handler={() => handleAddClick(record)}
        toolTip="Create parser"
      />
      <ActionFieldItem
        icon={refreshIcon}
        alt="refreshIcon"
        handler={() => handleUpdateClick(record)}
        toolTip="ReParse message"
      />
      <ActionFieldItem
        icon={deleteIcon}
        alt="deleteIcon"
        handler={() => handleDeleteClick(record)}
        toolTip="Delete message"
      />
      <ItemMenu options={menuOptions} />
      <IgnoreModal
        open={ignoreDialogOpen}
        close={() => setIgnoreDialogOpen(false)}
        mailBoxId={mailBoxId}
        from={record.mailFrom}
        subject={record.subject}
      />
      <AssignParserDialog
        onClose={() => setAssignParserDialogOpen(false)}
        onSelect={assignParserHandler}
        open={assignParserDialogOpen}
      />
      <LogsDialog
        open={!!letterLogs}
        onClose={() => setLetterLogs(null)}
        logs={letterLogs}
      />
      <TextDialog
        open={!!letterId}
        onClose={() => setLetterId(null)}
        letterId={letterId}
      />
    </div>
  );
};

ActionField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  handleUpdateClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleAddClick: PropTypes.func.isRequired,
  handleUpdateParserClick: PropTypes.func.isRequired,
  handleTestClick: PropTypes.func.isRequired,
  mailBoxId: PropTypes.string,
};

export default ActionField;
