import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useListContext } from 'react-admin';

import {
  ThunkGetZendeskPriorities,
  ThunkGetZendeskStatuses,
  ThunkGetZendeskTypes,
} from '@store/slices/Zendesk/thunks';
import ViewAutoComplete from '@common/ViewAutoComplete/ViewAutoComplete';

import useStyles from '../../styles';

const ZendeskTicketTableFilters = ({ defaultId }) => {
  const [typeId, setTypeId] = useState({ value: 0, label: '' });
  const [statusId, setStatusId] = useState({ value: 0, label: '' });
  const [priorityId, setPriorityId] = useState({ value: 0, label: '' });

  const [options, setOptions] = useState({
    typesOptions: [],
    statusesOptions: [],
    prioritiesOptions: [],
  });

  const dispatch = useDispatch();
  const { setFilters, filterValues } = useListContext();
  const classes = useStyles();

  const handleChange = (item, setter, filterName) => {
    setter(item);
    setFilters(
      {
        [filterName]: item?.value,
      },
      filterValues,
      true,
    );
  };

  const onMount = useCallback(() => {
    Promise.all([
      dispatch(ThunkGetZendeskTypes(defaultId)).unwrap(),
      dispatch(ThunkGetZendeskStatuses(defaultId)).unwrap(),
      dispatch(ThunkGetZendeskPriorities(defaultId)).unwrap(),
    ]).then(res => {
      const responseOptions = {
        typesOptions: res[0].map(i => ({ value: i.value, label: i.name })),
        statusesOptions: res[1].map(i => ({ value: i.value, label: i.name })),
        prioritiesOptions: res[2].map(i => ({ value: i.id, label: i.name })),
      };

      setOptions(responseOptions);
    });
  }, [defaultId, dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div className={classes.filtersContainer}>
      <ViewAutoComplete
        options={options.statusesOptions}
        onChange={item => {
          handleChange(item, setStatusId, 'statusId');
        }}
        currentView={statusId}
        textFieldLabel="Status"
      />
      <ViewAutoComplete
        options={options.typesOptions}
        onChange={item => {
          handleChange(item, setTypeId, 'typeId');
        }}
        currentView={typeId}
        textFieldLabel="Type"
      />
      <ViewAutoComplete
        options={options.prioritiesOptions}
        onChange={item => {
          handleChange(item, setPriorityId, 'priorityId');
        }}
        currentView={priorityId}
        textFieldLabel="Priority"
      />
    </div>
  );
};

ZendeskTicketTableFilters.propTypes = {
  defaultId: PropTypes.number.isRequired,
};

export default ZendeskTicketTableFilters;
