import React, { useMemo } from 'react';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import TableLabel from '@common/TableLabel/TableLabel';
import useStyles from './styles';

const ClickSendPhones = () => {
  const classes = useStyles();
  const columns = useMemo(
    () => [
      {
        name: <TableLabel item={{ name: 'Number', field: 'number' }} />,
        key: 'number',
        width: 200,
        isPhoneNumber: true,
      },
      {
        name: <TableLabel item={{ name: 'Country', field: 'country' }} />,
        key: 'country',
        width: 100,
      },
      {
        name: <TableLabel item={{ name: 'Type', field: 'type' }} />,
        key: 'type',
        width: 150,
        isUpperCase: true,
      },
      {
        name: <TableLabel item={{ name: 'Status', field: 'status' }} />,
        key: 'status',
        width: 150,
      },
      {
        name: <TableLabel item={{ name: 'Tenant', field: 'tenantName' }} />,
        key: 'tenantName',
        width: 200,
      },
      {
        name: <TableLabel item={{ name: 'Category', field: 'categoryName' }} />,
        key: 'categoryName',
        width: 200,
      },
      {
        name: 'Description',
        key: 'description',
      },
    ],
    [],
  );
  return (
    <div className={classes.container}>
      <MuiVirtualizedTable
        actions={null}
        columns={columns}
        resource="MessagingPhoneNumbers/numbers/report"
      />
    </div>
  );
};

export default ClickSendPhones;
