import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useNotify, useTranslate } from 'react-admin';
import { Field, Form } from 'react-final-form';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import { checkIfTypeNameIsExist, editTypeName } from '../../helpers';

import useStyles from '../styles';

const EditTypeDialog = ({ onClose, open, type = {}, onSubmit }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);

  const validate = values => {
    const errors = { name: undefined };

    if (!values.name) {
      errors.name = translate('ra.validation.required');
    }
    return errors;
  };

  const submit = async value => {
    setLoading(true);
    checkIfTypeNameIsExist(value.name).then(res => {
      if (res !== true) {
        editTypeName(value.id, value.name)
          .then(() => {
            onClose();
          })
          .catch(err => {
            notify(err.message, 'error');
          })
          .finally(() => {
            onClose();
            onSubmit(value);
          });
      } else {
        notify('Name is already exist', 'warning');
      }
    });

    setLoading(false);
  };

  const getText = value =>
    translate('resources.types.editTypeDialog.'.concat(value));

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{getText('title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{getText('content')}</DialogContentText>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={type}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <Field
                  autoFocus
                  id="name"
                  name="name"
                  fullWidth
                  component={Input}
                  styleType="main"
                  inputView="text"
                  placeholder={getText('label')}
                  disabled={loading}
                />
              </div>
              <div style={{ textAlign: 'end' }}>
                <ReusableButton
                  label="resources.buttons.cancel"
                  onClick={onClose}
                  disabled={loading}
                  classNameWrapper={cx(
                    classes.button,
                    classes.modalCancelButtonContainer,
                  )}
                />
                <ReusableButton
                  viewType="black"
                  type="submit"
                  disabled={loading}
                  classNameWrapper={classes.button}
                  loading={loading}
                  label="resources.buttons.ok"
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

EditTypeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
};

export default EditTypeDialog;
