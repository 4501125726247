import { makeRequest } from '@services/requests/makeRequest';
import { COMMON } from '@services/requests/requestResources';

export const getTimeZones = () => makeRequest('GET', `${COMMON}/time-zones`);

export const formatDate = value =>
  makeRequest('GET', `${COMMON}/convertDate?format=${value}`);

export const beautifyPhoneNumber = value =>
  makeRequest('POST', `${COMMON}/beautifyPhoneNumber`, { data: value });

export const beautifyPhoneNumbers = payload =>
  makeRequest('POST', `${COMMON}/beautifyPhoneNumbers`, { data: payload });

export const getTenantSettings = () => makeRequest('GET', `/tenantSettings`);

export const setCommonTenantImage = payload =>
  makeRequest('POST', `/tenantSettings/common-tenant-image`, {
    data: payload,
  });

export const getOptionsList = optionType =>
  makeRequest('GET', `${COMMON}/get-enum-dropdown-values?name=${optionType}`);

export const getPusherForAuth = () =>
  makeRequest('GET', `${COMMON}/get-auth-pusher-settings`);

export default {};
