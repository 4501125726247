export const ROW_HEIGHT = 52;
export const COLUMN_HEADER_HEIGHT = 55;
export const CHECKBOXES_WIDTH = 69;
export const NONE_SELECTED = 'None';

export const tableKeys = Object.freeze({
  merakiDashboards: 'MerakiDashboards',
  hookAlertsGrid: 'HookAlertsGrid',
  alertSettingGrid: 'AlertSettingGrid',
  autotaskHooks: 'AutotaskHooks',
  connectWiseHooks: 'ConnectWiseHooks',
  sophosAlerts: 'SophosAlerts',
  sophosDashboard: 'SophosDashboard',
});

export const statuses = {
  online: { name: 'online' },
  alerting: { name: 'alerting' },
  offline: { name: 'offline' },
  dormant: { name: 'dormant' },
};

export const booleanStatuses = {
  true: { name: 'true' },
  false: { name: 'false' },
};

export const sophosEndpointTypes = {
  computer: { name: 'computer' },
  server: { name: 'server' },
  securityVm: { name: 'securityVm' },
};

export const sophosStatuses = {
  good: { name: 'good' },
  suspicious: { name: 'suspicious' },
  bad: { name: 'bad' },
  unknown: { name: 'unknown' },
};

export const sophosProducts = {
  other: { name: 'other' },
  endpoint: { name: 'endpoint' },
  server: { name: 'server' },
  mobile: { name: 'mobile' },
  encryption: { name: 'encryption' },
  emailGateway: { name: 'emailGateway' },
  webGateway: { name: 'webGateway' },
  phishThreat: { name: 'phishThreat' },
  wireless: { name: 'wireless' },
  firewall: { name: 'firewall' },
  ztna: { name: 'ztna' },
};

export const sophosSeverity = {
  high: { name: 'high' },
  medium: { name: 'medium' },
  low: { name: 'low' },
};

export const sophosCategories = {
  azure: { name: 'azure' },
  adSync: { name: 'adSync' },
  applicationControl: { name: 'applicationControl' },
  appReputation: { name: 'appReputation' },
  blockListed: { name: 'blockListed' },
  connectivity: { name: 'connectivity' },
  cwg: { name: 'cwg' },
  denc: { name: 'denc' },
  downloadReputation: { name: 'downloadReputation' },
  endpointFirewall: { name: 'endpointFirewall' },
  fenc: { name: 'fenc' },
  forensicSnapshot: { name: 'forensicSnapshot' },
  general: { name: 'general' },
  isolation: { name: 'isolation' },
  malware: { name: 'malware' },
  mtr: { name: 'mtr' },
  mobiles: { name: 'mobiles' },
  policy: { name: 'policy' },
  protection: { name: 'protection' },
  pua: { name: 'pua' },
  runtimeDetections: { name: 'runtimeDetections' },
  security: { name: 'security' },
  smc: { name: 'smc' },
  systemHealth: { name: 'systemHealth' },
  uav: { name: 'uav' },
  uncategorized: { name: 'uncategorized' },
  updating: { name: 'updating' },
  utm: { name: 'utm' },
  virt: { name: 'virt' },
  wireless: { name: 'wireless' },
  xgEmail: { name: 'xgEmail' },
  ztnaAuthentication: { name: 'ztnaAuthentication' },
  ztnaGateway: { name: 'ztnaGateway' },
  ztnaResource: { name: 'ztnaResource' },
};
