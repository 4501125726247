import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useNotify } from 'react-admin';

import { LiveChatConfigurationSelector } from '@store/slices/liveChats/selectors';
import {
  ThunkGetLiveChatConfigurations,
  ThunkLiveChatScript,
} from '@store/slices/liveChats/thunks';
import copyText from '@utils/copy';

import Loading from '@common/Loading/Loading';
import SimpleTable from '@components/Triggers/pages/Incident/components/SimpleTable';
import ReusableButton from '@common/Button/Button';
import CreateLiveChatConfigurationsDialog from '@components/LiveChatConfigurations/dialogs/CreateLiveChatConfigurationsDialog';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

import EditIcon from '@assets/icons/edit.svg';
import CopyIcon from '@assets/icons/copy_blue.svg';

import { CHAT_BOTS } from '@constants/routes';

import useStyles from './styles';

const LiveChatConfigurations = () => {
  const { push } = useHistory();
  const [loading, setLoading] = useState(true);
  const [
    createLiveChatConfigurations,
    setCreateLiveChatConfigurations,
  ] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);

  const dispatch = useDispatch();
  const classes = useStyles();
  const notify = useNotify();

  const copyScript = useCallback(
    id => {
      dispatch(ThunkLiveChatScript(id))
        .unwrap()
        .then(p => {
          copyText(p.data);
          notify('Script copied to the clipboard');
        });
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'PSA Name',
        value: 'crmName',
      },
      {
        label: 'Widget Title',
        value: 'widgetTitle',
      },
      {
        label: 'Actions',
        handler: item => (
          <div>
            <ActionFieldItem
              icon={EditIcon}
              handler={() => {
                setSelectedItem(item);
              }}
              toolTip="Edit"
            />

            <ActionFieldItem
              icon={CopyIcon}
              handler={() => {
                copyScript(item.id);
              }}
              toolTip="Copy Url"
            />
          </div>
        ),
      },
    ],
    [],
  );

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetLiveChatConfigurations());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <div>
        <ReusableButton
          label="Create Configuration"
          classNameWrapper={classes.buttonMargin}
          onClick={() => {
            setCreateLiveChatConfigurations(true);
          }}
        />
        <ReusableButton
          label="Chat Sequences"
          classNameWrapper={classes.buttonMargin}
          onClick={() => {
            push(CHAT_BOTS);
          }}
        />
      </div>
      <SimpleTable selector={LiveChatConfigurationSelector} columns={columns} />

      {createLiveChatConfigurations && (
        <CreateLiveChatConfigurationsDialog
          setOpen={setCreateLiveChatConfigurations}
          open={createLiveChatConfigurations}
        />
      )}

      {selectedItem && (
        <CreateLiveChatConfigurationsDialog
          setOpen={setSelectedItem}
          open={!!selectedItem}
          item={selectedItem}
        />
      )}
    </div>
  );
};

export default LiveChatConfigurations;
