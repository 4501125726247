import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import { actions } from '@store/actions';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconDelete from '@assets/icons/delete.svg';
import iconEdit from '@assets/icons/edit.svg';
import { useRedirect, useTranslate } from 'react-admin';
import TableLabel from '@common/TableLabel/TableLabel';
import TableDropDown from '@common/TableDropDown/TableDropDown';
import deviceMonitorTypes, {
  deviceMonitorRegions,
} from '@constants/deviceMonitorTypes';
import { DEVICE_MONITORS, DEVICE_MONITORS_CREATE } from '@constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { listSelector } from '@store/selectors';
import iconVisible from '@assets/icons/visibility.svg';
import iconNotification from '@assets/icons/notifications.svg';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import Statuses from '@components/DeviceMonitors/components/Details/Statuses';
import Percentage from '@components/DeviceMonitors/components/Details/Percentage';
import BagelContainer from '@components/DeviceMonitors/components/BagelContainer';
import SelectGroup from '@components/DeviceMonitors/components/SelectGroup';
import localStorageConst from '@constants/localStorage';
import localStorage from '@services/localStorage';
import UploadDialog from '@components/UploadFileDialog/Dialog';
import { TableBody } from '@material-ui/core';
import DetailsDialog from '@components/UploadFileDialog/DetailsDialog/DetailsDialog';
import TableHeader from '@components/UploadFileDialog/DetailsDialog/TableHeader';
import importTypes from '@constants/importTypes';
import DetailsDialogItem from '../detailsDialog/DetailsDialogItem';
import Types from '../components/Details/Types';
import ListActions from '../components/ListActions';
import BulkActions from '../components/BulkActions';
import toggleEnabled, { deleteDeviceMonitor } from '../helpers';
import ButtonGroup from '../components/ButtonGroup';
import useDeleteRecord from '../hook/useDeleteRecord';
import RunningState from '../components/RunningState';
import events from '../components/Details/events';
import useStyles from './styles';

const headers = [
  'Status',
  'Company',
  'Device Name',
  'Device Url',
  'Device Type',
  'Site',
  'Region',
  'Period (min)',
  'Errors',
];

const labelHandlerStatus = key => events[key].name;
const valueHandlerStatus = key => events[key].enumKey;

const List = () => {
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const list = useSelector(listSelector);
  const resource = 'deviceMonitors';

  const classes = useStyles();

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [modalDetails, setModalDetails] = useState({
    open: false,
    data: {},
  });

  const translate = useTranslate();

  const {
    onCancel,
    open,
    loading,
    handleDeleteIcon,
    onDelete,
  } = useDeleteRecord(deleteDeviceMonitor);

  const handleMakeDefault = async (id, isActive) => {
    try {
      await toggleEnabled(id, !isActive);
      const el = list.find(item => item.id === id);
      el.enabled = !el.enabled;
      dispatch(actions.saveList([...list]));
    } catch (e) {
      console.error(e.message);
    }
  };

  const handleAddRecord = () => redirect(DEVICE_MONITORS_CREATE);

  const handleEditRecord = id => redirect(`${DEVICE_MONITORS}/${id}`);

  const handleDetails = async id =>
    redirect(`${DEVICE_MONITORS}/${id}/details`);

  const handleThreshold = async id =>
    redirect(`notifications/monitor?id=${id}`);
  const [groupByValue, setGroupByValue] = useState(
    localStorage.getItem(localStorageConst.SELECTED_GROUPBY),
  );

  return (
    <>
      <div className={classes.listContainer}>
        <MuiVirtualizedTable
          bulkActions={<BulkActions />}
          groupByValue={groupByValue?.value}
          resource={resource}
          actions={
            <ListActions
              handleAddRecord={handleAddRecord}
              handleUploadState={setOpenUploadModal}
              withAuthorize
            >
              <SelectGroup
                groupByValue={groupByValue}
                setGroupByValue={setGroupByValue}
              />
            </ListActions>
          }
          columns={[
            {
              name: (
                <TableDropDown
                  item={{ field: 'lastEventType', name: 'Status' }}
                  options={events}
                  labelHandler={labelHandlerStatus}
                  valueHandler={valueHandlerStatus}
                />
              ),
              key: 'lastEventType',
              accessor: row => (
                <Statuses
                  lastStatus={row.lastEventType}
                  successRate={row.successRate}
                />
              ),
              width: 100,
            },
            {
              name: 'Percentage',
              key: 'percentage',
              accessor: row => <Percentage successRate={+row.successRate} />,
              width: 100,
            },
            {
              name: <TableLabel item={{ name: 'Company', field: 'company' }} />,
              key: 'company',
            },
            {
              name: <TableLabel item={{ name: 'Site', field: 'site' }} />,
              key: 'site',
            },
            {
              name: (
                <TableLabel
                  item={{ name: 'Device Name', field: 'deviceName' }}
                />
              ),
              key: 'deviceName',
            },
            {
              name: (
                <TableDropDown
                  item={{ field: 'type', name: 'Type' }}
                  options={deviceMonitorTypes}
                  valueHandler={key => deviceMonitorTypes[key].id}
                  labelHandler={key => deviceMonitorTypes[key].name}
                />
              ),
              key: 'type',
              accessor: row => <Types record={row} />,
            },
            {
              name: <TableLabel item={{ name: 'Url', field: 'url' }} />,
              key: 'url',
            },
            {
              name: (
                <TableDropDown
                  item={{ field: 'region', name: 'Region' }}
                  options={deviceMonitorRegions}
                  valueHandler={key => deviceMonitorRegions[key].id}
                  labelHandler={key => deviceMonitorRegions[key].name}
                />
              ),
              accessor: row => row.region,
              key: 'region',
            },
            {
              name: 'Period [min]',
              key: 'period',
              sort: 'period',
            },
            {
              name: 'Latency [ms]',
              key: 'latency',
              sort: 'latency',
            },
            {
              name: 'Running state',
              key: 'enabled',
              sort: 'enabled',
              accessor: row => (
                <RunningState handleClick={handleMakeDefault} row={row} />
              ),
            },
            {
              name: 'Actions',
              key: 'actions',
              accessor: row => (
                <ButtonGroup>
                  <ActionButton
                    icon={<img src={iconNotification} alt="iconNotification" />}
                    handler={() => handleThreshold(row.id)}
                    toolTip="Alerts"
                  />

                  <ActionButton
                    icon={<img src={iconVisible} alt="iconVisible" />}
                    handler={() => handleDetails(row.id)}
                    toolTip="Display Monitor details"
                  />

                  <ActionButton
                    handler={() => handleEditRecord(row.id)}
                    icon={<img src={iconEdit} alt="iconEdit" />}
                  />

                  <ActionButton
                    handler={() => handleDeleteIcon(row.id)}
                    icon={<img src={iconDelete} alt="iconDelete" />}
                  />
                </ButtonGroup>
              ),
            },
          ]}
        >
          <BagelContainer />
          <UploadDialog
            type={importTypes.DeviceMonitor}
            setOpen={setOpenUploadModal}
            setModalDetails={setModalDetails}
            open={openUploadModal}
          />
        </MuiVirtualizedTable>
      </div>
      <DetailsDialog
        statistic={modalDetails.data.statistic}
        open={modalDetails.open}
        setModalDetails={setModalDetails}
      >
        <>
          <TableHeader headers={headers} />
          <TableBody>
            {modalDetails.data?.importedRows?.map(row => (
              <DetailsDialogItem key={row} row={row} />
            ))}
          </TableBody>
        </>
      </DetailsDialog>
      <ConfirmDialog
        open={open}
        onCancel={onCancel}
        onAccept={onDelete}
        loading={loading}
        title={translate('resources.deviceMonitors.modal.deleteTitle')}
        content={translate('resources.deviceMonitors.modal.deleteContent')}
      />
    </>
  );
};

List.propTypes = {
  resource: PropTypes.string,
};

export default List;
