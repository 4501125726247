import { requiredValidator } from '@utils/validators';
import { deviceThresholdTypes } from '@components/DeviceMonitors/components/helpers';
import { dmSettingsValidator } from './validators';

const loadData = data => {
  return {
    id: data?.id || 0,
    lastN: data?.lastN || 1,
    type:
      (data?.type !== undefined && String(data.type)) ||
      deviceThresholdTypes[0].value,
    value: data?.value || 1,
    deviceMonitorId: data?.deviceMonitorId || 0,
    notifyAboutRecovering: data?.notifyAboutRecovering || false,
    isEmail: data?.isEmail || false,
    isSlack: data?.isSlack || false,
    isSms: data?.isSms || false,
    isTeams: data?.isTeams || false,
    isTicket: data?.isTicket || false,
    isWebHook: data?.isWebHook || false,
  };
};

export const loadGroupData = data => {
  return {
    id: data?.id || 0,
    lastN: data?.lastN || 1,
    type:
      (data?.type !== undefined && String(data.type)) ||
      deviceThresholdTypes[0].value,
    value: data?.value || 1,
    companies: data?.companies || [],
    notifyAboutRecovering: data?.notifyAboutRecovering || false,
    isEmail: data?.isEmail || false,
    isSlack: data?.isSlack || false,
    isSms: data?.isSms || false,
    isTeams: data?.isTeams || false,
    isTicket: data?.isTicket || false,
    isWebHook: data?.isWebHook || false,
  };
};

export const handleErrors = values => {
  return {
    deviceMonitorId: requiredValidator(values.deviceMonitorId),
    lastN: dmSettingsValidator(values.lastN),
    value: dmSettingsValidator(values.value, values.type),
    type: requiredValidator(values.type),
  };
};

export const handleGroupErrors = values => {
  return {
    lastN: dmSettingsValidator(values.lastN),
    value: dmSettingsValidator(values.value, values.type),
    type: requiredValidator(values.type),
  };
};

export const handleDisabled = form => {
  if (form.getFieldState('type')?.value === '0') {
    form.change('value', null);
    return true;
  }
  return false;
};
export default loadData;
