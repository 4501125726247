import React from 'react';
import PropTypes from 'prop-types';
import { grayColor, greenColor } from '@constants/palette';
import useStyles from './styles';

const StatusCircle = ({ row, handleClick, rowKey = 'enabled' }) => {
  const classes = useStyles();

  return (
    <div
      role="presentation"
      onClick={() => handleClick(row.id, row[rowKey], row)}
      className={classes.circle}
      style={
        row[rowKey]
          ? { backgroundColor: `${greenColor}` }
          : { backgroundColor: `${grayColor}` }
      }
    />
  );
};

StatusCircle.propTypes = {
  row: PropTypes.objectOf(PropTypes.any),
  rowKey: PropTypes.string,
  handleClick: PropTypes.func,
};

export default StatusCircle;
