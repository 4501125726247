import React from 'react';
import PropTypes from 'prop-types';
import TableSortLabel from '@common/TableLabel/TableSortLabel';
import TableLabel from '@ui/components/common/TableLabel/TableLabel';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import KaseyaTicketTableFilters from '@components/PsaTickets/components/Kaseya/KaseyaTicketTableFilters';
import KaseyaTicketTableActions from '@components/PsaTickets/components/Kaseya/KaseyaTicketTableActions';

const KaseyaTicketTable = ({ defaultId, crmId }) => {
  const columns = [
    {
      name: <TableLabel item={{ field: 'ticketNumber', name: 'Id' }} />,
      key: 'ticketNumber',
    },
    {
      name: <TableLabel item={{ field: 'title', name: 'Title' }} />,
      key: 'title',
    },
    {
      name: <TableLabel item={{ field: 'account', name: 'Account' }} />,
      key: 'account',
    },
    {
      name: <TableSortLabel item={{ field: 'status', name: 'Status' }} />,
      key: 'status',
    },
    {
      name: <TableSortLabel item={{ field: 'queue', name: 'Queue' }} />,
      key: 'queue',
    },
    {
      name: <TableSortLabel item={{ field: 'type', name: 'Type' }} />,
      key: 'type',
    },
    {
      name: (
        <TableSortLabel item={{ field: 'issueType', name: 'Issue Type' }} />
      ),
      key: 'issueType',
    },
    {
      name: (
        <TableSortLabel
          item={{ field: 'issueSubType', name: 'Sub Issue Type' }}
        />
      ),
      key: 'issueSubType',
    },
    {
      name: <TableLabel item={{ field: 'contact', name: 'Contact' }} />,
      key: 'contact',
    },
    {
      name: <TableLabel item={{ field: 'assignee', name: 'Assignee' }} />,
      key: 'assignee',
    },
    {
      name: 'Actions',
      key: 'actions',
      accessor: record => (
        <KaseyaTicketTableActions
          record={record}
          crmDefaultId={defaultId}
          crmId={crmId}
        />
      ),
    },
  ];

  return (
    <div>
      <MuiVirtualizedTable
        actions={<></>}
        columns={columns}
        resource={`TicketUpdate/kaseya/${defaultId}/tickets`}
        defaultSort={{ field: 'id', order: 'DESC' }}
      >
        <KaseyaTicketTableFilters crmDefaultId={defaultId} />
      </MuiVirtualizedTable>
    </div>
  );
};

KaseyaTicketTable.propTypes = {
  defaultId: PropTypes.number.isRequired,
};

export default KaseyaTicketTable;
