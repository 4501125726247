import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { PSA_CONTACTS } from '@services/requests/requestResources';
import { ThunkGetKaseyaIntegrationCompanies } from '@store/slices/integration/thunks';

import { getKaseyaTableColumns } from '@components/PsaContacts/constants';

import Table from '@components/Table';
import PsaContactsTableBulkActions from '@components/PsaContacts/components/PsaContactsTableBulkActions';
import Loading from '@common/Loading/Loading';

import crmSources from '@constants/crmSources';

const KaseyaContacts = ({ psaId }) => {
  const [loading, setLoading] = useState(true);

  const [companies, setCompanies] = useState([]);

  const dispatch = useDispatch();

  const resource = useMemo(() => `${PSA_CONTACTS}/kaseya/${psaId}`, [psaId]);

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetKaseyaIntegrationCompanies({ crmId: psaId }))
      .unwrap()
      .then(res => {
        setCompanies(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, psaId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <Table
      resource={resource}
      columns={getKaseyaTableColumns(psaId, companies)}
      bulkActions={
        <PsaContactsTableBulkActions
          psaId={psaId}
          psaName={crmSources.ConnectWise.name}
        />
      }
    />
  );
};

KaseyaContacts.propTypes = {
  psaId: PropTypes.number.isRequired,
};

export default KaseyaContacts;
