import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    height: '90vh',
    overflowY: 'auto',
  },
}));

export default useStyles;
