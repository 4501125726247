import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  itemStyle: {
    color: props => props.color,
    fontWeight: 'bold',
  },
}));

export default useStyles;
