import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import EditIcon from '@assets/icons/edit.svg';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

import { CRM_TICKET_EDIT } from '@constants/routes';
import crmSources from '@constants/crmSources';
import UserIcon from '@assets/icons/user.svg';
import SMSIcon from '@assets/icons/sms.svg';

import ItemMenu from '@common/ItemMenu/ItemMenu';
import AssignContactDialog from '@components/PsaTickets/dialogs/AssignContactDialog';
import CreateMessagingChannelDialog from '@components/Messaging/dialogs/CreateMessagingChannelDialog';
import ShieldPersonIcon from '@assets/icons/shield_person.svg';
import SyncIcon from '@assets/icons/secureDataSend.svg';
import PodDialog from '@components/PodDialog';

import useStyles from '../../styles';

const HaloTicketTableActions = ({ record, crmDefaultId, crmId }) => {
  const classes = useStyles();
  const { push } = useHistory();

  const [openAssignContactDialog, setOpenAssignContactDialog] = useState(false);
  const [openCreateChannelDialog, setOpenCreateChannelDialog] = useState(false);
  const [openSecureDialog, setOpenSecureDialog] = useState(false);
  const [openPodDialog, setOpenPodDialog] = useState(false);

  const handleEditRedirect = () => {
    push(
      CRM_TICKET_EDIT.replace(':crmDefaultId', crmDefaultId)
        .replace(':ticketId', record.id)
        .replace(':crmName', crmSources.Halo.name),
    );
  };

  const menuOptions = useMemo(
    () => [
      {
        icon: <img src={UserIcon} alt="assignContact" />,
        caption: 'Assign Contact',
        handler: () => {
          setOpenAssignContactDialog(true);
        },
      },
      {
        icon: <img src={SMSIcon} alt="createChannel" />,
        caption: 'Add SMS Channel',
        handler: () => {
          setOpenCreateChannelDialog(true);
        },
      },
    ],
    [],
  );

  const handleAssignContactClick = useCallback(() => {
    setOpenAssignContactDialog(true);
    setOpenCreateChannelDialog(false);
  }, []);

  return (
    <div className={classes.tableActions}>
      <>
        <ActionFieldItem
          alt="Edit"
          handler={handleEditRedirect}
          toolTip="Edit"
          icon={EditIcon}
        />
        <ActionFieldItem
          alt="Open POD"
          handler={() => {
            setOpenPodDialog(true);
          }}
          toolTip="Open POD"
          icon={ShieldPersonIcon}
          ignoreDefaultIconSize
        />
        <ActionFieldItem
          alt="Secure Data"
          handler={() => setOpenSecureDialog(true)}
          toolTip="Secure data"
          icon={SyncIcon}
          ignoreDefaultIconSize
          disabled={!record?.userId}
        />
      </>
      <ItemMenu options={menuOptions} itemsHeightMultiplier={10} openOnHover />

      {openAssignContactDialog && (
        <AssignContactDialog
          open={openAssignContactDialog}
          setOpen={setOpenAssignContactDialog}
          record={record}
          crmDefaultId={crmDefaultId}
          crmName={crmSources.Halo.label}
        />
      )}

      {openCreateChannelDialog && (
        <CreateMessagingChannelDialog
          open={openCreateChannelDialog}
          setOpen={setOpenCreateChannelDialog}
          record={record}
          crmDefaultId={crmDefaultId}
          crmType={crmSources.Halo.name}
          handleAssignContactClick={handleAssignContactClick}
        />
      )}

      {openPodDialog && (
        <PodDialog
          itemId={record.id}
          onClose={() => {
            setOpenPodDialog(false);
          }}
          itemType="ticket"
          psaId={crmId}
          psa={crmSources.Halo.label}
        />
      )}

      {openSecureDialog && (
        <PodDialog
          itemId={record.id}
          onClose={() => {
            setOpenSecureDialog(false);
          }}
          itemType="ticket"
          psaId={crmId}
          psa={crmSources.Halo.label}
          page="secureData"
        />
      )}
    </div>
  );
};

HaloTicketTableActions.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    userId: PropTypes.number,
  }),
  crmDefaultId: PropTypes.number,
  crmId: PropTypes.number,
};

export default HaloTicketTableActions;
