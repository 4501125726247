import React, { useEffect, useMemo, useState } from 'react';
import { Form as RaForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import ReusableButton from '@common/Button/Button';
import iconPlus from '@assets/icons/plusGrey.svg';
import { useHistory } from 'react-router-dom';
import Paper from '@common/Paper/Paper';
import cx from 'classnames';

import { useNotify, useTranslate } from 'react-admin';
import CreatedItem from '@components/Settings/LocaleCompany/components/CreatedItem';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import deleteIcon from '@assets/icons/delete.svg';
import CrmList from '@components/Settings/LocaleCompany/components/CrmList';
import CrmHeader from '@components/Settings/LocaleCompany/components/CrmHeader';
import ImportCrmNamesWizard from '@components/Settings/LocaleCompany/components/importWizard/DataSources/ImportCrmNamesWizard';
import Loading from '@ui/components/common/Loading/Loading';
import {
  getLocalCompanies,
  getLocalCompaniesCrmList,
  getLocalCrmList,
  postLocaleCompanies,
} from '../helpers';
import { getNewRecordWithBindings, initData, nameValidate } from './helpers';
import ImportDataViewNamesWizard from './importWizard/DataViews/ImportDataViewNamesWizard';
import useStyles from '../styles';

const LocalCompany = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const { goBack } = useHistory();

  const [data, setData] = useState(initData);
  const crmLength = useMemo(() => data.localCrmList.length, [
    data.localCrmList,
  ]);
  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [crmWizardOpen, setCrmWizardOpen] = useState(false);
  const [dataViewWizardOpen, setDataViewWizardOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSubLoading(true);
    Promise.all([
      getLocalCompanies(),
      getLocalCrmList(),
      getLocalCompaniesCrmList(),
    ])
      .then(([localCompanies, localCrmList, localCompaniesCrmList]) => {
        setData({
          localCompanies,
          localCrmList,
          localCompaniesCrmList,
        });
        setCompanies(localCompanies.sort((a, b) => (a.name > b.name ? 1 : -1)));
      })
      .catch(e => console.error(e.message))
      .finally(() => {
        setSubLoading(false);
      });
  }, []);

  const submit = values => {
    setLoading(true);
    postLocaleCompanies(values.companies)
      .then(() => notify('Created'))
      .catch(e => {
        console.error(e.message);
        notify('Something went wrong', 'error');
      })
      .finally(() => setLoading(false));
  };

  const onCompaniesSelected = (names, push) => {
    names.map(name =>
      push(
        'companies',
        getNewRecordWithBindings(name, data.localCompaniesCrmList),
      ),
    );
    setCrmWizardOpen(false);
    setDataViewWizardOpen(false);
  };

  const reverseCompaniesOrder = () => {
    const newArr = [...companies.reverse()];
    setCompanies(newArr);
  };

  useEffect(() => {
    if (search !== '') {
      setCompanies(data.localCompanies.filter(c => c.name.includes(search)));
    } else {
      setCompanies(data.localCompanies);
    }
  }, [search]);

  const classes = useStyles({ count: crmLength });
  if (subLoading) return <Loading />;
  return (
    <Paper classNameContainer={classes.paperContainer}>
      <RaForm
        onSubmit={submit}
        mutators={arrayMutators}
        initialValues={{
          companies,
          localCrmList: data.localCrmList,
        }}
        render={({
          handleSubmit,
          form: {
            change,
            mutators: { push },
          },
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.container}>
                <CrmHeader
                  crmList={data.localCrmList}
                  search={search}
                  setSearch={setSearch}
                  reverseOrder={reverseCompaniesOrder}
                  crmLength={crmLength}
                />
                <FieldArray name="companies" validate={nameValidate}>
                  {({ fields }) => {
                    return fields.map((item, index) => (
                      <div key={item}>
                        <div className={classes.row}>
                          <CreatedItem
                            name={`${item}.name`}
                            crmLength={crmLength}
                          />
                          <CrmList
                            localCompaniesCrmList={data.localCompaniesCrmList}
                            localCrmList={data.localCrmList}
                            item={values.companies[index]}
                            path={item}
                            change={change}
                            crmLength={crmLength}
                          />
                          <ActionFieldItem
                            icon={deleteIcon}
                            alt="deleteIcon"
                            handler={() => fields.remove(index)}
                            toolTip="Delete message"
                            classNameWrapper={classes.deleteIcon}
                          />
                        </div>
                      </div>
                    ));
                  }}
                </FieldArray>
              </div>
              <div className={classes.buttonContainer}>
                <ReusableButton
                  size="md"
                  icon={null}
                  classNameWrapper={classes.buttonAddWrapper}
                  onClick={() => push('companies', getNewRecordWithBindings())}
                  disabled={loading}
                >
                  <>
                    <img src={iconPlus} alt="iconPlus" />
                    {translate('common.create')}
                  </>
                </ReusableButton>
                <ReusableButton
                  size="lg"
                  icon={null}
                  classNameWrapper={classes.buttonAddWrapper}
                  onClick={() => setCrmWizardOpen(true)}
                  disabled={loading}
                  label="resources.localCompany.importFromDataSources"
                />
                <ReusableButton
                  size="lg"
                  icon={null}
                  classNameWrapper={classes.buttonAddWrapper}
                  onClick={() => setDataViewWizardOpen(true)}
                  disabled={loading}
                  label="resources.localCompany.importFromDataViews"
                />
              </div>
              <div
                className={cx(
                  classes.buttonContainer,
                  classes.buttonContainerLast,
                )}
              >
                <ReusableButton
                  size="md"
                  icon={null}
                  classNameWrapper={classes.buttonAddWrapper}
                  label="resources.buttons.back"
                  onClick={() => goBack()}
                  disabled={loading}
                />
                <ReusableButton
                  size="md"
                  type="submit"
                  viewType="black"
                  icon={null}
                  loading={loading}
                  label="resources.buttons.submit"
                  classNameWrapper={classes.buttonAddWrapper}
                />
              </div>
              <ImportCrmNamesWizard
                onSelect={names => onCompaniesSelected(names, push)}
                setOpen={setCrmWizardOpen}
                open={crmWizardOpen}
              />
              <ImportDataViewNamesWizard
                open={dataViewWizardOpen}
                setOpen={setDataViewWizardOpen}
                onSelect={names => onCompaniesSelected(names, push)}
              />
            </form>
          );
        }}
      />
    </Paper>
  );
};

export default LocalCompany;
