import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import { Tooltip } from '@material-ui/core';

import { ReactComponent as EditIcon } from '@assets/icons/edit.svg';

import Loading from '@ui/components/common/Loading/Loading';
import ReusableButton from '@ui/components/common/Button/Button';
import TypeValuesItem from './TypeValuesItem';
import EditTypeDialog from './dialogs/EditTypeDialog';
import CreateTypeValueDialog from './dialogs/CreateTypeValueDialog';

import { getTypeValue } from '../helpers';
import useStyles from './styles';

const TypeValues = React.memo(
  ({ selectedType, types, setTypes, setSelectedType }) => {
    const [loading, setLoading] = useState(false);
    const [typeValues, setTypeValues] = useState([]);
    const [openEditTypeDialog, setOpenEditTypeDialog] = useState(false);
    const [openCreateTypeValueDialog, setOpenCreateTypeValueDialog] = useState(
      false,
    );

    const notify = useNotify();
    const classes = useStyles();

    const handleNameEditClick = useCallback(
      item => {
        setTypes(types.map(type => (type.id === item.id ? item : type)));
        setSelectedType(item);
      },
      [setTypes, types, setSelectedType],
    );

    const onMount = useCallback(() => {
      if (selectedType !== 0) {
        setLoading(true);
        getTypeValue(selectedType.id)
          .then(res => {
            setTypeValues(res);
          })
          .catch(err => {
            notify(err.message, 'error');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }, [selectedType, notify]);

    useEffect(() => {
      onMount();
    }, [onMount]);

    if (loading) return <Loading />;
    return (
      <div className={classes.typeValuesListContainer}>
        {selectedType !== 0 && (
          <>
            <div className={classes.typeValuesListHeader}>
              <span>{selectedType.name}</span>
              {!selectedType.readOnly && (
                <Tooltip title="Edit Name">
                  <EditIcon
                    alt="edit"
                    onClick={() => {
                      setOpenEditTypeDialog(true);
                    }}
                  />
                </Tooltip>
              )}
              <ReusableButton
                label="Create"
                onClick={() => {
                  setOpenCreateTypeValueDialog(true);
                }}
              />
            </div>
            {typeValues.map(typeValue => (
              <TypeValuesItem
                key={typeValue.id}
                {...typeValue}
                onEdit={item => {
                  setTypeValues(
                    typeValues.map(type => (type.id === item.id ? item : type)),
                  );
                }}
                onDelete={item => {
                  setTypeValues(
                    typeValues.filter(type => type.id !== item.id && type),
                  );
                }}
              />
            ))}
          </>
        )}

        {openEditTypeDialog && (
          <EditTypeDialog
            open={openEditTypeDialog}
            type={selectedType}
            onClose={() => {
              setOpenEditTypeDialog(false);
            }}
            onSubmit={value => {
              handleNameEditClick(value);
            }}
          />
        )}

        {openCreateTypeValueDialog && (
          <CreateTypeValueDialog
            open={openCreateTypeValueDialog}
            onClose={() => {
              setOpenCreateTypeValueDialog(false);
            }}
            typeId={selectedType.id}
            onSubmit={item => {
              setOpenCreateTypeValueDialog(false);
              setTypeValues([...typeValues, item]);
            }}
          />
        )}
      </div>
    );
  },
);

TypeValues.propTypes = {
  selectedType: PropTypes.objectOf(PropTypes.any),
  setTypes: PropTypes.func,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      readOnly: PropTypes.bool,
    }),
  ),
  setSelectedType: PropTypes.func,
};
export default TypeValues;
