import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useNotify, useSafeSetState, useTranslate } from 'react-admin';
import { Field, Form } from 'react-final-form';
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';

import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import useStyles from '../styles';
import { createType } from '../../helpers';

const CreateTypeDialog = props => {
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles();

  const { onSubmit, onClose, open } = props;

  const [loading, setLoading] = useSafeSetState(false);
  const validate = values => {
    const errors = { name: undefined };

    if (!values.name) {
      errors.name = translate('ra.validation.required');
    }
    return errors;
  };

  const submit = async value => {
    setLoading(true);
    createType(value)
      .then(res => {
        notify('Record created');
        onSubmit(res);
        onClose();
      })
      .catch(err => {
        notify(err.message, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getText = value =>
    translate('resources.types.createTypeDialog.'.concat(value));
  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{getText('title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{getText('content')}</DialogContentText>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <Field
                  autoFocus
                  id="name"
                  name="name"
                  fullWidth
                  component={Input}
                  styleType="main"
                  inputView="text"
                  placeholder={getText('label')}
                  disabled={loading}
                />
              </div>
              <div style={{ textAlign: 'end' }}>
                <ReusableButton
                  label="resources.buttons.cancel"
                  onClick={onClose}
                  disabled={loading}
                  classNameWrapper={cx(
                    classes.button,
                    classes.modalCancelButtonContainer,
                  )}
                />
                <ReusableButton
                  viewType="black"
                  type="submit"
                  disabled={loading}
                  classNameWrapper={classes.button}
                  loading={loading}
                  label="resources.buttons.ok"
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

CreateTypeDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CreateTypeDialog;
