import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useAuthenticated,
  useAuthProvider,
  useGetOne,
  useRedirect,
} from 'react-admin';
import _ from 'lodash';

import getData from '@services/api/common/getData';
import { defaultPalette, setPalette } from '@services/themes/mainTheme';
import { actions } from '@store/actions';
import { userDataSelector } from '@store/selectors';
import appConfig from '@configs/appConfig';
import { getTenantPalettes } from '@components/Settings/CustomPalette/helpers';

import SimpleDialog from '@ui/components/common/Dialog/SimpleDialog';

import { pricePlanMyPlan } from '@constants/appRoutes';
import { BILLING } from '@constants/routes';
import resources from '@constants/resources';

import useStyles from './styles';

const getPricePlanInfo = () =>
  getData(`${appConfig.baseUrl}${pricePlanMyPlan}`);

const UserName = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const redirect = useRedirect();

  const authProvider = useAuthProvider();

  const id = authProvider.getUserId();

  useAuthenticated();

  const { data, loading } = useGetOne(resources.user, id);

  const userData = useSelector(userDataSelector);

  useEffect(() => {
    getPricePlanInfo()
      .then(d => dispatch(actions.setPricePlan(d)))
      .catch(e => console.error(e.message));
    getTenantPalettes()
      .then(d =>
        setPalette(
          _.isEmpty(JSON.parse(d.palette))
            ? defaultPalette
            : JSON.parse(d.palette),
        ),
      )
      .catch(e => console.error(e.message));
  }, [dispatch]);

  useEffect(() => {
    if (data && !data.isSubscriptionEnabled) {
      setOpen(true);
    } else {
      localStorage.setItem('tenantId', data?.tenantId);
      localStorage.setItem('roleId', data?.roleId);
      localStorage.setItem('profileImage', data?.imageUri);
      dispatch(actions.setCurrentUser(data));
    }
  }, [data, dispatch]);

  if (loading) return null;

  const userName = userData?.username || data?.username;

  return (
    <div className={classes.container}>
      <span>{userName}</span>

      <SimpleDialog
        open={open}
        setOpen={setOpen}
        title="Your subscription is inactive"
        children={
          <div>
            Your subscription is inactive. One of the possible reasons: Invalid
            credit card data. <br />
            Try to update your credit card data in the{' '}
            <a
              className={classes.link}
              onClick={() => {
                redirect(BILLING.concat('?page=creditCard'));
                setOpen(false);
              }}
            >
              Credit Card page.
            </a>
          </div>
        }
      />
    </div>
  );
};

export default UserName;
