import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@material-ui/core';

const TemplateMenu = ({
  anchorEl,
  setAnchorEl,
  form,
  name,
  templateVariables,
  overrideValue = false,
}) => {
  const applyValue = i => {
    const val = form.getFieldState(name).value || '';
    let newValue;
    if (Array.isArray(val)) {
      newValue = overrideValue ? [i.value] : [...val, i.value];
    } else {
      newValue = overrideValue ? i.value : `${val}${i.value}`;
    }
    form.change(name, newValue);
  };

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
    >
      {templateVariables.map(i => (
        <MenuItem
          key={i.value}
          onClick={() => {
            setAnchorEl(null);
            applyValue(i);
          }}
        >
          {i.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

TemplateMenu.propTypes = {
  anchorEl: PropTypes.objectOf(PropTypes.any),
  setAnchorEl: PropTypes.func,
  form: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string,
  templateVariables: PropTypes.arrayOf(PropTypes.any),
};

export default TemplateMenu;
