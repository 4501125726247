import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';

import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';

import { ReactComponent as EditIcon } from '@assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { useHistory } from 'react-router-dom';
import { EDIT_CLIENT_PORTAL_USER } from '@constants/routes';

import { ThunkDeleteClientPortalUser } from '@store/slices/clientPortalAdmin/thunks';

import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';

import useStyles from './styles';

const ClientPortalUserActions = ({ id, psaId }) => {
  const { push } = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const list = useSelector(listSelector);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleEdit = () => {
    push(
      EDIT_CLIENT_PORTAL_USER.replace(':userId', id).replace(':psaId', psaId),
    );
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteUser = () => {
    setDeleteDialogOpen(false);
    dispatch(ThunkDeleteClientPortalUser(id))
      .unwrap()
      .then(result => {
        if (result) {
          const newList = list.filter(item => item.id !== id);
          dispatch(actions.saveList(newList));
          notify('Successfully removed');
        } else {
          notify('Unable to delete item', 'error');
        }
      });
  };

  return (
    <div>
      <Tooltip title="Edit">
        <EditIcon className={classes.actionButton} onClick={handleEdit} />
      </Tooltip>
      <Tooltip title="Delete">
        <DeleteIcon className={classes.actionButton} onClick={handleDelete} />
      </Tooltip>

      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDeleteUser}
        onCancel={() => setDeleteDialogOpen(false)}
        title="Delete portal user"
        content="Warning! This operation will delete portal user and cannot be undone"
      />
    </div>
  );
};

ClientPortalUserActions.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ClientPortalUserActions;
