import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  buttons: {
    gap: '10px',
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '1fr 1fr',
    margin: '10px 0px',
  },
}));

export default useStyles;
