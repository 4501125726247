import React, { useState } from 'react';
import { Confirm, useListContext, useNotify, useTranslate } from 'react-admin';
import Authorize from '@common/Authorize/Authorize';

import deleteIcon from '@assets/icons/deleteWhite.svg';
import updateIcon from '@assets/icons/circular-arrow.svg';
import ReusableButton from '@common/Button/Button';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import {
  deleteDeviceMonitors,
  updateDeviceMonitors,
} from '@components/DeviceMonitors/helpers';
import useBulkDelete from '@components/Hooks/useBulkDelete';
import { actions } from '@store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { listSelector } from '@store/selectors';

const BulkActions = () => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [valueToUpdate, setValueToUpdate] = useState(null);
  const { selectedIds, onUnselectItems } = useListContext();
  const notify = useNotify();

  const translate = useTranslate();
  const dispatch = useDispatch();
  const list = useSelector(listSelector);

  const onClickDelete = useBulkDelete(
    deleteDeviceMonitors,
    setDeleteDialogOpen,
  );

  const updateMany = () => {
    const params = selectedIds.map(i => `id=${i}`).join('&');
    return updateDeviceMonitors(params, valueToUpdate)
      .then(() => {
        const newList = list.map(item => {
          if (selectedIds.includes(item.id))
            return { ...item, enabled: valueToUpdate };
          return item;
        });
        dispatch(actions.saveList(newList));
        onUnselectItems();
        notify(`Successfully updated`);
      })
      .catch(() => notify(`elements updating was failed`, 'error'))
      .finally(() => {
        setOpen(false);
        setValueToUpdate(null);
      });
  };

  const handleClickRunningState = () => {
    const enableValues = list
      .filter(item => selectedIds.includes(item.id))
      .map(i => i.enabled);
    const isAllTrue = enableValues.every(v => v);
    const isAllFalse = enableValues.every(v => !v);
    if (!isAllTrue && !isAllFalse) {
      notify(
        "Can't update elements running state with the differing values",
        'error',
      );
    } else {
      setValueToUpdate(!isAllTrue);
      setOpen(true);
    }
  };

  const handleConfirm = () => {
    updateMany();
  };

  const getText = key => `resources.deviceMonitors.bulkActions.${key}`;

  return (
    <>
      <ReusableButton
        onClick={() => setDeleteDialogOpen(true)}
        viewType="red"
        size="xl"
      >
        <>
          <img src={deleteIcon} alt="deleteIcon" />
          {translate(getText('deleteSelected'))}
        </>
      </ReusableButton>

      <ReusableButton onClick={handleClickRunningState} viewType="black">
        <>
          <img width={20} height={20} src={updateIcon} alt="updateIcon" />
          {translate(getText('updateRunningState'))}
        </>
      </ReusableButton>
      <Confirm
        isOpen={open}
        title={translate(getText('reprocess.title'))}
        content={translate(getText('reprocess.content'))}
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
      <ConfirmDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onClickDelete}
        onCancel={() => setDeleteDialogOpen(false)}
        title={translate('common.deleteDialogHeader')}
        content={translate('common.deleteDialogText')}
      />
    </>
  );
};

export default BulkActions;
