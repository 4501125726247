import React from 'react';
import PropTypes from 'prop-types';
import { Pause, PlayArrow, Sync } from '@material-ui/icons';

import useStyles from '@common/Styles/actionFieldStyles';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

const ActionField = ({ record, handleToggleStateClick, handleSyncClick }) => {
  const classes = useStyles();

  const toggleObject = record?.cancelAtPeriodEnd
    ? {
        icon: <PlayArrow />,
        tip: 'UnPause subscription',
      }
    : {
        icon: <Pause />,
        tip: 'Pause subscription',
      };

  return (
    <div className={classes.container}>
      <ActionFieldItem
        handler={() => handleToggleStateClick(record)}
        toolTip={toggleObject.tip}
      >
        {toggleObject.icon}
      </ActionFieldItem>
      <ActionFieldItem
        handler={() => handleSyncClick(record)}
        toolTip="Sync with stripe"
      >
        <Sync />
      </ActionFieldItem>
    </div>
  );
};

ActionField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  handleToggleStateClick: PropTypes.func.isRequired,
  handleSyncClick: PropTypes.func.isRequired,
};

export default ActionField;
