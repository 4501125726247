import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';

import {
  ThunkCloseChannel,
  ThunkGetChannelHistory,
  ThunkGetChannelLogs,
  ThunkGetMessagingChannel,
} from '@store/slices/messaging/thunks';
import { ThunkGetTicketStatusesByChannelId } from '@store/slices/crmTicket/thunks/getTicketStatuses';
import { ThunkGetZendeskTicketStatusesByChannelId } from '@store/slices/crmTicket/thunks/getZendeskTicketStatuses';
import crmSources from '@constants/crmSources';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';

import ReusableButton from '@ui/components/common/Button/Button';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import Input from '@ui/components/Auth/Common/Input';
import DropDown from '@ui/components/Auth/Common/DropDown';
import Loading from '@ui/components/common/Loading/Loading';
import FormSwitch from '@ui/components/Auth/Common/FormSwitch';

import { OnChange } from 'react-final-form-listeners';
import useStyles from '../styles';

const DeleteChannelDialog = ({
  open,
  setOpen,
  channelIds,
  configurationId,
  fromChannelPage = false,
  crmType,
}) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [ticketStatuses, setTicketStatuses] = useState([]);
  const [preSelectedStatus, setPreSelectedStatus] = useState();

  const classes = useStyles();

  const dispatch = useDispatch();
  const notify = useNotify();
  const list = useSelector(listSelector);

  const submit = useCallback(
    ({ message, ticketStatusId, sendSmsToUser, skipTicketUpdate }) => {
      setProcessing(true);
      const payload = {
        message,
        ticketStatusId,
        sendSmsToUser: sendSmsToUser || false,
        skipTicketUpdate,
      };

      dispatch(ThunkCloseChannel({ channelIds, payload }))
        .unwrap()
        .then(() => {
          notify('Channel Closed');
          setOpen(false);
          if (fromChannelPage) {
            dispatch(ThunkGetMessagingChannel(channelIds));
            dispatch(ThunkGetChannelHistory({ channelId: channelIds }));
            dispatch(ThunkGetChannelLogs({ channelIds, configurationId }));
          } else {
            const updatedList = list.map(item =>
              channelIds.includes(item.id) ? { ...item, isClosed: true } : item,
            );
            dispatch(actions.saveList(updatedList));
          }
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [
      dispatch,
      notify,
      channelIds,
      setOpen,
      configurationId,
      fromChannelPage,
      list,
    ],
  );

  const handleSkipUpdateChange = (value, form) => {
    if (value) {
      form.change('message', null);
      form.change('ticketStatusId', null);
      form.change('sendSmsToUser', false);
    }
  };

  const getContactThunk = () => {
    switch (crmType) {
      case crmSources.Zendesk.idx:
        return ThunkGetZendeskTicketStatusesByChannelId;
      default:
        return ThunkGetTicketStatusesByChannelId;
    }
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    const statuses = await dispatch(getContactThunk()(channelIds[0])).unwrap();
    setTicketStatuses(statuses.options);
    if (statuses.options.some(option => option.id === statuses.current)) {
      setPreSelectedStatus(statuses.current);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Confirm Channel Closing</DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <Form
            onSubmit={submit}
            initialValues={
              preSelectedStatus && { ticketStatusId: preSelectedStatus }
            }
            render={({ handleSubmit, values, form }) => (
              <form onSubmit={handleSubmit}>
                <FieldWrapper
                  label="Enter message to user"
                  labelSize={12}
                  contentSize={12}
                  classNameLabelInner={classes.deleteDialogFieldLabelInner}
                  classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                  content={
                    <Field
                      id="message"
                      name="message"
                      fullWidth
                      variant="outlined"
                      multiline
                      minRows={3}
                      maxRows={5}
                      dateProps={{ maxLength: 120 }}
                      className={classes.deleteDialogInput}
                      helperText={`${
                        values.message ? values.message.length : 0
                      }/120`}
                      component={Input}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Ticket Status"
                  labelSize={12}
                  contentSize={12}
                  classNameLabelInner={classes.deleteDialogFieldLabelInner}
                  classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                  content={
                    <Field
                      id="ticketStatusId"
                      name="ticketStatusId"
                      fullWidth
                      variant="outlined"
                      component={DropDown}
                      disabled={processing}
                      options={ticketStatuses}
                    />
                  }
                />

                <FieldWrapper
                  label="Send Message"
                  labelSize={8}
                  contentSize={4}
                  classNameLabelInner={classes.deleteDialogFieldLabelInner}
                  classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                  content={
                    <Field
                      id="sendSmsToUser"
                      name="sendSmsToUser"
                      fullWidth
                      component={FormSwitch}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Skip ticket update"
                  labelSize={8}
                  contentSize={4}
                  classNameLabelInner={classes.deleteDialogFieldLabelInner}
                  classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                  content={
                    <Field
                      id="skipTicketUpdate"
                      name="skipTicketUpdate"
                      fullWidth
                      component={FormSwitch}
                      disabled={processing}
                    />
                  }
                />

                <OnChange name="skipTicketUpdate">
                  {value => handleSkipUpdateChange(value, form)}
                </OnChange>

                <div className={classes.deleteDialogActions}>
                  <ReusableButton
                    label="Cancel"
                    onClick={() => {
                      setOpen(false);
                    }}
                    disabled={processing}
                  />
                  <ReusableButton
                    label="Submit"
                    type="submit"
                    disabled={processing}
                    loading={processing}
                  />
                </div>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

DeleteChannelDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  channelIds: PropTypes.arrayOf([PropTypes.number]),
  configurationId: PropTypes.number,
  fromChannelPage: PropTypes.bool,
};

export default DeleteChannelDialog;
