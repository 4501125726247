import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ThunkUpdateChannelUserName } from '@store/slices/messaging/thunks';
import { MessagingChannelSelector } from '@store/slices/messaging';

import Input from '@components/Auth/Common/Input';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

import acceptIcon from '@assets/icons/accept.svg';
import editIcon from '@assets/icons/edit.svg';

import useStyles from '../styles';

const ChannelDetailsItem = ({ label, value, isEditable = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(value || '');
  const [processing, setProcessing] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { id } = useSelector(MessagingChannelSelector);

  const submit = async () => {
    setProcessing(true);
    await dispatch(
      ThunkUpdateChannelUserName({ channelId: id, payload: inputValue }),
    );
    setProcessing(false);
    setEditMode(false);
  };

  return (
    <div className={classes.itemContainer}>
      <div className={classes.channelDetailsLabel}>{label}</div>
      {!editMode ? (
        <div className={classes.channelDetailsAlignment}>
          {value}
          {isEditable && (
            <ActionFieldItem
              icon={editIcon}
              alt="edit"
              toolTip="Edit"
              handler={() => {
                setEditMode(!editMode);
              }}
            />
          )}
        </div>
      ) : (
        <div className={classes.inputContainer}>
          <Input
            meta={{ touched: false }}
            input={{
              value: inputValue,
              onChange: event => setInputValue(event.target.value),
            }}
            disabled={processing}
          />
          <ActionFieldItem
            icon={acceptIcon}
            alt="saveIcon"
            handler={submit}
            toolTip="Save User Name"
            classNameWrapper={classes.inputActionButton}
            disabled={processing}
          />
        </div>
      )}
    </div>
  );
};

ChannelDetailsItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isEditable: PropTypes.bool,
};

export default ChannelDetailsItem;
