import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Paper, Table as MUITable, TableContainer } from '@material-ui/core';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { actions } from '@store/actions';
import TableContextProvider from './TableContextProvider/TableContextProvider';
import TablePagination from './TablePagination/TablePagination';
import TableBody from './TableBody/TableBody';

import useStyles from './styles';

const Table = ({
  className,
  bulkActionsClassName,
  actionsComponent,
  isDisplayTable = true,
  ...props
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  /*
   * TableContextProvider - contain all state and request function
   *
   * */

  // Reset the data if the component was unmounted.
  useEffect(() => {
    return () => {
      dispatch(actions.saveList([]));
    };
  }, [dispatch]);

  return (
    <TableContextProvider {...props}>
      <TableContainer
        component={Paper}
        className={classNames(classes.tableContainer, className)}
      >
        {actionsComponent}
        {isDisplayTable && (
          <MUITable className={classes.table}>
            <TableBody bulkActionsClassName={bulkActionsClassName} />
            <TablePagination />
          </MUITable>
        )}
      </TableContainer>
    </TableContextProvider>
  );
};

Table.propTypes = {
  resource: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      sortable: PropTypes.bool,
      searchable: PropTypes.bool,
      type: PropTypes.string,
      labelAlignment: PropTypes.string,
      accessor: PropTypes.func,
      isCustomAccessor: PropTypes.bool,
    }),
  ),
  bulkActions: PropTypes.element,
  bulkActionsClassName: PropTypes.string,
  isDisplayTable: PropTypes.bool,
  className: PropTypes.string,
};
export default Table;
