import React from 'react';
import PropTypes from 'prop-types';
import deviceMonitorTypes from '@constants/deviceMonitorTypes';
import useStyles from '../styles';

const Types = ({ record }) => {
  const { type: typeName } = record;
  const type = deviceMonitorTypes[typeName];
  const classes = useStyles(type);

  return (
    <div className={classes.container}>
      <span className={classes.wrapper}>{type.name}</span>
    </div>
  );
};

Types.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Types;
