import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '@store/actions';

import { axiosInstance } from '@components/Table/axios';
import { TableContext } from '@components/Table/context';

import {
  BulkTypeWidth,
  TableDebounceDelay,
  TableTypes,
} from '@components/Table/constants';

const TableContextProvider = ({
  children,
  resource,
  columns,
  bulkActions,
  selectorKey,
  updateTableDataTrigger,
  defaultSort,
  defaultFilters,
}) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sorting, setSorting] = useState(defaultSort || {});
  const [filtering, setFiltering] = useState(defaultFilters || {});
  const [totalCount, setTotalCount] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [extendedColumns, setExtendedColumns] = useState(columns ?? []);

  const data =
    useSelector(state =>
      selectorKey ? state.list.list[selectorKey] : state.list.list,
    ) || [];

  const isRemovedRowElement = useSelector(
    state => state.list.isRemovedRowElement,
  );

  const dispatch = useDispatch();
  const refArray = columns.map(() => React.createRef());

  const updateColumnWidthInCache = (tableCache, columnKey, newWidth) => {
    const parsed = JSON.parse(tableCache);
    const updatedData = {
      ...parsed,
      [columnKey]: newWidth,
    };
    return JSON.stringify(updatedData);
  };

  const setTableCachedData = useCallback(
    (columnKey, newWidth) => {
      const tableCache = localStorage.getItem(resource) ?? '{}';
      const updatedCache = updateColumnWidthInCache(
        tableCache,
        columnKey,
        newWidth,
      );
      localStorage.setItem(resource, updatedCache);
    },
    [resource],
  );

  const columnsWithoutWidth = useMemo(
    () =>
      columns?.length ? columns.filter(i => !i.width).length : columns?.length,
    [columns],
  );

  const allIds = useMemo(() => {
    if (data.length) {
      return data.map(d => d.id);
    }
    return [];
  }, [data]);

  const changeColumnWidth = useCallback(
    (columnKey, newWidth) => {
      setExtendedColumns(
        extendedColumns.map(i =>
          i.key === columnKey ? { ...i, width: newWidth } : i,
        ),
      );

      setTableCachedData(columnKey, newWidth);
    },
    [extendedColumns, setTableCachedData],
  );

  const selectAllItems = useCallback((state, d) => {
    setSelectedIds(d);
    setSelectAll(state);
  }, []);

  const deselectAllItems = useCallback(state => {
    setSelectedIds([]);
    setSelectAll(state);
  }, []);

  const addItemToSelectedIds = useCallback(
    id => {
      setSelectedIds([...selectedIds, id]);
    },
    [selectedIds],
  );

  const removeItemFromSelected = useCallback(
    id => {
      setSelectedIds(selectedIds.filter(i => i !== id));
    },
    [selectedIds],
  );

  const setSortingObject = useCallback((fieldName, order) => {
    setSorting({ fieldName, order });
  }, []);

  const setFilteringObject = useCallback((filteringValue, fieldName, value) => {
    setFiltering({ ...filteringValue, [fieldName]: value });
  }, []);

  const onMount = useCallback(() => {
    setLoading(true);

    axiosInstance
      .request({
        method: 'GET',
        url: resource,
        params: {
          _start: page * rowsPerPage,
          _end: page * rowsPerPage + rowsPerPage,
          _sort: sorting.fieldName ?? null,
          _order: sorting.order ?? null,
          ...filtering,
        },
      })
      .then(res => {
        dispatch(
          actions.saveList(
            selectorKey ? { data: res.data, key: selectorKey } : res.data,
          ),
        );
        setLoading(false);
        setTotalCount(res.headers.get('x-total-count'));
      });
  }, [
    selectorKey,
    dispatch,
    filtering,
    page,
    resource,
    rowsPerPage,
    sorting.fieldName,
    sorting.order,
    updateTableDataTrigger,
  ]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onMount();
    }, TableDebounceDelay);

    return () => clearTimeout(timeoutId);
  }, [page, rowsPerPage, resource, sorting, filtering, onMount, dispatch]);

  useEffect(() => {
    if (columns.length && resource) {
      const tableCache = localStorage.getItem(resource) ?? '{}';
      const parsedCache = JSON.parse(tableCache);

      const updatedColumns =
        bulkActions && columns[0]?.key !== TableTypes.bulk
          ? [
              {
                key: TableTypes.bulk,
                type: TableTypes.bulk,
                width: BulkTypeWidth,
                minWidth: BulkTypeWidth,
              },
              ...columns.map(c => ({
                ...c,
                width: parsedCache[c.key] ?? c.width,
              })),
            ]
          : columns.map(c => ({
              ...c,
              width: parsedCache[c.key] ?? c.width,
            }));

      setExtendedColumns(updatedColumns);
    }
  }, [columns, resource, bulkActions]);

  useEffect(() => {
    if (typeof isRemovedRowElement === 'boolean') {
      // check if all elements on the table are visible and if it is the last page of the table
      if (Math.ceil(totalCount / rowsPerPage) === page + 1) {
        setTotalCount(prevState => prevState - 1);
      } else {
        onMount();
      }
    }
  }, [isRemovedRowElement, onMount]);

  return (
    <TableContext.Provider
      value={{
        data,
        rowsPerPage,
        page,
        setPage,
        setRowsPerPage,
        totalCount,
        columns: extendedColumns,
        setFilteringObject,
        setSortingObject,
        sortingOrder: sorting?.order,
        sortingField: sorting?.fieldName,
        loading,
        filtering,
        addItemToSelectedIds,
        removeItemFromSelected,
        selectedIds,
        bulkActions,
        selectAllItems,
        deselectAllItems,
        selectAll,
        allIds,
        columnsWithoutWidth,
        changeColumnWidth,
        refArray,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

TableContextProvider.propTypes = {
  children: PropTypes.element,
  resource: PropTypes.string.isRequired,
  selectorKey: PropTypes.string,
  updateTableDataTrigger: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      sortable: PropTypes.bool,
      searchable: PropTypes.bool,
      type: PropTypes.string,
      labelAlignment: PropTypes.string,
      accessor: PropTypes.func,
    }),
  ),
  bulkActions: PropTypes.element,
};

export default TableContextProvider;
