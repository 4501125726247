import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

import {
  incidentStatuses,
  triggerPriorities,
} from '@components/Triggers/components/helpers';
import predicateRequiredValidator from '@utils/validators/predicateRequiredValidator';
import { requiredValidator } from '@utils/validators';

import { ESCALATION_RULES } from '@constants/routes';
import resources from '@constants/resources';

import CheckBoxArraySelectorItem from '@common/form/ArrayCheckBoxesSelector/ArrayCheckBoxesSelector';
import Loading from '@common/Loading/Loading';
import FieldWrapper from '@common/form/FieldWrapper';
import FormSwitch from '@common/FilterInputs/Switch';
import Checkbox from '@common/Checkbox/FormControlCheckBox';
import RadioInput from '@common/Radio/RadioInput';
import ReusableButton from '@common/Button/Button';
import Input from '@components/Auth/Common/Input';
import HtmlInput from '@common/HtmlInput';
import InputWithAutocomplete from '@common/Input/InputWithAutocomplete';
import { InputWithTemplate } from '@components/Ticketing/Notifications/TicketSettings/components/subComponents';
import ReEscalation from './ReEscalation/ReEscalation';

import {
  getNotificationGroupAutocomplete,
  getNotificationUsersAutocomplete,
  getTemplateVariables,
} from './helpers';

import { ackOptions, initial } from './form';
import useStyles from './styles';

const EscalationRulesForm = ({ match }) => {
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();
  const { goBack } = useHistory();

  const [initModel, setInitModel] = useState(initial);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [templateVariables, setTemplateVariables] = useState([]);
  const [groupsOptions, setGroupsOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);

  const onMount = () => {
    setLoading(true);

    Promise.all([
      getNotificationGroupAutocomplete(),
      getNotificationUsersAutocomplete(),
      getTemplateVariables(),
    ])
      .then(res => {
        setGroupsOptions(res[0]);
        setUsersOptions(res[1]);
        setTemplateVariables(res[2]);
      })
      .catch(e => {
        notify(e.message, 'error');
        goBack();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const route = match.params.id;
    onMount();
    if (route === 'createRule') {
      setInitModel(initial);
    } else if (!Number.isNaN(+route)) {
      setLoading(true);
      dataProvider
        .getOne(resources.escalationRules, { id: route })
        .then(d => setInitModel(d.data))
        .catch(e => notify(e.message, 'error'))
        .finally(() => setLoading(false));
    } else {
      redirect(ESCALATION_RULES);
    }
  }, [match.params.id]);

  const submit = values => {
    let promise;
    setProcessing(true);
    if (!values.id) {
      promise = dataProvider.create(resources.escalationRules, {
        data: values,
      });
    } else {
      promise = dataProvider.update(resources.escalationRules, {
        id: values.id,
        data: values,
      });
    }
    promise
      .then(() => {
        notify(`Succesfully ${!values.id ? 'created' : 'updated'}`);
        setProcessing(false);
        redirect(ESCALATION_RULES);
      })
      .catch(e => {
        notify(e.message, 'error');
        setProcessing(false);
      });
  };

  const validate = values => {
    return {
      name: requiredValidator(values.name),
      smsText: predicateRequiredValidator(values.isSms, values.smsText),
      emailSubject: predicateRequiredValidator(
        values.isEmail,
        values.emailSubject,
      ),
    };
  };

  if (loading) return <Loading />;
  return (
    <Form
      onSubmit={submit}
      validate={validate}
      initialValues={initModel}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          <Paper className={classes.paper}>
            <FieldWrapper
              label="Enabled"
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="isActive"
                  name="isActive"
                  type="checkbox"
                  component={FormSwitch}
                  disabled={processing}
                />
              }
            />
          </Paper>
          <Paper className={classes.paper}>
            <FieldWrapper
              label="Name"
              isRequired
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="name"
                  name="name"
                  fullWidth
                  component={Input}
                  placeholder="Name"
                  disabled={processing}
                />
              }
            />
            <FieldWrapper
              label="Statuses"
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="query.statuses"
                  name="query.statuses"
                  fullWidth
                  component={CheckBoxArraySelectorItem}
                  disabled={processing}
                  itemsList={incidentStatuses}
                />
              }
            />
            <FieldWrapper
              label="Priorities"
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="query.priorities"
                  name="query.priorities"
                  fullWidth
                  component={CheckBoxArraySelectorItem}
                  disabled={processing}
                  itemsList={triggerPriorities}
                />
              }
            />
            <FieldWrapper
              label="Acknowledge"
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="query.isAcknowledge"
                  name="query.isAcknowledge"
                  fullWidth
                  component={RadioInput}
                  choices={ackOptions}
                  disabled={processing}
                />
              }
            />
          </Paper>
          <Paper className={classes.paper}>
            <FieldWrapper
              label="Past Due"
              labelSize={2}
              contentSize={10}
              content={
                <div className={classes.box}>
                  <Field
                    id="query.pastDueMinutes"
                    name="query.pastDueMinutes"
                    component={Input}
                    styleType="main"
                    inputView="number"
                    min={1}
                    type="number"
                    disabled={processing}
                    style={{ maxWidth: '10rem' }}
                  />
                  <span style={{ marginLeft: '1rem' }}>minutes</span>
                </div>
              }
            />
            <FieldWrapper
              label="Groups"
              labelSize={2}
              contentSize={10}
              content={
                <InputWithAutocomplete
                  name="notificationGroupIds"
                  options={groupsOptions}
                  loading={processing}
                  disableCloseOnSelect
                  freeSolo={false}
                  getOptionValue={i => {
                    return typeof i === 'object'
                      ? i.value
                      : groupsOptions.find(m => m.value === i)?.value;
                  }}
                  getOptionLabel={i =>
                    typeof i === 'object'
                      ? i.label
                      : groupsOptions.find(m => m.value === i)?.label
                  }
                  getOptionSelected={(options, value) =>
                    options.value === value
                  }
                />
              }
            />
            <FieldWrapper
              label="Users"
              labelSize={2}
              contentSize={10}
              content={
                <InputWithAutocomplete
                  name="notificationUserIds"
                  options={usersOptions}
                  loading={processing}
                  disableCloseOnSelect
                  freeSolo={false}
                  getOptionValue={i => {
                    return typeof i === 'object'
                      ? i.value
                      : usersOptions.find(m => m.value === i)?.value;
                  }}
                  getOptionLabel={i =>
                    typeof i === 'object'
                      ? i.label
                      : usersOptions.find(m => m.value === i)?.label
                  }
                  getOptionSelected={(options, value) =>
                    options.value === value
                  }
                />
              }
            />
          </Paper>
          <Paper className={classes.paper}>
            <FieldWrapper
              label="Additional Escalation"
              labelSize={2}
              contentSize={10}
              infoText="Will notify again according to the listed rules order"
              content={
                <Field
                  id="query.reEscalationRules"
                  name="query.reEscalationRules"
                  groups={groupsOptions}
                  users={usersOptions}
                  component={ReEscalation}
                />
              }
            />
          </Paper>
          <Paper className={classes.paper}>
            <FieldWrapper
              label="SMS"
              labelSize={2}
              contentSize={10}
              content={
                <div className={classes.box}>
                  <Field
                    id="isSms"
                    name="isSms"
                    label="Enable:"
                    type="checkbox"
                    component={Checkbox}
                    labelPlacement="start"
                    disabled={processing}
                  />
                  <span className={classes.margins}>Message: </span>
                  <Field
                    id="smsText"
                    name="smsText"
                    fullWidth
                    component={Input}
                    disabled={processing}
                  />
                </div>
              }
            />
            <FieldWrapper
              label="Email"
              labelSize={2}
              contentSize={10}
              classNameLabelContainer={classes.alignAuto}
              content={
                <div className={classes.emailBox}>
                  <FieldWrapper
                    label="Enable"
                    labelSize={1}
                    contentSize={11}
                    content={
                      <Field
                        id="isEmail"
                        name="isEmail"
                        type="checkbox"
                        component={Checkbox}
                        labelPlacement="start"
                        disabled={processing}
                      />
                    }
                  />
                  <FieldWrapper
                    label="Subject"
                    labelSize={1}
                    contentSize={11}
                    content={
                      <InputWithTemplate
                        name="emailSubject"
                        disabled={processing}
                        form={form}
                        templateVariables={templateVariables}
                      />
                    }
                  />
                  <FieldWrapper
                    label="Message"
                    labelSize={1}
                    contentSize={11}
                    content={
                      <HtmlInput
                        name="emailText"
                        form={form}
                        templateVariables={templateVariables}
                      />
                    }
                  />
                </div>
              }
            />
          </Paper>
          <div className={classes.buttons}>
            <ReusableButton
              size="md"
              label="resources.buttons.back"
              onClick={() => redirect(ESCALATION_RULES)}
              disabled={processing}
            />
            <ReusableButton
              type="submit"
              viewType="black"
              size="md"
              label="resources.buttons.confirm"
              loading={processing}
              disabled={processing}
            />
          </div>
        </form>
      )}
    />
  );
};

EscalationRulesForm.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
};

export default EscalationRulesForm;
