import React, { useState } from 'react';
import { useRedirect, useNotify, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import ReusableButton from '@common/Button/Button';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import deleteIcon from '@assets/icons/delete.svg';
import { deleteMailBox } from '../../../helpers';
import useStyles from './styles';

const DangerZone = ({ mailBoxId }) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const notify = useNotify();
  const translate = useTranslate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const onDelete = () => {
    setDeleteDialogOpen(false);
    deleteMailBox(mailBoxId)
      .then(() => redirect('/home'))
      .catch(e => notify(e.message, 'error'));
  };

  return (
    <div className={classes.dangerZone}>
      <div className={classes.container}>
        <div>
          <p>Delete current mailBox? </p>
        </div>
        <div>
          <ReusableButton
            size="md"
            viewType="black"
            onClick={() => setDeleteDialogOpen(true)}
          >
            <>
              <img src={deleteIcon} alt="delete" />
              {translate('common.delete')}
            </>
          </ReusableButton>
        </div>
      </div>
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onDelete}
        onCancel={() => setDeleteDialogOpen(false)}
        title="Delete current mail box"
        content="Warning! This operation will delete current mailbox and all Data! This action cannot be undone"
      />
    </div>
  );
};

DangerZone.propTypes = {
  mailBoxId: PropTypes.string.isRequired,
};

export default DangerZone;
