import React, { useState } from 'react';
import { useListContext } from 'react-admin';

import ReusableButton from '@ui/components/common/Button/Button';
import DeleteChannelDialog from '../../MessagingChannel/dialogs/DeleteChannelDialog';

const TableBulkActions = ({ selectedConfiguration }) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { selectedIds } = useListContext();

  return (
    <div>
      <ReusableButton
        label="Close Channels"
        onClick={() => {
          setOpenDeleteDialog(true);
        }}
      />

      {openDeleteDialog && (
        <DeleteChannelDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          channelIds={selectedIds}
          configurationId={selectedConfiguration}
        />
      )}
    </div>
  );
};

TableBulkActions.propTypes = {};

export default TableBulkActions;
