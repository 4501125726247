import { useNotify } from 'react-admin';
import { actions } from '@store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { listSelector } from '@store/selectors';
import { useTableContext } from '../../../hooks/table';

const useBulkDelete = (apiRequest, setOpenDialog) => {
  const { selectedIds, deselectAllItems } = useTableContext();
  const notify = useNotify();

  const dispatch = useDispatch();
  const list = useSelector(listSelector);

  return () => {
    const params = selectedIds.map(i => `id=${i}`).join('&');

    return apiRequest(params)
      .then(() => {
        const newList = list.filter(item => !selectedIds.includes(item.id));
        dispatch(actions.saveList(newList));
        deselectAllItems();
        notify(`Successfully removed`);
      })
      .catch(() => notify(`elements deleting was failed`, 'error'))
      .finally(() => setOpenDialog(false));
  };
};

export default useBulkDelete;
