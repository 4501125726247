import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import { Field, Form } from 'react-final-form';

import { ThunkUpdateBillingAddress } from '@store/slices/billing/thunks';
import { BillingAddressSelector } from '@store/slices/billing';

import {
  getCountriesOptions,
  getStatesOptions,
} from '@constants/countries/countries';

import ReusableButton from '@common/Button/Button';
import Input from '@components/Auth/Common/Input';
import FieldWrapper from '@common/form/FieldWrapper';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

const ChangeBillingAddressDialog = ({ open, onClose }) => {
  const [processing, setProcessing] = useState(false);

  const sharedStyles = useSharedStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const billingAddress = useSelector(BillingAddressSelector);

  const submit = useCallback(
    values => {
      setProcessing(true);

      dispatch(ThunkUpdateBillingAddress(values))
        .unwrap()
        .then(() => {
          notify('Billing Adress Updated', 'success');
          onClose();
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch, notify, onClose],
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Change Billing Address</DialogTitle>

      <DialogContent>
        <Form
          onSubmit={submit}
          initialValues={billingAddress}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Full Name"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    placeholder="Full Name"
                    name="name"
                    id="name"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Phone"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    placeholder="Phone"
                    name="phone"
                    id="phone"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Address"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    placeholder="Address 1"
                    name="address.line1"
                    id="address.line1"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: '10px',
                }}
              >
                <FieldWrapper
                  label="Country"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  content={
                    <Field
                      label="Country"
                      id="address.country"
                      name="address.country"
                      render={AutocompleteFormInput}
                      disabled={processing}
                      items={getCountriesOptions()}
                    />
                  }
                />

                <FieldWrapper
                  label="Country"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  content={
                    <Field
                      id="address.state"
                      name="address.state"
                      component={AutocompleteFormInput}
                      label="State"
                      items={getStatesOptions(values.address.country)}
                      disabled={processing}
                    />
                  }
                />
              </div>

              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: '10px',
                }}
              >
                <FieldWrapper
                  label="City"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  content={
                    <Field
                      placeholder="City"
                      name="address.city"
                      id="address.city"
                      render={Input}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Postal Code"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  content={
                    <Field
                      placeholder="Postal Code"
                      name="address.postalCode"
                      id="address.postalCode"
                      render={Input}
                      disabled={processing}
                    />
                  }
                />
              </div>

              <div className={sharedStyles.dialogActionsContainer}>
                <ReusableButton label="Close" onClick={onClose} />
                <ReusableButton label="Submit" type="submit" viewType="blue" />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

ChangeBillingAddressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChangeBillingAddressDialog;
