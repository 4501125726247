import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useRedirect, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import ButtonGroup from '@components/DeviceMonitors/components/ButtonGroup';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconEdit from '@assets/icons/edit.svg';
import iconDelete from '@assets/icons/delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import { listSelector } from '@store/selectors';
import { Box, TableBody } from '@material-ui/core';
import ListActions from '@components/DeviceMonitors/components/ListActions';

import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { actions } from '@store/actions';
import TableLabel from '@common/TableLabel/TableLabel';
import NotificationTypes from '@components/Ticketing/Notifications/TicketSettings/NotificationTypes';
import UploadDialog from '@components/UploadFileDialog/Dialog';
import DetailsDialog from '@components/UploadFileDialog/DetailsDialog/DetailsDialog';
import TableHeader from '@components/UploadFileDialog/DetailsDialog/TableHeader';
import importTypes from '@constants/importTypes';
import {
  DM_SETTINGS_CREATE,
  DM_SETTINGS_GROUP_CREATE,
} from '@constants/routes';
import useStyles from './styles';
import { deleteDeviceMonitorDmSetting } from './helpers';
import DetailsDialogItem from './detailsDialog/DetailsDialogItem';

const headers = [
  'Status',
  'Company',
  'Device Name',
  'Email Recipient',
  'Email Subject',
  'Type',
  'Last N',
  'Value',
  'Email',
  'WebHook',
  'Slack',
  'Ticket',
  'Sms',
  'Teams',
  'Errors',
];

const ListActionsWrapper = ({
  handleAddThreshold,
  setOpenUploadModal,
  handleAddGroup,
}) => {
  return (
    <Box width="100%">
      <ListActions
        handleAddRecord={handleAddThreshold}
        handleUploadState={setOpenUploadModal}
        handleAddGroup={handleAddGroup}
      />
    </Box>
  );
};

const DmSettings = ({ id }) => {
  const list = useSelector(listSelector);
  const redirect = useRedirect();
  const classes = useStyles();
  const resource = 'DmNotificationSettings';
  const location = useLocation();
  const translate = useTranslate();
  const notify = useNotify();
  const dispatch = useDispatch();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [modalDetails, setModalDetails] = useState({
    open: false,
    data: {},
  });

  const handleEditRecord = rowId => {
    const defaultUrl = `/notifications/dmSetting/${rowId}/monitor`;
    const url = location.search ? `${defaultUrl}?id=${id}` : defaultUrl;
    redirect(url);
  };

  useEffect(() => {
    dispatch(actions.resetSetting());
  }, [dispatch]);

  const handleAddDmSetting = () => {
    const url = location.search
      ? `${DM_SETTINGS_CREATE}?id=${id}`
      : DM_SETTINGS_CREATE;
    redirect(url);
  };

  const handleAddDmSettingGroup = () => redirect(DM_SETTINGS_GROUP_CREATE);

  const onCancelDeleteHandler = () => {
    setIdForDelete(null);
    setDeleteDialogOpen(false);
  };

  const handleDeleteIcon = rowId => {
    setIdForDelete(rowId);
    setDeleteDialogOpen(true);
  };

  const onDeleteHandler = async () => {
    const result = await deleteDeviceMonitorDmSetting(idForDelete);
    if (!result) {
      notify('Unable to remove', 'warning');
    }
    const newArr = list.filter(({ id: _id }) => _id !== idForDelete);
    dispatch(actions.saveList(newArr));
    onCancelDeleteHandler();
  };

  return (
    <div className={classes.listContainer}>
      <MuiVirtualizedTable
        columns={[
          {
            name: (
              <TableLabel
                item={{ field: 'deviceMonitorName', name: 'Monitor Name' }}
              />
            ),
            key: 'deviceMonitorName',
          },
          {
            name: (
              <TableLabel
                item={{ field: 'deviceMonitorCompany', name: 'Company' }}
              />
            ),
            key: 'deviceMonitorCompany',
          },

          {
            name: 'Notification Types',
            key: 'type',
            accessor: row => <NotificationTypes record={row} useFtp={false} />,
          },
          {
            name: 'Actions',
            key: 'actions',
            accessor: row => (
              <ButtonGroup>
                <ActionButton
                  icon={<img src={iconEdit} alt="iconEdit" />}
                  handler={() => handleEditRecord(row.id)}
                  toolTip="Display Device Alert details"
                />

                <ActionButton
                  handler={() => handleDeleteIcon(row.id)}
                  icon={<img src={iconDelete} alt="iconDelete" />}
                />
              </ButtonGroup>
            ),
          },
        ]}
        defaultFilters={{ DeviceMonitorId: id }}
        resource={resource}
        actions={
          <ListActionsWrapper
            setOpenUploadModal={setOpenUploadModal}
            handleAddThreshold={handleAddDmSetting}
            handleAddGroup={handleAddDmSettingGroup}
          />
        }
        className={cx('rdg-light')}
      >
        <UploadDialog
          type={importTypes.DeviceNotification}
          setOpen={setOpenUploadModal}
          setModalDetails={setModalDetails}
          open={openUploadModal}
          displaySkip={false}
        />
      </MuiVirtualizedTable>
      <DetailsDialog
        open={modalDetails.open}
        statistic={modalDetails.data.statistic}
        setModalDetails={setModalDetails}
      >
        <>
          <TableHeader headers={headers} />
          <TableBody>
            {modalDetails.data?.importedRows?.map(row => (
              <DetailsDialogItem key={row} row={row} />
            ))}
          </TableBody>
        </>
      </DetailsDialog>
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onDeleteHandler}
        onCancel={onCancelDeleteHandler}
        title={translate('resources.notificationSettings.deleteTitle')}
        content={translate('resources.notificationSettings.deleteContent')}
      />
    </div>
  );
};

DmSettings.propTypes = {
  id: PropTypes.string,
};
ListActionsWrapper.propTypes = {
  handleAddThreshold: PropTypes.func,
  setOpenUploadModal: PropTypes.func,
};
export default DmSettings;
