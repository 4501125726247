import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import InviteUsers from '@components/SetupWizard/steps/InviteUsers';
import PropTypes from 'prop-types';

import useStyles from '../styles';

const InviteUsersDialog = ({ open, onClose }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        style={{
          minWidth: '500px',
        }}
      >
        Invite Users
      </DialogTitle>

      <DialogContent>
        <span className={classes.font}>Hit enter or "+" after each Email</span>
        <InviteUsers
          handleForward={onClose}
          showCloseButton
          addStyles={false}
        />
      </DialogContent>
    </Dialog>
  );
};

InviteUsersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InviteUsersDialog;
