import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SyncroMspTicketTableActions from '@components/PsaTickets/components/SyncroMsp/SyncroMspTicketTableActions';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';

import {
  getIntegrationBoardStatuses,
  getSyncroIntegrationCompanies,
} from '@services/requests/integration';
import nationalFormatPhoneNumber from '@utils/nationalFormatPhoneNumber';
import useStyles from '../../styles';

const SyncroMspTicketTable = ({
  defaultId,
  crmId,
  resource,
  tableType = 'tickets',
  defaultSortName = 'ticketNumber',
}) => {
  const classes = useStyles();

  const [statusOptions, setStatusOptions] = useState([]);
  const [companies, setCompanies] = useState([]);

  const handleTicketIdClick = useCallback(ticketLink => {
    window.open(ticketLink, '_blank');
  }, []);

  const onMount = useCallback(async () => {
    if (tableType === 'tickets') {
      const options = await getIntegrationBoardStatuses(crmId, defaultId);
      setStatusOptions(
        options.map(option => ({ label: option.name, value: option.name })),
      );
    }
    if (tableType === 'contacts') {
      const options = await getSyncroIntegrationCompanies(crmId);
      setCompanies(options);
    }
  }, [crmId, defaultId, tableType]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const columns = {
    tickets: [
      {
        name: 'Ticket Number',
        key: 'ticketNumber',
        type: TableTypes.actions,
        accessor: item => (
          <div
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={() => {
              handleTicketIdClick(item.ticketLink);
            }}
            role="presentation"
          >
            {item.ticketNumber}
          </div>
        ),
        searchable: true,
        sortable: true,
      },
      {
        name: 'Subject',
        key: 'subject',
        searchable: true,
        sortable: true,
      },
      {
        name: 'Status',
        key: 'status',
        searchable: true,
        sortable: true,
        type: TableTypes.dropdown,
        dropDownValues: statusOptions,
      },
      {
        name: 'Customer',
        key: 'customer',
        searchable: true,
        sortable: true,
      },
      {
        name: 'Contact',
        key: 'contactName',
        type: TableTypes.actions,
        accessor: record => (
          <>
            {record.contactId
              ? nationalFormatPhoneNumber(record.contactId)
              : 'None Assigned'}
          </>
        ),
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        accessor: record => (
          <SyncroMspTicketTableActions record={record} crmId={crmId} />
        ),
      },
    ],
    contacts: [
      {
        name: 'Name',
        key: 'name',
        searchable: true,
        sortable: true,
      },
      {
        name: 'Customer',
        key: 'companyName',
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: companies,
        filterByAutocomplete: 'companyId',
      },
      {
        name: 'Phone',
        key: 'phone',
        searchable: true,
        type: TableTypes.phoneNumber,
      },
      {
        name: 'Email',
        key: 'email',
        searchable: true,
        sortable: true,
      },
      {
        name: 'Is Verified',
        key: 'isVerified',
        type: TableTypes.boolean,
        labelAlignment: 'center',
      },
      {
        name: 'Is Opted-in',
        key: 'isOptedIn',
        type: TableTypes.boolean,
        labelAlignment: 'center',
      },
      {
        name: 'Client Portal',
        key: 'hasClientPortal',
        type: TableTypes.boolean,
        labelAlignment: 'center',
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        accessor: record => (
          <SyncroMspTicketTableActions
            record={{ ...record, contactId: record.id }}
            crmId={crmId}
            tableType={tableType}
          />
        ),
      },
    ],
  };

  return (
    <div className={classes.tableWrapper}>
      {(!!statusOptions.length || !!companies.length) && (
        <Table
          resource={resource || `TicketUpdate/syncro/${defaultId}/tickets`}
          columns={columns[tableType]}
          defaultSort={{ field: defaultSortName, order: 'DESC' }}
        />
      )}
    </div>
  );
};

SyncroMspTicketTable.propTypes = {
  defaultId: PropTypes.number,
  crmId: PropTypes.number.isRequired,
  resource: PropTypes.string,
  tableType: PropTypes.string,
  defaultSortName: PropTypes.string,
};

export default SyncroMspTicketTable;
