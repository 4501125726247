import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tableWrapper: {
    marginTop: '0.5rem',
  },
  activeFieldContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonsContainer: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-around',
  },
  marginTop: {
    marginTop: '1rem',
  },
  buttonWrapper: {
    margin: '0 0 5px 25px',
  },
  formText: {
    marginTop: '1rem',
    fontStyle: 'normal',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  colorBlock: {
    fontWeight: 'bold',
    color: props => props.color,
  },
  copyLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    alignItems: 'flex-end',
  },
}));

export default useStyles;
