import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import visibleIcon from '@assets/icons/visibility_blue.svg';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import useStyles from './styles';

const ErrorField = ({ value }) => {
  const classes = useStyles();
  const [open, setOpen] = useState();

  return value ? (
    <>
      <ActionButton
        icon={<img src={visibleIcon} alt="View" />}
        handler={() => setOpen(true)}
        toolTip="Show error"
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Paper className={classes.errorBox}>{value}</Paper>
      </Modal>
    </>
  ) : null;
};

ErrorField.propTypes = {
  value: PropTypes.string,
};

export default ErrorField;
